
import { Vue, Component, Prop } from 'vue-property-decorator';
import Loading from '@/components/loading/index.vue';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';
import { getCurrentMonth } from '@/common/utils';

@Component({
  components: {
    Loading,
  },
})
export default class Representative extends Vue {
  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;
  lading = false;
  typeList: any = {
    // 大B组员
    kaTeam: ['workbenches.ShouldBeCharged', 'workbenches.StoreConsumed'],
    // 大B组长
    kaTeamLeader: ['workbenches.ShouldBeCharged', 'workbenches.StoreConsumed'],
    // 大B部长
    kaTeamMinister: [],
    // 小B组员
    ordinaryTeam: ['workbenches.ShouldBeCharged', 'workbenches.CollisionLineStore', 'workbenches.MergePaymentIng', 'workbenches.StoreConsumed'],
    // 小B组长
    ordinaryTeamLeader: ['workbenches.ShouldBeCharged', 'workbenches.CollisionLineStore', 'workbenches.MergePaymentIng', 'workbenches.StoreConsumed'],
    // 小B部长
    ordinaryTeamMinister: [],
    // 助理总监
    assistantDirector: [],
    // 总监
    director: [],

  };

  @Prop({ type: String, default: 'kaTeamMinister' })
  type?: any;

  data: any = {
    need_recharge: 0, // 应充商家
    ad_campaign_overflow: 0, // 撞线门店
    need_deployed: 0, // 合并支付待投放
  };

  async init(type: any) {
    const list = ['kaTeam', 'kaTeamLeader', 'ordinaryTeam', 'ordinaryTeamLeader'];
    if (list.includes(type)) {
      try {
        const res = await this.workbenchesService.getMyTodo();
        this.lading = true;
        if (res) {
          this.data = res;
        }
      } catch (error) {
        this.lading = true;
      }
    } else {
      this.lading = true;
    }
  }

  handleClick(name: string) {
    if (name === 'workbenches.MergePaymentIng') {
      const query: any = {
        month: getCurrentMonth(),
      };
      this.$router.push({
        name,
        query,
      });
    } else {
      this.$router.push({ name });
    }
  }
}
