<template>
  <div class="parentBox">
    <div class="containerBox">
      <!-- 第一种 -->
      <!-- <div class="canvasBox">
        <div class="spinnerOneBox spinnerMaxBox">
          <div class="spinnerOneBox spinnerMidBox">
            <div class="spinnerOneBox spinnerMinBox"></div>
          </div>
        </div>
      </div> -->
      <!-- 第二种 -->
      <!-- <div class="canvasBox canvasTwoBox">
        <div class="spinnerTwoBox"></div>
        <div class="hourHandBox"></div>
        <div class="dotBox"></div>
      </div> -->
      <!-- 第三种 -->
      <!-- <div class="canvasBox">
        <div class="spinnerThreeBox"></div>
      </div> -->
      <!-- 第四种 -->
      <!-- <div class="canvasBox">
        <div class="spinnerFourBox"></div>
      </div> -->
      <!-- 第五种 -->
      <!-- <div class="canvasBox">
        <div class="spinnerFiveBox"></div>
      </div> -->
      <!-- 第六种 -->
      <div class="canvasBox">
        <div class="spinnerSexBox p1"></div>
        <div class="spinnerSexBox p2"></div>
        <div class="spinnerSexBox p3"></div>
        <div class="spinnerSexBox p4"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class Loading extends Vue {

}
</script>
<style lang="scss" scoped>
.parentBox {
  height: 300px;
  background: rgba(31, 31, 31, 0.05);
  // padding: 100px;


  .containerBox {
    display: flex;
    justify-content: center;
    align-items: center;

    .canvasBox {
      align-items: center;
      // background: #eeeeee;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      margin: 1em;
      width: 10em;
      height: 20em;

      // 第一种
      .spinnerOneBox {
        align-items: center;
        border: 0.3em solid transparent;
        border-top: 0.3em solid #4db6ac;
        border-right: 0.3em solid #4db6ac;
        border-radius: 100%;
        display: flex;
        justify-content: center;
      }

      .spinnerMaxBox {
        animation: spinnerOne 3s linear infinite;
        height: 3em;
        width: 3em;

        .spinnerMidBox {
          animation: spinnerOne 5s linear infinite;
          height: 2.4em;
          width: 2.4em;

          .spinnerMinBox {
            animation: spinnerOne 5s linear infinite;
            height: 1.8em;
            width: 1.8em;
          }
        }
      }
    }

    @keyframes spinnerOne {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    // 第二种
    .canvasTwoBox {
      position: relative;

      .spinnerTwoBox {
        animation: spinnerTwo 1s linear infinite;
        background: #4db6ac;
        border-radius: 100px;
        height: 3em;
        transform-origin: top;
        position: absolute;
        top: 50%;
        width: 0.22em;
      }

      .hourHandBox {
        animation: spinnerTwo 7s linear infinite;
        background: #4db6ac;
        border-radius: 100px;
        height: 2em;
        transform-origin: top;
        position: absolute;
        top: 50%;
        width: 0.2em;
      }

      .dotBox {
        background: #4db6ac;
        border-radius: 100%;
        height: 0.5em;
        width: 0.5em;
      }
    }

    @keyframes spinnerTwo {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    // 第三种
    .spinnerThreeBox {
      animation: spinnerThree 1s linear infinite;
      background: #4db6ac;
      border-radius: 100%;
      width: 3em;
      height: 3em;
    }

    @keyframes spinnerThree {

      0%,
      35% {
        background: #4db6ac;
        transform: scale(1);
      }

      20%,
      50% {
        background: #80cbc4;
        transform: scale(1.3);
      }
    }

    // 第四种
    .spinnerFourBox {
      animation: spinnerFour 1s linear infinite;
      border: solid 7px transparent;
      border-top: solid 7px #4db6ac;
      border-radius: 100%;
      width: 3em;
      height: 3em;
    }

    @keyframes spinnerFour {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    // 第五种
    .spinnerFiveBox {
      animation: spinnerFive 1s linear infinite;
      border: solid 1.5em #4db6ac;
      border-right: solid 1.5em transparent;
      border-left: solid 1.5em transparent;
      border-radius: 100%;
      width: 0;
      height: 0;
    }

    @keyframes spinnerFive {
      0% {
        transform: rotate(0deg);
      }

      50% {
        transform: rotate(60deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    // 第六种
    .spinnerSexBox {
      background: #4db6ac;
      border-radius: 50%;
      height: 1em;
      margin: 0.1em;
      width: 1em;
    }

    .p1 {
      animation: fall 1s linear 0.3s infinite;
    }

    .p2 {
      animation: fall 1s linear 0.2s infinite;
    }

    .p3 {
      animation: fall 1s linear 0.1s infinite;
    }

    .p4 {
      animation: fall 1s linear infinite;
    }

    @keyframes fall {
      0% {
        transform: translateY(-15px);
      }

      25%,
      75% {
        transform: translateY(0);
      }

      100% {
        transform: translateY(-15px);
      }
    }
  }
}
</style>
