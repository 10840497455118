
import { Vue, Component } from 'vue-property-decorator';
import * as echarts from 'echarts';
import lazyInject from '@/di';
import Loading from '@/components/loading/index.vue';
import WorkbenchesService from '@/services/workbenches.service';

@Component({
  components: {
    Loading,
  },
})
export default class StatisticsMod extends Vue {
  typeLoading: any = false;

  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;

  data = '';
  async init(val: any) {
    this.data = val;
    // const data: any = {
    //   35: {
    //     target_quota_all: 520.00, // 目标
    //     real_finished_all: 36, // 实际
    //     name: '\u8fd0\u8425\u54c1\u724c\u90e8', // 部、组、名
    //     ratio: 0.06923076923076923, // 百分比
    //   },
    //   41: {
    //     target_quota_all: 200.00,
    //     real_finished_all: 235,
    //     name: '\u8fd0\u8425\u4e09\u90e8',
    //     ratio: 1.175,
    //   },
    //   42: {
    //     target_quota_all: 200.00,
    //     real_finished_all: 71,
    //     name: '\u8fd0\u8425\u56db\u90e8',
    //     ratio: 0.355,
    //   },
    // };
    const res = await this.workbenchesService.getAdDeptAgentRatioGroup();
    const chartArr: any = {
      targetQuotaAll: [],
      realFinishedAll: [],
      name: [],
      ratio: [],
    };
    for (const key in res) {
      chartArr.targetQuotaAll.push(Number(res[key].target_quota_all));
      chartArr.realFinishedAll.push(Number(res[key].real_finished_all));
      chartArr.name.push(res[key].name);
      chartArr.ratio.push((Number(res[key].ratio) * 100).toFixed(2));
    }
    setTimeout(() => {
      this.typeLoading = true;
      setTimeout(() => {
        this.setChartData(chartArr);
      }, 1000);
    }, 1000);
  }
  // 其他方法...
  // 完成组件的其他逻辑...
  setChartData(data: any) {
    const option = {
      title: {
        text: '推广通完成率',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999',
          },
        },
        formatter: (params: any) => {
          let result = `<b>${params[0].name}</b><br/>`;
          params.forEach((item: any) => {
            const unit = item.seriesIndex === 2 ? '%' : '元';
            result += `${item.marker} ${item.seriesName}: <b>${item.value}${unit}</b><br/>`;
          });
          return result;
        },
      },
      legend: {
        bottom: 10,

      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: data.name,
        axisLabel: {
          // rotate: 30, // 倾斜角度，0-90度
          margin: 10, // 标签与轴线的距离
          interval: 0, // 强制显示所有标签
          // 其他样式设置
          color: '#666',
          fontSize: 12,
        },
      },
      yAxis: [
        {
          type: 'value',
          scale: true,
          name: '金额',
          min: 0,
          splitLine: {
            show: false, // 不显示右侧轴的网格线
          },
        },
        {
          type: 'value',
          scale: true,
          name: '占比',
          min: 0,
          max: 100,
          interval: 20,
          axisLabel: {
            formatter: '{value} %',
          },
          splitLine: {
            show: false, // 不显示右侧轴的网格线
          },
        },
      ],
      series: [
        {
          name: '推广通目标',
          data: data.targetQuotaAll,
          type: 'bar',
        },
        {
          name: '已完成金额',
          data: data.realFinishedAll,
          type: 'bar',
        },
        {
          name: '完成率',
          type: 'bar',
          data: data.ratio,
          yAxisIndex: 1,
        },
      ],
    };
    // 设置图表数据
    const chart: any = document.getElementById('chart');
    const myChart = echarts.init(chart);
    myChart.setOption(option);
  }
  setChartDatas() {
    const option = {
      title: {
        text: '推广通消耗率',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999',
          },
        },
        formatter: (params: any) => {
          let result = `<b>${params[0].name}</b><br/>`;
          params.forEach((item: any) => {
            const unit = item.seriesIndex === 2 ? '%' : '元';
            result += `${item.marker} ${item.seriesName}: <b>${item.value}${unit}</b><br/>`;
          });
          return result;
        },
      },
      legend: {
        bottom: 10,
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      },
      yAxis: [
        {
          type: 'value',
          scale: true,
          name: '金额',
          splitLine: {
            show: false, // 不显示右侧轴的网格线
          },
        },
        {
          type: 'value',
          scale: true,
          name: '占比',
          min: 0,
          max: 100,
          interval: 20,
          axisLabel: {
            formatter: '{value} %',
          },
          splitLine: {
            show: false, // 不显示右侧轴的网格线
          },
        },
      ],
      series: [
        {
          name: '充值金额',
          data: [120, 200, 150, 80, 70, 110, 130],
          type: 'bar',
        },
        {
          name: '消耗金额',
          data: [10, 100, 350, 30, 70, 120, 30],
          type: 'bar',
        },
        {
          name: '消耗占比',
          type: 'bar',
          data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 120.3],
          yAxisIndex: 1,
        },
      ],
    };
    // 设置图表数据
    const chart: any = document.getElementById('charts');
    const myChart = echarts.init(chart);
    myChart.setOption(option);
  }
}
