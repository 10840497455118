
import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import ImportModal from '@/components/importModal/index.vue';
import moment from 'moment';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';
import { warkLabel } from '@/models/wark';
import { AccountService } from '@/services/account_service';
import { AuthEnum } from '@/services/permission.service';
import { getLastMonth } from '@/common/utils';

@Component({
  components: {
    ImportModal,
  },
})
export default class OperationalPerformance extends Vue {
  @Ref('importModal')
  importModal: any;

  AuthEnum = AuthEnum;
  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;


  @lazyInject(AccountService)
  protected accountService!: AccountService;

  categoryType = '1';

  moment = moment;
  type = 'normal';
  pagination = {
    pageSize: 10,
    current: 1,
    total: 100,
    onChange: this.paginationChange,
  };

  uploadUrl = {
    url: '/wb/performance/import/csv',
    month: '',
    downUrl: '/wb/performance/download',
    dept_id: '',
    title: '运营绩效(普通门店)',
    type: 'normal',
    category: '1',
  };

  filterForm: any = {
    month: '',
    dept_id: undefined,
    group_id: undefined,
    agent_name: '',
  };

  dataSource: any = [];
  columns = warkLabel.init('OperationalLabel');

  columnsRende = ['t_leader', 'leader'];

  columnsKa = warkLabel.init('OperationalLabelKa');

  dept_list: any = [];

  group_list: any = [];

  permissionListOfRole: any = [];
  fetching = false;

  @Watch('$route')
  onRouteChange() {
    const { page, pageSize, month, dept_id, group_id, user_id, user_name } = this.$route.query;
    const params: any = {
      page: page || 1,
      pageSize: pageSize || 10,
      category: this.categoryType,
    };
    if (month) {
      this.filterForm.month = month;
      params.plan_month = month;
    }
    if (dept_id) {
      this.filterForm.dept_id = +dept_id;
      params.dept_id = +dept_id;
    }
    if (group_id) {
      this.filterForm.group_id = +group_id;
      params.group_id = +group_id;
    }
    if (user_name) {
      this.filterForm.user_id = user_id;
      this.permissionListOfRole = {
        key: user_id,
        label: user_name,
      };
      params.user_id = user_id;
    }
    this.pagination.current = Number(page);
    this.pagination.pageSize = Number(pageSize);
    this.getList(params);
  }
  funUpload() {
    this.importModal.init('promotionCompletion');
  }

  async searchPermission(value: string) {
    try {
      const res = await this.accountService.getLeaderList({
        page: 1,
        pageSize: 1000,
        userName: value,
      });
      this.permissionOptions = res.list;
    } catch (error) {
      // console.log(error);
    }
  }

  permissionOptions: any[] = [];

  async handleChange(value: any) {
    this.permissionListOfRole = value;
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }
  changeMonth(val: any) {
    const year = val._d.getFullYear(); // 获取当前年份，例如：2021
    let month: any = Number(val._d.getMonth()) + 1; // 获取当前月份，注意需要加1，例如：9
    if (month < 10) {
      month = `0${month}`;
    }
    const _d = `${year}-${month}`;
    this.filterForm.month = _d;
  }
  changeUploadMonth(val: any) {
    const year = val._d.getFullYear(); // 获取当前年份，例如：2021
    let month: any = Number(val._d.getMonth()) + 1; // 获取当前月份，注意需要加1，例如：9
    if (month < 10) {
      month = `0${month}`;
    }
    const _d = `${year}-${month}`;
    this.uploadUrl.month = _d;
  }
  selectOption(val: any) {
    this.group_list = this.dept_list.find((res: any) => res.dept.id === val).group;
    this.filterForm.group_id = undefined;
  }
  async getList(params: any) {
    const res = await this.workbenchesService.getStaffPerformanceList(params);
    if (res) {
      this.dataSource = res;
    }
  }
  changeType(type: string) {
    this.type = type;
    if (type === 'normal') {
      this.categoryType = '1';
      this.uploadUrl.category = '1';
      this.uploadUrl.title = '运营绩效(普通门店)';
      this.uploadUrl.type = 'normal';
    } else if (type === 'brand') {
      this.categoryType = '2';
      this.uploadUrl.category = '2';
      this.uploadUrl.title = '运营绩效(品牌门店)';
      this.uploadUrl.type = 'brand';
    }
    this.onRouteChange();
  }
  refresh() {
    this.uploadUrl.month = '';
    this.onRouteChange();
  }
  search() {
    const { month, dept_id, group_id, agent_name } = this.filterForm;
    // const { current, pageSize } = this.pagination;
    const { key, label } = this.permissionListOfRole;
    const query: any = {};
    if (month) {
      query.month = month;
    }
    if (dept_id) {
      query.dept_id = +dept_id;
    }
    if (group_id) {
      query.group_id = +group_id;
    }
    if (agent_name) {
      query.agent_name = agent_name;
    }
    if (key || label) {
      query.user_id = key;
      query.user_name = label;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  mounted() {
    this.onRouteChange();
    this.getDeptInfo();
    // this.searchPermission();
    this.changColums(this.columns, this.columnsRende);
    this.changColums(this.columnsKa, this.columnsRende);
  }
  changColums(columns: any, rende: any) {
    const that = this;
    columns.forEach((element: any) => {
      if (rende.includes(element.dataIndex)) {
        element.customRender = (text: any, row: any, index: any) => {
          const obj = that.customRender(text, row, index, `${element.dataIndex}_id`);
          return obj;
        };
      }
    });
  }
  customRender(text: any, row: any, index: number, column: any) {
    const prevRow: any = this.dataSource[index - 1];
    if (prevRow && prevRow[column] === row[column]) {
      return {
        children: text,
        attrs: {
          rowSpan: 0,
        },
      };
    }
    let rowSpan = 1;
    for (let i = index + 1; i < this.dataSource.length; i++) {
      const currentRow: any = this.dataSource[i];
      if (currentRow[column] === row[column]) {
        rowSpan++;
      } else {
        break;
      }
    }
    return {
      children: text,
      attrs: {
        rowSpan,
      },
    };
  }

  async getDeptInfo() {
    const res = await this.workbenchesService.getDeptInfo();
    this.dept_list = res;
    const { dept_id } = this.$route.query;
    if (!dept_id) return;
    const deptId = Number(dept_id);
    this.group_list = this.dept_list.find((item: any) => item.dept.id === deptId).group;
  }
  resetQuery() {
    // const currentDate = new Date();
    // // const currentMonth = currentDate.getMonth() + 1;
    // const currentMonth = currentDate.getMonth() === 0 ? 12 : currentDate.getMonth();
    // const currenrYear = currentDate.getMonth() === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
    // const _D = `${currenrYear}-${currentMonth < 10 ? `0${currentMonth}` : currentMonth}`;
    // this.filterForm.month = _D;
    this.filterForm = {
      month: getLastMonth(),
      dept_id: undefined,
      group_id: undefined,
      agent_name: '',
    };
    const query: any = {
      page: 1,
      pageSize: 10,
      month: getLastMonth(),
    };
    this.permissionListOfRole = [];
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  paginationChange(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    // const query: any = {
    //   page,
    //   pageSize,
    // };
    // query.timestamp = Math.round(Date.now() / 1000);
    // this.$router.push({
    //   query,
    // });
    this.search();
  }
}
