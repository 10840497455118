
import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';
import { AccountService } from '@/services/account_service';
import { getCurrentMonth } from '@/common/utils';

@Component({})
export default class PromotionOfBilling extends Vue {
  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;

  @lazyInject(AccountService)
  protected accountService!: AccountService;

  moment = moment;
  dataSource = [];
  columns = [
    {
      title: '部门',
      dataIndex: 'dept_name',
      align: 'center',
      customRender: (text: any, row: any, index: any) => {
        const obj = this.customRender(text, row, index, 'dept_id');
        return obj;
      },
    },
    {
      title: '组',
      dataIndex: 'group_name',
      align: 'center',
      customRender: (text: any, row: any, index: any) => {
        const obj = this.customRender(text, row, index, 'group_id');
        return obj;
      },
    },
    {
      title: '组员',
      dataIndex: 'agent_name',
      align: 'center',
      customRender: (text: any, row: any, index: any) => {
        const obj = this.customRender(text, row, index, 'agent_id');
        return obj;
      },
    },
    {
      title: '个数',
      dataIndex: 'count',
      align: 'center',
      customRender: (text: any, row: any, index: any) => {
        const obj = this.customRender(text, row, index, 'agent_id');
        obj.children = obj.attrs.rowSpan;
        return obj;
      },
    },
    {
      title: '门店名称',
      dataIndex: 'shop_name',
      align: 'center',
    },
    {
      title: '金额',
      dataIndex: 'pay_price',
      align: 'center',
    },
    {
      title: '付费时间',
      dataIndex: 'paytime',
      align: 'center',
    },
    {
      title: '备注',
      dataIndex: 'desc',
      align: 'center',
    },
  ];
  permissionListOfRole: any = [];
  fetching = false;
  permissionOptions: any = [];
  pagination = {
    pageSize: 10,
    current: 1,
    total: 0,
    onChange: this.paginationChange,
  };
  times_list = [
    {
      value: '1',
      label: '首开',
    },
    {
      value: '2',
      label: '一充',
    },
    {
      value: '3',
      label: '二充',
    },
    {
      value: '4',
      label: '三充',
    },
  ];
  timesData = '1';
  filterForm: any = {
    month: '',
    dept_id: undefined,
    group_id: undefined,
    agent_name: '',
  };
  dept_list: any = [];

  group_list: any = [];

  @Watch('$route')
  onRouteChange() {
    const { page, pageSize, month, dept_id, group_id, agent_name, agent_id } = this.$route.query;
    const params: any = {
      page: page || 1,
      pageSize: pageSize || 10,
    };
    if (month) {
      this.filterForm.month = month;
      params.month = month;
    }
    if (dept_id) {
      this.filterForm.dept_id = +dept_id;
      params.dept_id = +dept_id;
    }
    if (group_id) {
      this.filterForm.group_id = +group_id;
      params.group_id = +group_id;
    }
    if (agent_name) {
      this.filterForm.agent_name = agent_name;
      params.agent_name = agent_name;
    }
    if (agent_id) {
      this.filterForm.agent_id = +agent_id;
      params.agent_id = +agent_id;
      this.permissionListOfRole = {
        key: agent_id,
        label: agent_name,
      };
    }

    this.getList(this.timesData, params);
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }
  search() {
    const { month, dept_id, group_id, agent_name } = this.filterForm;
    const { key, label } = this.permissionListOfRole;
    const query: any = {
      page: 1,
      pageSize: 10,
    };
    if (month) {
      query.month = month;
    }
    if (dept_id) {
      query.dept_id = +dept_id;
    }
    if (group_id) {
      query.group_id = +group_id;
    }
    if (agent_name) {
      query.agent_name = agent_name;
    }
    if (key || label) {
      query.agent_id = key;
      query.agent_name = label;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  mounted() {
    this.onRouteChange();
    this.getDeptInfo();
  }
  async handleChange(value: any) {
    this.permissionListOfRole = value;
  }
  async searchPermission(value: string) {
    try {
      const res = await this.accountService.getLeaderList({
        page: 1,
        pageSize: 1000,
        userName: value,
      });
      this.permissionOptions = res.list;
    } catch (error) {
      // console.warn(error);
    }
  }
  async getDeptInfo() {
    const res = await this.workbenchesService.getDeptInfo();
    this.dept_list = res;
    const { dept_id } = this.$route.query;
    if (!dept_id) return;
    const deptId = Number(dept_id);
    this.group_list = this.dept_list.find((item: any) => item.dept.id === deptId).group;
  }
  timesClick(val: any) {
    this.timesData = val;
    this.pagination.current = 1;
    const { pageSize, month, dept_id, group_id, agent_name, agent_id } = this.$route.query;
    const query: any = {
      page: 1,
      pageSize: pageSize || 10,
    };
    if (month) {
      query.month = month;
    }
    if (dept_id) {
      query.dept_id = +dept_id;
    }
    if (group_id) {
      query.group_id = +group_id;
    }
    if (agent_name) {
      query.agent_name = agent_name;
    }
    if (agent_id) {
      query.agent_id = +agent_id;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  resetQuery() {
    this.filterForm = {
      month: getCurrentMonth(),
      dept_id: undefined,
      group_id: undefined,
      agent_name: '',
      agent_id: undefined,
    };
    this.permissionListOfRole = [];
    const query: any = {
      page: 1,
      pageSize: 10,
      month: getCurrentMonth(),
    };
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  refresh() {
  }


  changeMonth(val: any) {
    const year = val._d.getFullYear(); // 获取当前年份，例如：2021
    let month: any = Number(val._d.getMonth()) + 1; // 获取当前月份，注意需要加1，例如：9
    if (month < 10) {
      month = `0${month}`;
    }
    const _d = `${year}-${month}`;
    this.filterForm.month = _d;
  }
  selectOption(val: any) {
    this.group_list = this.dept_list.find((res: any) => res.dept.id === val).group;
    this.filterForm.group_id = undefined;
  }


  customRender(text: any, row: any, index: number, column: any) {
    const prevRow: any = this.dataSource[index - 1];
    if (prevRow && prevRow[column] === row[column]) {
      return {
        children: text,
        attrs: {
          rowSpan: 0,
        },
      };
    }
    let rowSpan = 1;
    for (let i = index + 1; i < this.dataSource.length; i++) {
      const currentRow: any = this.dataSource[i];
      if (currentRow[column] === row[column]) {
        rowSpan++;
      } else {
        break;
      }
    }
    return {
      children: text,
      attrs: {
        rowSpan,
      },
    };
  }
  paginationChange(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    const { month, dept_id, group_id, agent_name } = this.filterForm;
    const { key, label } = this.permissionListOfRole;
    const query: any = {
      page,
      pageSize,
    };
    if (month) {
      query.month = month;
    }
    if (dept_id) {
      query.dept_id = +dept_id;
    }
    if (group_id) {
      query.group_id = +group_id;
    }
    if (agent_name) {
      query.agent_name = agent_name;
    }
    if (key || label) {
      query.agent_id = key;
      query.agent_name = label;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  async getList(timesData: any, params: any) {
    const res = await this.workbenchesService.getAdRechargeShops(timesData, params);
    this.dataSource = res.list;
    this.pagination.current = res.page;
    this.pagination.total = res.total;
  }
}
