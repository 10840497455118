
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
// import icon_xianjinliu from '@/assets/icon/icon_xianjinliu.png';
// import icon_hebingzhifu from '@/assets/icon/icon_hebingzhifu.png';
// import icon_jixiao from '@/assets/icon/icon_jixiao.png';
// import icon_jiankangshuju from '@/assets/icon/icon_jiankangshuju.png';
// import icon_kaidan from '@/assets/icon/icon_kaidan.png';
// import icon_rengongjixiao from '@/assets/icon/icon_rengongjixiao.png';
// import icon_baobiao from '@/assets/icon/icon_baobiao.png';
// import icon_wanchenglv from '@/assets/icon/icon_wanchenglv.png';
// import icon_yungongjixiao from '@/assets/icon/icon_yungongjixiao.png';
// import icon_increase from '@/assets/icon/icon_increase.png';
// import icon_performance from '@/assets/icon/icon_performance.png';
import Loading from '@/components/loading/index.vue';
import { getCurrentMonth, getLastMonth } from '@/common/utils';

@Component({
  components: {
    Loading,
  },
})
export default class QuickEntrance extends Vue {
  typeList: any = {
    icon_xianjinliu: 'workbenches.shouldEnrich',
    icon_hebingzhifu: 'workbenches.mergePayment',
    icon_jixiao: 'workbenches.operationalPerformance',
    icon_jiankangshuju: 'workbenches.promoteCollision',
    icon_kaidan: 'workbenches.promotionOfBilling',
    icon_rengongjixiao: 'workbenches.ministerialPerformance',
    icon_wanchenglv: 'workbenches.promotionCompletion',
    icon_increase: 'workbenches.growthData',
    icon_performance: 'workbenches.monthlyPerformance',
    icon_baobiao: 'workbenches.reportForms',
    icon_yungongjixiao: 'workbenches.operationalPerformanceLeader',
  };
  lading = false;
  objList: any = {
    // 大B组员
    kaTeam: ['icon_wanchenglv', 'icon_jixiao', 'icon_kaidan', 'icon_xianjinliu'],
    // 大B组长
    kaTeamLeader: ['icon_wanchenglv', 'icon_jixiao', 'icon_kaidan', 'icon_xianjinliu'],
    // 大B部长
    kaTeamMinister: ['icon_wanchenglv', 'icon_jixiao', 'icon_kaidan', 'icon_xianjinliu', 'icon_performance', 'icon_yungongjixiao'],
    // 小B组员
    ordinaryTeam: ['icon_wanchenglv', 'icon_jixiao', 'icon_kaidan', 'icon_increase', 'icon_xianjinliu', 'icon_jiankangshuju', 'icon_hebingzhifu'],
    // 小B组长
    ordinaryTeamLeader: ['icon_wanchenglv', 'icon_jixiao', 'icon_kaidan', 'icon_increase', 'icon_xianjinliu', 'icon_jiankangshuju', 'icon_hebingzhifu'],
    // 小B部长
    ordinaryTeamMinister: ['icon_wanchenglv', 'icon_jixiao', 'icon_kaidan', 'icon_increase', 'icon_xianjinliu', 'icon_jiankangshuju', 'icon_hebingzhifu', 'icon_performance', 'icon_yungongjixiao'],
    // 助理总监
    assistantDirector: ['icon_xianjinliu', 'icon_hebingzhifu', 'icon_jixiao', 'icon_jiankangshuju', 'icon_kaidan', 'icon_rengongjixiao', 'icon_wanchenglv', 'icon_increase', 'icon_performance', 'icon_baobiao', 'icon_yungongjixiao'],

    // 总监
    director: ['icon_xianjinliu', 'icon_hebingzhifu', 'icon_jixiao', 'icon_jiankangshuju', 'icon_kaidan', 'icon_rengongjixiao', 'icon_wanchenglv', 'icon_increase', 'icon_performance', 'icon_baobiao', 'icon_yungongjixiao'],
    // director: ['icon_rengongjixiao', 'icon_performance', 'icon_wanchenglv', 'icon_baobiao'],
  };

  @Prop({ type: String, default: 'assistantDirector' })
  type?: any;

  @Watch('type')
  watchType() {
    this.lading = true;
  }

  closeLading() {
    this.lading = true;
  }

  handleClick(name: string) {
    if (name === 'icon_kaidan' || name === 'icon_hebingzhifu' || name === 'icon_baobiao') {
      const query: any = {
        month: getCurrentMonth(),
      };
      this.$router.push({ name: this.typeList[name], query });
    } else if (name === 'icon_jixiao' || name === 'icon_jiankangshuju' || name === 'icon_rengongjixiao' || name === 'icon_yungongjixiao') {
      const query: any = {
        month: getLastMonth(),
      };
      this.$router.push({ name: this.typeList[name], query });
    } else if (name === 'icon_wanchenglv') {
      const query: any = {
        month: getCurrentMonth(),
        type: this.type,
      };
      this.$router.push({ name: this.typeList[name], query });
    } else {
      this.$router.push({ name: this.typeList[name] });
    }
  }
}
