
import { Vue, Component } from 'vue-property-decorator';
import lazyInject from '@/di';
import OrderService from '@/services/order.service';
import { Order } from '@/common/interface/order';
import QRCode from 'qrcode';
// import html2canvas from 'html2canvas';

@Component({})
export default class LookQRCode extends Vue {
  @lazyInject(OrderService)
  orderService!: OrderService;

  order: any = '';
  isVisible = false;

  pr_img: any = '';

  async open(order: Order) {
    this.order = Object.assign({}, order);
    const res = await this.orderService.orderGrInfo(order?.orderId);
    const dataUrl = this.generateQRDataURL(res.qr);
    dataUrl.then((img) => {
      this.pr_img = img;
    });
    this.isVisible = true;
  }

  async generateQRDataURL(url: string): Promise<string> {
    try {
      return await QRCode.toDataURL(url);
    } catch (err) {
      throw new Error('Failed to generate QR code');
    }
  }

  close() {
    this.reset();
  }

  submit() { }


  reset() {
    this.isVisible = false;
  }
}
