
import { Vue, Component, Prop } from 'vue-property-decorator';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';

@Component({})
export default class Foundation extends Vue {
  @Prop({ type: String, default: '' })
  type?: any;


  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;

  position: any = {
    assistantDirector: '总监助理',
    kaTeamLeader: '大B组长',
    kaTeam: '大B组员',
    kaTeamMinister: '大B部长',
    ordinaryTeamLeader: '小B组长',
    ordinaryTeam: '小B组员',
    ordinaryTeamMinister: '小B部长',
    director: '总监',
  };
  data: any = {
    // user_id: 63,
    // name: '张健',
    // dept: '运营一部一组',
    // dept_id: 6,
    // agent_id: 0,
    // ka_agent: false, // 是否是大B运营，false-不是，true--是
    // level: '总监', // 组员，组长、部长、总监
    // group_leader: '赵小宁', // 组长 （组长身份，该值为空）
    // minister: '阿文', // 部长 （部长身份，该值为空）
  };
  typeChild: any = '';
  mounted() {
    this.getInfo();
  }

  async getInfo() {
    const res = await this.workbenchesService.getBaseInfo();
    this.data = res;
    this.distinguishLevel();
  }
  distinguishLevel() {
    switch (this.data.level) {
      case '总监':
        this.typeChild = 'director';
        break;
      case '助理':
        this.typeChild = 'assistantDirector';
        break;
      case '组长':
        if (this.data.ka_agent) {
          this.typeChild = 'kaTeamLeader';
        } else {
          this.typeChild = 'ordinaryTeamLeader';
        }
        break;
      case '部长':
        if (this.data.ka_agent) {
          this.typeChild = 'kaTeamMinister';
        } else {
          this.typeChild = 'ordinaryTeamMinister';
        }
        break;
      default:
        if (this.data.ka_agent) {
          this.typeChild = 'kaTeam';
        } else {
          this.typeChild = 'ordinaryTeam';
        }
        break;
    }
    this.$emit('changeType', this.typeChild);
  }
}
