
import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';
import { warkLabel } from '@/models/wark';
import { getLastMonth } from '@/common/utils';

@Component({})
export default class MinisterialPerformance extends Vue {
  moment = moment;
  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;

  dataSource: any = [];
  columnsChild = warkLabel.init('MinisterialLabel');
  columnsKA = warkLabel.init('MinisterialLabelKa');
  filterForm: any = {
    month: '',
  };
  type = 'normal';
  categoryType = '1';
  @Watch('$route')
  onRouteChange() {
    const { month } = this.$route.query;
    const params: any = {
      // page: page || 1,
      // pageSize: pageSize || 10,
      category: this.categoryType,
    };
    if (month) {
      this.filterForm.month = month;
      params.plan_month = month;
    }

    // this.pagination.current = Number(page);
    // this.pagination.pageSize = Number(pageSize);
    this.getList(params);
  }
  changeType(type: string) {
    this.type = type;
    if (type === 'normal') {
      this.categoryType = '1';
    } else if (type === 'brand') {
      this.categoryType = '2';
    }
    this.dataSource = [];
    this.onRouteChange();
  }
  changeMonth(val: any) {
    const year = val._d.getFullYear(); // 获取当前年份，例如：2021
    let month: any = Number(val._d.getMonth()) + 1; // 获取当前月份，注意需要加1，例如：9
    if (month < 10) {
      month = `0${month}`;
    }
    const _d = `${year}-${month}`;
    this.filterForm.month = _d;
  }
  mounted() {
    this.onRouteChange();
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }
  refresh() {
  }
  search() {
    const { month } = this.filterForm;
    const query: any = {
      month,
    };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  resetQuery() {
    this.filterForm = {
      month: getLastMonth(),
    };
    const query: any = {
      month: getLastMonth(),
    };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  async getList(params: any) {
    // console.log(params);
    const res = await this.workbenchesService.getDeptLeaderPerformanceList(params);
    this.dataSource = res;
  }
}
