
import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';
import lazyInject from '@/di';
import WithdrawService from '@/services/withdraw.service';
import { WithdrawExamineStatus } from '@/common/enum/withdraw';
import { CAuditDetail } from '@/models/apply.model';
import { IAuditDetail } from '@/common/interface/apply';
import { handleNum } from '@/common/utils';
import UTILService from '@/services/utils.service';
import {
  AuthEnum,
} from '@/services/permission.service';

@Component({})
export default class AuditDetails extends Vue {
  AuthEnum = AuthEnum;
  @lazyInject(WithdrawService)
  withdrawService!: WithdrawService;

  @lazyInject(UTILService)
  private utilService!: UTILService;

  selectedRowKeys: any = []; // Check here to configure the default column

  handleNum = handleNum;

  WithdrawExamineStatus = WithdrawExamineStatus;

  statusList: WithdrawExamineStatus[] = [
    WithdrawExamineStatus.待审核,
    WithdrawExamineStatus.审核未通过,
    WithdrawExamineStatus.提现成功,
    WithdrawExamineStatus.提现中,
  ];

  filterForm: {
    withdrawal_no: string;
    status: any;
    pay_ts_start: string;
    pay_ts_end: string;
    account_no: string;
  } = {
      withdrawal_no: '',
      status: undefined,
      pay_ts_start: '',
      pay_ts_end: '',
      account_no: '',
    };

  verifyts_data: any = [];
  columns = [
    {
      title: '提现单号',
      dataIndex: 'withdrawalNo',
      align: 'center',
      width: 200,
    },
    {
      title: '总金额',
      dataIndex: 'money',
      align: 'center',
      scopedSlots: { customRender: 'money' },
      width: 120,
    },
    {
      title: '状态',
      dataIndex: 'statusStr',
      align: 'center',
      width: 120,
    },
    {
      title: '提现账号',
      dataIndex: 'accountNo',
      align: 'center',
      scopedSlots: { customRender: 'accountNo' },
      width: 240,
    },
    {
      title: '申请时间',
      dataIndex: 'ctime',
      align: 'center',
      width: 200,
    },
    {
      title: '审核时间',
      dataIndex: 'verifyTime',
      align: 'center',
      scopedSlots: { customRender: 'verifyTime' },
      width: 200,
    },
    {
      title: '提现人',
      dataIndex: 'username',
      align: 'center',
      scopedSlots: { customRender: 'username' },
      width: 200,
    },
    // {
    //   title: '支付宝转账订单单号',
    //   dataIndex: 'payNo',
    //   align: 'center',
    //   scopedSlots: { customRender: 'pay_no' },
    //   width: 200,
    // },
    // {
    //   title: '支付宝转账流水号',
    //   dataIndex: 'flowNo',
    //   align: 'center',
    //   scopedSlots: { customRender: 'flowNo' },
    //   width: 200,
    // },
    {
      title: '转账时间',
      dataIndex: 'transferTime',
      align: 'center',
      scopedSlots: { customRender: 'transferTime' },
      width: 200,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      align: 'center',
      width: 200,
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
      width: 200,
    },
  ];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
    showTotal: (total: any) => `共 ${total} 条`,
  };

  data = [];

  encryptAccount = '';

  loading = true;

  @Watch('$route')
  routeChange() {
    const { pay_ts_start, pay_ts_end, withdrawal_no, status, account_no, page, pageSize } = this.$route.query;
    const params: any = {
      page: page || 1,
      pageSize: pageSize || this.pagination.pageSize,
    };
    this.pagination.current = page ? Number(page) : 1;
    if (pay_ts_start && pay_ts_end) {
      params.pay_ts_start = pay_ts_start;
      params.pay_ts_end = pay_ts_end;
      this.filterForm.pay_ts_start = pay_ts_start as string;
      this.filterForm.pay_ts_end = pay_ts_end as string;
      this.verifyts_data = [moment(pay_ts_start as string), moment(pay_ts_end as string)];
    }
    if (withdrawal_no) {
      this.filterForm.withdrawal_no = withdrawal_no as string;
      params.withdrawal_no = withdrawal_no;
    }
    if (status || status === '0') {
      this.filterForm.status = Number(status);
      params.status = status as string;
    }
    if (account_no) {
      this.filterForm.account_no = account_no as string;
      params.account_no = account_no;
    }
    this.getkaWithdrawalRecord(params);
  }

  async LookShopDetail(title: string, encryptAccount: string) {
    const data = {
      encrypt_phone: encryptAccount,
      operate_type: title,
    };
    try {
      const res = await this.utilService.postPhoneDecrypt(data);
      if (res) {
        this.encryptAccount = res;
      }
    } catch (error) {
      this.$message.error('解密失败');
    }
  }

  paginationChange(page: number, pageSize: number) {
    const { pay_ts_start, pay_ts_end, withdrawal_no, status, account_no } = this.filterForm;
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    const query: any = {
      page,
      pageSize,
    };
    if (pay_ts_start) {
      query.pay_ts_start = pay_ts_start;
    }
    if (pay_ts_end) {
      query.pay_ts_end = pay_ts_end;
    }
    if (withdrawal_no) {
      query.withdrawal_no = withdrawal_no;
    }
    if (status) {
      query.status = status;
    }
    if (account_no) {
      query.account_no = account_no;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  handleRowChange() { }

  go(routeName: string, id: any, no: string): void {
    this.$router.push({
      name: routeName,
      params: {
        id,
        no,
        type: 'detail',
      },
    });
  }

  onChange(dates: any) {
    this.verifyts_data = dates;
    this.filterForm.pay_ts_start = dates[0]?.format('YYYY-MM-DD');
    this.filterForm.pay_ts_end = dates[1]?.format('YYYY-MM-DD');
  }

  resetQuery() {
    this.filterForm = {
      withdrawal_no: '',
      status: undefined,
      pay_ts_start: '',
      pay_ts_end: '',
      account_no: '',
    };
    this.pagination.current = 1;
    this.verifyts_data = [];
    const query: any = {};
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  search() {
    const { pay_ts_start, pay_ts_end, status, withdrawal_no, account_no } = this.filterForm;
    const query: any = {
      page: 1,
      pageSize: this.pagination.pageSize,
    };
    if (pay_ts_start) {
      query.pay_ts_start = pay_ts_start;
    }
    if (pay_ts_end) {
      query.pay_ts_end = pay_ts_end;
    }
    if (status || status == 0) {
      query.status = status as string;
    }
    if (withdrawal_no) {
      query.withdrawal_no = withdrawal_no;
    }
    if (account_no) {
      query.account_no = account_no;
    }
    this.pagination.current = 1;
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  refresh() { }

  async getkaWithdrawalRecord(params: any) {
    this.loading = true;
    if (params.pay_ts_start && params.pay_ts_end) {
      params.pay_ts_start = `${params.pay_ts_start} 00:00:00`;
      params.pay_ts_end = `${params.pay_ts_end} 23:59:59`;
    }
    const res = await this.withdrawService.kaWithdrawalRecord(params);
    if (res) {
      this.loading = false;
      const auditDetailList = res.list.map((item: IAuditDetail) => CAuditDetail.from(item));
      this.data = auditDetailList;
      this.pagination.total = res.total;
    }
  }
  mounted() {
    this.routeChange();
  }
}
