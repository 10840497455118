/*
 * @Author: Src
 * @Date: 2025-03-24 08:48:43
 * @LastEditTime: 2025-03-31 17:54:09
 * @LastEditors: Src
 * @FilePath: /work-station/src/services/workbenches.service.ts
 * @Description: 头部注释配置模板
 */
// import { WithdrawOrderItem, WithdrawOrderRes } from '@/common/interface/withdraw';
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';


@injectable()
export default class WorkbenchesService {
  @inject(HttpClient)
  protected http!: HttpClient;


  // 基础信息
  async getBaseInfo() {
    try {
      const res = await this.http.get('/wb/base_info') as string;
      return res;
    } catch (error) {
      return error;
    }
  }

  // 我的待办
  async getMyTodo() {
    try {
      const res = await this.http.get('/wb/my_todo') as string;
      return res;
    } catch (error) {
      return null;
    }
  }

  // 应充门店列表
  async getNeedRechargeShops(params: any) {
    try {
      const res = await this.http.get('/wb/need_recharge_shops', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }


  // 撞线门店列表
  async getOverflowShops(params: any) {
    try {
      const res = await this.http.get('/wb/overflow_shops', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }

  // 部门树
  async getDeptInfo() {
    try {
      const res = await this.http.get('/wb/dept_info') as string;
      return res;
    } catch (error) {
      return null;
    }
  }


  // 合并支付投放完成情况
  async getAdDeployedOrder(params: any) {
    try {
      const res = await this.http.get('/wb/ad_deployed_order', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }


  // 广告充值门店列表
  async getAdRechargeShops(times: number, params: any) {
    try {
      const res = await this.http.get(`/wb/ad_recharge_shops/${times}`, { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }


  // 我的成就
  async getMyAchievement() {
    try {
      const res = await this.http.get('/wb/my_achievement') as any;
      return res;
    } catch (error) {
      return null;
    }
  }

  // 员工绩效列表
  async getStaffPerformanceList(params: any) {
    try {
      const res = await this.http.get('/wb/performance/staff/list', { params }) as string;
      return res;
    } catch (error) {
      return null;
    }
  }


  // 组长绩效列表
  async getGroupLeaderPerformanceList(params: any) {
    try {
      const res = await this.http.get('/wb/performance/group_leader/list', { params }) as string;
      return res;
    } catch (error) {
      return null;
    }
  }

  // 部门主管绩效列表
  async getDeptLeaderPerformanceList(params: any) {
    try {
      const res = await this.http.get('/wb/performance/dept_leader/list', { params }) as string;
      return res;
    } catch (error) {
      return null;
    }
  }


  // 绩效模版下载
  async performanceDownload(params: any) {
    try {
      const res = await this.http.get('/wb/performance/download', { params, responseType: 'arraybuffer' }) as string;
      const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      if (params.category === '1') {
        link.download = '员工得分表(普通门店).csv';
      } else {
        link.download = '员工得分表(品牌门店).csv';
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return res;
    } catch (error) {
      return null;
    }
  }

  /**
   * 运营业绩汇总信息
   *
   * @param params 查询参数
   * @returns 返回广告汇总信息，若请求失败则返回 null
   */
  async getAdsummary(params: any) {
    try {
      const res = await this.http.get('/wb/ad/summary', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }

  /**
   * 广告投放门店列表
   * @param params
   * @returns
   * @description 广告投放门店列表
   */
  async getAdSummaryLine(params: any) {
    try {
      const res = await this.http.get('/wb/ad/summary_line', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }

  async getAdSummaryTable(params: any) {
    try {
      const res = await this.http.get('/wb/ad/summary_table', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }

  // 柱状图
  async getAdDeptAgentRatioGroup() {
    try {
      const res = await this.http.get('/wb/ad/dept_agent_ratio_group') as any;
      return res;
    } catch (error) {
      return null;
    }
  }


  // 推广通完成率
  async getAdDeptAgentRatio(params: any) {
    try {
      const res = await this.http.get('/wb/ad/dept_agent_ratio', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }

  // 推广通人效
  async getAdDeptAgentEffect(params: any) {
    try {
      const res = await this.http.get('/wb/ad/dept_agent_effect', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }

  // 撞线率列表
  async getAdCampaignRate(params: any) {
    try {
      const res = await this.http.get('/wb/ad_campaign_rate', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }

  async getNeedDeployedShops(params: any) {
    try {
      const res = await this.http.get('/wb/need_deployed_shops', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }

  /**
   * 推广通完成率模板到出
   */
  async getAdUploadTemplate() {
    try {
      const res = await this.http.get('/wb/ad/upload_template', { responseType: 'arraybuffer' }) as string;
      const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = '员工得分表.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return res;
    } catch (error) {
      return null;
    }
  }

  async getAdUploadTarget() {
    try {
      const res = await this.http.get('/wb/ad/upload_target') as any;
      return res;
    } catch (error) {
      return null;
    }
  }
}
