
import { Vue, Component, Watch, Prop, Model } from 'vue-property-decorator';

@Component({})
export default class IsAutoBrandField extends Vue {
  val: number | string = 0;

  @Prop()
  placeholder?: string;

  @Prop()
  refs!: any;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Model('change', {
    type: Number,
  })
  value!: number;

  @Watch('val')
  watchInternalVal(): void {
    this.$emit('change', +this.val);
  }

  @Watch('value')
  watchValue() {
    if (this.value || this.value == 0) {
      this.val = this.value;
    } else {
      this.val = '';
    }
  }

  mounted() {
    this.watchValue();
  }
}
