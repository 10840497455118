import { render, staticRenderFns } from "./SpuKa.vue?vue&type=template&id=61aa4ee0&scoped=true"
import script from "./SpuKa.vue?vue&type=script&lang=ts"
export * from "./SpuKa.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61aa4ee0",
  null
  
)

export default component.exports