/*
 * @Author: Src
 * @Date: 2024-12-29 16:30:01
 * @LastEditTime: 2025-04-02 12:37:35
 * @LastEditors: Src
 * @FilePath: /work-station/src/common/utils/permission-manager.ts
 * @Description: 权限管理
 */
import { BehaviorSubject, from, throttleTime, distinctUntilChanged, mergeMap, Subject } from 'rxjs';
import { BASE_URL } from '@/common/constant/index';
import axios from 'axios';
import { message } from 'ant-design-vue';

class PermissionManager {
  private eventsMap: Map<string, any>;

  private resultMap: Map<string, any>;

  public constructor() {
    this.eventsMap = new Map();
    this.resultMap = new Map();
  }

  private getData(names: string) {
    const userStorage = localStorage.getItem('user');
    // const userId = userStorage ? JSON.parse(userStorage).id : null;
    const AUTHORIZATION = userStorage ? JSON.parse(userStorage).token : null;
    const item = axios.get(`${BASE_URL}/rbac/permission/${encodeURIComponent(names)}`, {
      data: {
        loadingEl: document.body,
      },
      headers: {
        AUTHORIZATION,
      },
    });
    item.then(() => {
    }).catch((err) => {
      if (err.response.status === 401) {
        message.error('登录已过期，请重新登录');
        window.location.hash = '#/login';
        location.reload();
      }
    });
    return item;
  }

  public send(namesList: string[]) {
    const names = namesList.join(',');
    let result = this.resultMap.get(names);
    if (!result) {
      result = new BehaviorSubject({});
      this.resultMap.set(names, result);
    }

    let events = this.eventsMap.get(names);
    if (!events) {
      events = new Subject();
      events
        .pipe(
          throttleTime(3000),
          mergeMap((name2: string) => from(this.getData(name2))),
          distinctUntilChanged((a: object, b: object) => {
            return JSON.stringify(a) === JSON.stringify(b);
          }),
        )
        .subscribe(result);
      this.eventsMap.set(names, events);
    }

    events.next(names);
    return result;
  }
}

const manager = new PermissionManager();
export default manager;
