
import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';
import { AccountService } from '@/services/account_service';
import { getCurrentMonth } from '@/common/utils';

@Component({})
export default class MergePayment extends Vue {
  moment = moment;

  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;

  @lazyInject(AccountService)
  protected accountService!: AccountService;
  permissionListOfRole: any = [];
  fetching = false;
  permissionOptions: any = [];

  pagination = {
    pageSize: 10,
    current: 1,
    total: 0,
    onChange: this.paginationChange,
  };
  dataSource = [];
  columns = [
    {
      title: '部门',
      dataIndex: 'dept_name',
      key: 'dept_name',
      align: 'center',
      width: 100,
    },
    {
      title: '组',
      dataIndex: 'group_name',
      key: 'group_name',
      align: 'center',
      width: 100,
    },
    {
      title: '组员',
      dataIndex: 'agent_name',
      key: 'agent_name',
      align: 'center',
      width: 100,
    },
    {
      title: '城市',
      dataIndex: 'city',
      key: 'city',
      align: 'center',
      width: 100,
    },
    {
      title: '地推员',
      dataIndex: 'staff_name',
      key: 'staff_name',
      align: 'center',
      width: 100,
    },
    {
      title: '门店名称',
      dataIndex: 'shop_name',
      key: 'shop_name',
      align: 'center',
      width: 100,
    },
    {
      title: 'POI',
      dataIndex: 'poi',
      key: 'poi',
      align: 'center',
      width: 100,
    },
    {
      title: '订单类型',
      dataIndex: 'order_category',
      key: 'order_category',
      align: 'center',
      width: 100,
    },
    {
      title: '支付方式',
      dataIndex: 'pay_channel',
      key: 'pay_channel',
      align: 'center',
      width: 100,
    },
    {
      title: '订单金额',
      dataIndex: 'pay_price',
      key: 'pay_price',
      align: 'center',
      width: 100,
    },
    {
      title: '余额',
      dataIndex: 'advertiser_amount',
      key: 'advertiser_amount',
      align: 'center',
      width: 100,
    },
    // {
    //   title: '划账流水号',
    //   dataIndex: 'remit_account_number',
    //   key: 'remit_account_number',
    //   align: 'center',
    //   width: 100,
    // },
    {
      title: '支付流水号',
      dataIndex: 'alitradeid',
      key: 'alitradeid',
      align: 'center',
      width: 140,
    },
    {
      title: '创建时间',
      dataIndex: 'ctime',
      key: 'ctime',
      align: 'center',
      width: 180,
    },
    // {
    //   title: '充值次数',
    //   dataIndex: 'rechargeTimes',
    //   key: 'rechargeTimes',
    //   align: 'center',
    //   width: 100,
    // },
    {
      title: '是否投放',
      dataIndex: 'ad_campaign',
      key: 'ad_campaign',
      align: 'center',
      width: 100,
      customRender: (value: any) => {
        return value ? '是' : '否';
      },
    },
    {
      title: '投放金额',
      dataIndex: 'ad_campaign_price',
      key: 'ad_campaign_price',
      align: 'center',
      width: 100,
    },
    {
      title: '投放时间',
      dataIndex: 'ad_campaign_time',
      key: 'ad_campaign_time',
      align: 'center',
      width: 180,
    },
  ];

  filterForm: any = {
    month: '',
    dept_id: undefined,
    group_id: undefined,
  };
  dept_list: any = [];

  group_list: any = [];

  @Watch('$route')
  onRouteChange() {
    const { month, dept_id, group_id, agent_name, agent_id, page, pageSize } = this.$route.query;
    const params: any = {
      page: page || 1,
      pageSize: pageSize || 10,
    };
    if (month) {
      this.filterForm.month = month;
      params.month = month;
    }
    if (dept_id) {
      this.filterForm.dept_id = +dept_id;
      params.dept_id = +dept_id;
    }
    if (group_id) {
      this.filterForm.group_id = +group_id;
      params.group_id = +group_id;
    }
    if (agent_name || agent_id) {
      this.filterForm.agent_name = agent_name;
      params.agent_id = agent_id;
      params.agent_name = agent_name;
      this.permissionListOfRole = {
        key: agent_id,
        label: agent_name,
      };
      this.searchPermission(agent_name as string);
    }
    this.getList(params);
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }
  async getList(params: any) {
    const res = await this.workbenchesService.getAdDeployedOrder(params);
    this.dataSource = res.list;
    this.pagination.total = res.total;
    this.pagination.current = res.page;
  }
  refresh() {
    // console.log('refresh');
  }

  async searchPermission(value: string) {
    // console.log(value);
    try {
      const res = await this.accountService.getLeaderList({
        page: 1,
        pageSize: 1000,
        userName: value,
      });
      this.permissionOptions = res.list;
    } catch (error) {
      // console.log(error);
    }
  }

  async handleChange(value: any) {
    // console.log(value);
    this.permissionListOfRole = value;
  }

  search() {
    const { month, dept_id, group_id } = this.filterForm;
    const { key, label } = this.permissionListOfRole;
    const query: any = {
      page: 1,
      pageSize: 10,
    };
    if (month) {
      query.month = month;
    }
    if (dept_id) {
      query.dept_id = dept_id;
    }
    if (group_id) {
      query.group_id = group_id;
    }
    if (key) {
      query.agent_id = key;
      query.agent_name = label;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  resetQuery() {
    this.filterForm = {
      month: getCurrentMonth(),
      dept_id: undefined,
      group_id: undefined,
    };
    this.permissionListOfRole = [];
    const query: any = {};
    query.month = getCurrentMonth();
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  paginationChange(page: number, pageSize: number) {
    // console.log(page, pageSize);
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    const { month, dept_id, group_id } = this.filterForm;
    const { key, label } = this.permissionListOfRole;
    const query: any = {
      page,
      pageSize: 10,
    };
    if (month) {
      query.month = month;
    }
    if (dept_id) {
      query.dept_id = dept_id;
    }
    if (group_id) {
      query.group_id = group_id;
    }
    if (key) {
      query.agent_id = key;
      query.agent_name = label;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  getDataSource() {
    // console.log('getDataSource');
  }

  changeMonth(val: any) {
    const year = val._d.getFullYear(); // 获取当前年份，例如：2021
    let month: any = Number(val._d.getMonth()) + 1; // 获取当前月份，注意需要加1，例如：9
    if (month < 10) {
      month = `0${month}`;
    }
    const _d = `${year}-${month}`;
    this.filterForm.month = _d;
  }

  mounted() {
    this.onRouteChange();
    this.getDeptInfo();
  }

  async getDeptInfo() {
    const res = await this.workbenchesService.getDeptInfo();
    this.dept_list = res;
    const { dept_id } = this.$route.query;
    if (!dept_id) return;
    const deptId = Number(dept_id);
    this.group_list = this.dept_list.find((item: any) => item.dept.id === deptId).group;
  }
  selectOption(val: any) {
    this.group_list = this.dept_list.find((res: any) => res.dept.id === val).group;
    this.filterForm.group_id = undefined;
  }
}
