
import { Vue, Component, Watch } from 'vue-property-decorator';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';

@Component({})
export default class ShouldBeCharged extends Vue {
  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;

  pagination: any = {
    pageSize: 10,
    current: 1,
    total: 0,
    onChange: this.paginationChange,
  };
  dataSource: any = [];
  columns = [
    {
      title: '店铺名称',
      dataIndex: 'shop_name',
      align: 'center',
    },
    {
      title: '门店POI',
      dataIndex: 'poi',
      align: 'center',
    },
    {
      title: '广告主ID',
      dataIndex: 'advertiser_id',
      align: 'center',
    },
    {
      title: '近7天推广通消耗金额',
      dataIndex: 'cost',
      align: 'center',
    },
    {
      title: '推广通余额',
      dataIndex: 'advertiser_amount',
      align: 'center',
    },
    {
      title: '操作',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center',
    },
  ];

  @Watch('$route')
  onRouteChange() {
    const { page, pageSize } = this.$route.query;
    const params = {
      page: page || 1,
      pageSize: pageSize || 10,
    };
    this.pagination.current = Number(page);
    this.pagination.pageSize = Number(pageSize);
    this.getList(params);
  }
  goToShopDetail(id: string) {
    const routeUrl = this.$router.resolve({
      name: 'main.shop.detail',
      params: {
        id: `${id}`,
      },
    });
    window.open(routeUrl.href, '_blank');
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }
  async getList(params: any) {
    const res = await this.workbenchesService.getNeedRechargeShops(params);
    this.dataSource = res.list;
    this.pagination.current = res.page;
    this.pagination.total = res.total;
  }

  // handleClick(record: any) {
  //   console.log(record);
  // }

  mounted() {
    this.onRouteChange();
  }

  paginationChange(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    const query: any = {
      page,
      pageSize,
    };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
}
