/*
 * @Author: Src
 * @Date: 2024-08-07 18:46:34
 * @LastEditTime: 2025-02-14 11:12:04
 * @LastEditors: Src
 * @FilePath: /work-station/src/common/enum/spu.ts
 * @Description: 头部注释配置模板
 */
export enum EKASpuTyreTag {
  静音 = '静音',
  操控 = '操控 ',
  运动 = '运动 ',
  新能源 = '新能源 ',
  稳定 = '稳定',
  全路况 = '全路况',
  防爆 = '防爆',
  冬季胎 = '冬季胎',
  自修补 = '自修补',
}

export enum ESPUField {
  ID = 'id',
  NAME = 'name',
  CATEGORY = 'category',
  COMMISSION_RATIO = 'commissionRatio',
  EFFECTIVE_TIMES = 'effectiveTimes',
  HAS_NEW_USER_PRICE = 'hasNewUserPrice',
  IS_HOT = 'isHot',
  IS_COMBO = 'isCombo', // 是否套餐
  IS_AUTO_BRAND = 'isAutoBrand', // 是否结算至品牌账户
  IS_ONLINE = 'isOnline',
  INTRODUCTION = 'introduction',
  PRODUCTS = 'products', // 服务价格明细
  IMAGES = 'images', // 商品相册
  NEW_USER_PRICE_CENT = 'newUserPriceCent',
  ORIGINAL_PRICE_CENT = 'originalPriceCent',
  VIP_PRICE_CENT = 'vipPriceCent',
  HEADLINE_IMAGE = 'headlineImage', // 商品照片/头图
  HEADLINE_IMAGES = 'headlineImages', // 标品头图
  UNAVAILABLE_TIME = 'unavailableTime',
  COMBO_TOTAL_PRICE = 'comboTotalPrice', // 套餐总价
  SHORT_COMMENT = 'shortComment',
  IS_DRAW_COMMISSION = 'isDrawCommission',
  TEMP_SPU_SHOP_POIS = 'tempSpuShopPois', // 平台标品适用门店
  VIDEOS = 'videos', // 平台标品视频
  TEMP_SPU_SHOP_IDS = 'tempSpuShopIds', // 品牌标品和批量商品适用门店
  KA = 'ka', // 品牌标品ka
  TYRE_TAG = 'tyreTag', // 品牌标品：轮胎-性能
  TYRE_SPEC = 'tyreSpec', // 品牌标品：轮胎-尺寸
  IS_AUTO_OFF_SHELF = 'isAutoOffShelf', // 批量多门店商品是否自动下架
  AUTO_OFF_SHELF_TIME = 'autoOffShelfTime', // 批量多门店商品自动下架时间
  FILTER_TAG = 'filterTag', // 筛选标签
  REMAK = 'remark', // 备注：对标签的解释
}

/** SPU 不要用时段 */
export enum ENonBusinessHours {
  周末不可用 = 1,
  春节期间不可用 = 2,
  法定节假日不可用 = 3,
}


export enum ENProductSource {
  BRAND_STANDARD = 'brand_standard', // 品牌标品
  PLATFORM_STANDARD = 'platform_standard', // 平台标品
  GENERAL_MERCHANDISE = 'general_merchandise', // 普通商品
  BATCH_PRODUCTS = 'batch_products', // 批量商品
}
