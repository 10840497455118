
import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import ImportModal from '@/components/importModal/index.vue';
import moment from 'moment';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';
import { warkLabel } from '@/models/wark';
import { AuthEnum } from '@/services/permission.service';
import { getCurrentMonth } from '@/common/utils';

@Component({
  components: {
    ImportModal,
  },
})
export default class PromotionCompletion extends Vue {
  @Ref('importModal')
  importModal: any;

  AuthEnum = AuthEnum;

  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;

  filterForm: any = {
    month: '',
    dept_id: undefined,
    group_id: undefined,
  };
  moment = moment;

  uploadUrl = {
    url: '/wb/ad/upload_target',
    month: '',
    downUrl: '/wb/performance/download?dept_id=4',
    type: 'PromotionCompletion',
    title: '推广通完成率',
  };

  dataSource = [];
  dataList: any = [];

  columns = warkLabel.init('PromotionCompletion');

  dept_list: any = [];

  group_list: any = [];

  type: any = '';

  pagination = {
    pageSize: 10,
    current: 1,
    total: 100,
    onChange: this.paginationChange,
  };
  @Watch('$route')
  onRouteChange() {
    const { month, dept_id, group_id, agent_name } = this.$route.query;
    const params: any = {};
    if (month) {
      this.filterForm.month = month;
      params.month = month;
    }
    if (dept_id) {
      this.filterForm.dept_id = +dept_id;
      params.dept_id = +dept_id;
    }
    if (group_id) {
      this.filterForm.group_id = +group_id;
      params.group_id = +group_id;
    }
    if (agent_name) {
      this.filterForm.agent_name = agent_name;
      params.agent_name = agent_name;
    }
    // this.pagination.current = Number(page);
    // this.pagination.pageSize = Number(pageSize);
    this.getList(params);
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }

  changeMonth(val: any) {
    const year = val._d.getFullYear(); // 获取当前年份，例如：2021
    let month: any = Number(val._d.getMonth()) + 1; // 获取当前月份，注意需要加1，例如：9
    if (month < 10) {
      month = `0${month}`;
    }
    const _d = `${year}-${month}`;
    this.filterForm.month = _d;
  }
  changeUploaMonth(val: any) {
    const year = val._d.getFullYear(); // 获取当前年份，例如：2021
    let month: any = Number(val._d.getMonth()) + 1; // 获取当前月份，注意需要加1，例如：9
    if (month < 10) {
      month = `0${month}`;
    }
    this.uploadUrl.month = `${year}-${month}`;
    this.uploadUrl.url = `${this.uploadUrl.url}?month=${this.uploadUrl.month}`;
  }
  async getList(params: any) {
    const res = await this.workbenchesService.getAdDeptAgentRatio(params);
    this.func(res);
  }
  paginationChange(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    const query: any = {
      page,
      pageSize,
    };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  openImportModal() {
    this.importModal.init('promotionCompletion');
  }
  refresh() {
    this.onRouteChange();
  }

  func(data: any) {
    const dataSource = data;
    dataSource.forEach((item: any) => {
      let monthTarget = 0; let monthCompleted = 0; let monthCompletedRate: any = 0;
      dataSource.forEach((rem: any) => {
        if (item.dept_id === rem.dept_id) {
          monthTarget += Number(rem.target_quota);
          monthCompleted += Number(rem.month_real_price);
          monthCompletedRate = `${((monthCompleted / monthTarget) * 100).toFixed(2)}%`;
        }
      });
      let groupTarget = 0;
      let groupCompleted = 0;
      let groupCompletedRate: any = 0;
      dataSource.forEach((rem: any) => {
        if (item.group_id === rem.group_id) {
          groupTarget += Number(rem.target_quota);
          groupCompleted += Number(rem.month_real_price);
          groupCompletedRate = `${((groupCompleted / groupTarget) * 100).toFixed(2)}%`;
        }
      });
      item.monthTarget = monthTarget;
      item.monthCompleted = monthCompleted;
      item.monthCompletedRate = monthCompletedRate;
      item.groupTarget = groupTarget;
      item.groupCompleted = groupCompleted;
      item.groupCompletedRate = groupCompletedRate;
    });
    this.dataList = dataSource;
  }
  search() {
    const { month, dept_id, group_id, agent_name } = this.filterForm;
    const query: any = {};
    // const { key, label } = this.permissionListOfRole;
    if (month) {
      query.month = month;
    }
    if (dept_id) {
      query.dept_id = +dept_id;
    }
    if (group_id) {
      query.group_id = +group_id;
    }
    if (agent_name) {
      query.agent_name = agent_name;
    }
    // if (key || label) {
    //   query.user_id = key;
    //   query.user_name = label;
    // }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  tableRowClassName(record: any) {
    switch (record.leader) {
      case '运营一部':
        return 'blue';
      case '运营二部':
        return 'red';
      default:
        return 'green';
    }
  }
  customRender(text: any, row: any, index: any, column: any) {
    const prevRow: any = this.dataList[index - 1];
    if (prevRow && prevRow[column] === row[column]) {
      return {
        children: text,
        attrs: {
          rowSpan: 0,
        },
      };
    }
    let rowSpan = 1;
    const _i: number = index;
    for (let i = _i + 1; i < this.dataList.length; i++) {
      const currentRow: any = this.dataList[i];
      if (currentRow[column] === row[column]) {
        rowSpan++;
      } else {
        break;
      }
    }
    return {
      children: text,
      attrs: {
        rowSpan,
      },
    };
  }

  resetQuery() {
    const query: any = {};
    this.filterForm = {
      month: '',
      dept_id: undefined,
      group_id: undefined,
    };
    query.month = getCurrentMonth();
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  mounted() {
    this.onRouteChange();
    this.getDeptInfo();
    this.changeColumns();
    const { type } = this.$route.query;
    this.type = type;
    // 总监&助理
    // "director"
    // "assistantDirector"
    // 部长 前五
    // "kaTeamLeader"
    // "kaTeamMinister"
    // 组长 前十
    // "ordinaryTeamLeader"
    // "ordinaryTeamMinister"
    // 组员
    // "ordinaryTeam"
    // "kaTeam"
    switch (this.type) {
      // case 'director':
      //   this.columns = this.columns.slice(4);
      //   break;
      // case 'assistantDirector':
      //   this.columns = this.columns.slice(4);
      //   break;
      case 'kaTeamLeader':
        this.columns = this.columns.slice(5);
        break;
      case 'kaTeamMinister':
        this.columns = this.columns.slice(5);
        break;
      case 'ordinaryTeamLeader':
        this.columns = this.columns.slice(10);
        break;
      case 'ordinaryTeamMinister':
        this.columns = this.columns.slice(10);
        break;
      case 'ordinaryTeam':
        this.columns = this.columns.slice(10);
        break;
      case 'kaTeam':
        this.columns = this.columns.slice(10);
        break;
      default: {
        return null;
      }
    }
  }
  changeColumns() {
    const obj: any = {
      dept_name: 'dept_id',
      dept_leader: 'dept_id',
      monthTarget: 'dept_id',
      monthCompleted: 'dept_id',
      monthCompletedRate: 'dept_id',
      group_leader: 'group_id',
      groupTarget: 'group_id',
      groupCompleted: 'group_id',
      groupCompletedRate: 'group_id',
      group_name: 'group_id',
    };
    this.columns.forEach((res: any) => {
      if (Object.prototype.hasOwnProperty.call(obj, res.dataIndex)) {
        res.customRender = (text: any, row: any, index: any) => {
          return this.customRender(text, row, index, obj[res.dataIndex]);
        };
      }
    });
  }

  async getDeptInfo() {
    const res = await this.workbenchesService.getDeptInfo();
    this.dept_list = res;
    const { dept_id } = this.$route.query;
    if (!dept_id) return;
    const deptId = Number(dept_id);
    this.group_list = this.dept_list.find((item: any) => item.dept.id === deptId).group;
  }

  selectOption(val: any) {
    this.group_list = this.dept_list.find((res: any) => res.dept.id === val).group;
    this.filterForm.group_id = undefined;
  }
}
