
import { Vue, Component, Prop } from 'vue-property-decorator';
import { BASE_URL } from '@/common/constant';
import lazyInject from '@/di';
import UserService from '@/services/user.service';
import WorkbenchesService from '@/services/workbenches.service';
import { $delay } from '@/common/utils';

@Component({})
export default class ImportModal extends Vue {
  BASE_URL = BASE_URL;

  @lazyInject(UserService)
  protected userService!: UserService;

  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;

  @Prop({
    type: String,
    default: 'doc/xls/xlsx/ppt/docx/pptx/txt/pdf',
  })
  type?: string;

  @Prop({
    type: Object,
    default: {},
  })
  uploadUrl: any;

  @Prop({
    type: Number,
    default: 5,
  })
  size?: number;


  uploadObj = {

  };
  token: any = '';

  fileClassTable: any = {
    promotionCompletion: {
      name: '推广通月度目标',
      type: 'excel',
      url: 'https://www.baidu.com',
    },
  };
  fileClass: any = {
    name: '',
    type: '',
    url: '',
  };

  visible = false;
  title = '';
  width = 800;
  height = 500;
  form = {};
  rules = {};
  uploading = false;
  init(type: string) {
    this.fileClass = this.fileClassTable[type];
    this.visible = true;
  }
  async handleDownload() {
    try {
      if (this.uploadUrl.type === 'PromotionCompletion') {
        await this.workbenchesService.getAdUploadTemplate();
      } else {
       await this.workbenchesService.performanceDownload({ category: this.uploadUrl.category });
      }
    } catch (error) {
      this.$message.error(`${error}`);
    }
  }
  created() {
    // this.options.headers.AUTHORIZATION = this.userService.currentUser()?.token;
    this.token = this.userService.currentUser()?.token;
  }
  handleCancel() {
    this.visible = false;
  }

  isLt5M(file: File) {
    return file.size / 1024 / 1024 < 5;
  }

  async handleUploadChange(info: any) {
    if (info.file.status === 'uploading') {
      this.uploading = true;
    }
    if (info.file.status === 'error') {
      this.uploading = false;
      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        await $delay(1000);
        if (code === 401) {
          this.$message.error('登录过期，请重新登录');
          await $delay(1000);
          if (code === 401) {
            this.$router.push({
              path: '/login',
              query: {
                redirect: this.$route.fullPath,
              },
            });
          }
        }
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }

    if (info.file.status === 'done') {
      const { data } = JSON.parse(info.file.xhr.response);
      if (data) {
        this.$message.success(data);
        this.visible = false;
        this.$emit('refresh');
      }
    }
  }

  beforeUpload(file: { type: string; size: number }) {
    if (this.uploadUrl.type !== 'PromotionCompletion') {
      if (!this.uploadUrl.month) {
        this.$message.error('请选择月度！');
        return false;
      }
    }
    const isExcel =
      file.type === 'application/vnd.ms-excel' ||
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'text/csv';
    if (!isExcel) {
      this.$message.error('请上传 excel表格！');
    }
    const isLt5M = this.isLt5M(file as File);
    // if (!isLt5M) {
    //   this.$message.error('文件必须小于5MB!');
    // }
    return isExcel && isLt5M;
  }
  close() {
    this.visible = false;
    this.$emit('refresh');
  }
}
