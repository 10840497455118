
import { Vue, Component, Ref } from 'vue-property-decorator';
import lazyInject from '@/di';
import { Moment } from 'moment';
import OrderService, { PaymentChannelEnum } from '@/services/order.service';
import { FormModel } from 'ant-design-vue';
import { Order } from '@/common/interface/order';
import { DebounceTime } from '@/common/decorator';

@Component({})
export default class ModPaymentReceipt extends Vue {
  labelCol = { span: 6 };

  wrapperCol = { span: 16 };

  isVisible = false;

  isLoading = false;

  buttonText = '提交';

  form: {
    paymentChannelId: string | undefined;
    transactionNo: string;
    paymentTime: Moment | null;
  } = {
      paymentChannelId: undefined,
      transactionNo: '',
      paymentTime: null,
    };

  paymentChannel: Array<{
    id: PaymentChannelEnum;
    name: string;
  }> = [];

  transactionNoCheckStatus: 'toCheck' | 'checking' | 'checked' = 'toCheck';

  extra = '';

  order: Order | null = null;

  isHidden = false;

  @Ref() readonly ruleForm!: FormModel;

  @lazyInject(OrderService)
  orderService!: OrderService;

  @DebounceTime(320)
  async onChange() {
    if (this.extra && this.extra !== '') {
      this.extra = '';
      this.transactionNoCheckStatus = 'toCheck';
      this.buttonText = '提交';
    }
  }

  onChangePaymentTime(value: Moment) {
    this.$set(this.form, 'paymentTime', value);
  }

  oChangeChannel(val: PaymentChannelEnum | string) {
    const channelId = this.form.paymentChannelId;

    if (channelId === (val as string)) {
      return;
    }

    // if (Number(val) === PaymentChannelEnum.无需付款) {
    //   this.$set(this.form, 'transactionNo', '');
    //   this.$set(this.form, 'paymentTime', null);
    //   this.isHidden = true;
    // } else {
    //   this.isHidden = false;
    // }
    this.$set(this.form, 'paymentChannelId', `${val}`);
  }

  async init() {
    // 获取支付渠道
    const res = await this.orderService.paymentChannels();
    if (res) {
      this.paymentChannel = res;
    }
  }

  open(order: Order) {
    this.order = Object.assign({}, order);
    this.isVisible = true;
  }

  close() {
    if (this.isLoading) {
      setTimeout(() => {
        this.reset();
      }, 300);
      return;
    }
    this.reset();
  }

  paymentValidatort() {
    if (!this.form.paymentChannelId) {
      this.$message.warn('请选择支付渠道！');
      return false;
    }
    return true;
  }


  submit() {
    this.funsubmit();
  }

  @DebounceTime(618)
  async funsubmit() {
    const data = {
      paychannel: this.form.paymentChannelId,
    };
    const res = await this.orderService.orderChangePayType(this.order?.orderId, data);
    if (res) {
        this.$message.success('成功');
        this.$emit('refresh');
        setTimeout(() => {
          this.reset();
        }, 500);
      }
  }

  reset() {
    this.isVisible = false;
    this.isLoading = false;
    this.isHidden = false;
    this.extra = '';
    this.transactionNoCheckStatus = 'toCheck';

    this.ruleForm.resetFields();

    this.form = {
      paymentChannelId: undefined,
      transactionNo: '',
      paymentTime: null,
    };
    this.order = null;
  }

  created() {
    this.init();
  }
}
