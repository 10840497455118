
import { EOperationType } from '@/common/enum/shop';
import { IShopOperationLogItem } from '@/common/interface/shop';
import lazyInject from '@/di';
import { ShopOperationLogService } from '@/services/shop_operation_log.service';
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import OperationLogDetailDrawer from './components/OperationDetailDrawer.vue';

const OperationTypeMap = new Map([
  [EOperationType.DEFAULT, ''],
  [EOperationType.POI, 'POI迁移'],
  [EOperationType.BOSS_ID_IMAGES, '门店老板身份证'],
  [EOperationType.MAIN_BUSINESS, '主营业务'],
  [EOperationType.SHOP_LICENSE, '营业执照'],
  [EOperationType.SHOP_IMAGES, '门店相册'],
  [EOperationType.SHOP_NAME, '店铺名称'],
  [EOperationType.ADDRESS, '店铺地址'],
  [EOperationType.STAFF, '子账户管理'],
  [EOperationType.SHOP_AUDIT_VIDEO, '（门店审核）实拍视频'],
  [EOperationType.ONSITESERVICEFLAG, '上门服务'],
  [EOperationType.OPERATION_RECORD, '门店抽拥'],
]);

@Component({
  components: {
    OperationLogDetailDrawer,
  },
})
export default class ShopOperationLog extends Vue {
  shopId = 0;

  shopName = '';

  type = EOperationType.DEFAULT;

  EOperationType = EOperationType;

  OperationTypeMap = OperationTypeMap;

  get title() {
    return `${this.shopName}：${this.OperationTypeMap.get(this.type)}操作记录`;
  }

  columns = [
    {
      title: '时间',
      dataIndex: 'operateTime',
      key: 'operateTime',
      ellipsis: true,
    },
    {
      title: '操作人',
      dataIndex: 'operatorName',
      key: 'operatorName',
      ellipsis: true,
    },
    {
      title: '操作人工号',
      dataIndex: 'operatorId',
      key: 'operatorId',
      ellipsis: true,
    },
    {
      title: '操作内容',
      dataIndex: 'content',
      key: 'content',
      scopedSlots: { customRender: 'content' },
    },
  ];

  listData: IShopOperationLogItem[] = [];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };

  @lazyInject(ShopOperationLogService)
  operationLogService!: ShopOperationLogService;

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { pageSize, current } = this.pagination;
    const param = {
      pageSize,
      page: current,
    };
    await this.list(param);
  }

  @Ref()
  operationDrawer!: OperationLogDetailDrawer;

  openDrawer(obj: { previous: string[]; current: string[] }) {
    const prev = [...obj.previous] || [];
    const curr = [...obj.current] || [];
    const config = {
      title: `查看${this.OperationTypeMap.get(this.type)}操作记录`,
      previous: prev,
      current: curr,
      type: this.type,
    };
    console.log(config);
    this.operationDrawer.setDrawerConfig(config);
    this.operationDrawer.isVisible = true;
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = { page: `${current}` };

    this.$router.push({
      query,
    });
  }

  async list(param: { pageSize: number; page: number }) {
    const { pageSize, page } = param;
    const reqParam = {
      page_size: pageSize,
      page,
      operate_type: this.type,
      shop_id: this.shopId,
    };
    const res = await this.operationLogService.list(reqParam);
    if (res) {
      console.log(res);
      this.listData = res.list;
      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: Number(res.pageSize),
        onChange: this.paginationChange,
      };
    }
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };

    this.$router.push({
      query: {},
    });
  }

  created() {
    const { shopId, shopName, type } = this.$route.params;
    this.shopId = +shopId;
    this.shopName = shopName;
    this.type = type as EOperationType;
  }

  mounted() {
    const { page } = this.$route.query;
    if (typeof page !== 'undefined') {
      this.pagination.current = +page;
    }
    this.handleSearch();
  }
}
