
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class ShouldEnrich extends Vue {
  dataSource = [];
  columns = [
    {
      title: '部门',
      dataIndex: 'storeName',
      align: 'center',
    },
    {
      title: '组',
      dataIndex: 'poi',
      align: 'center',
    },
    {
      title: '代运营',
      dataIndex: 'advertiserId',
      align: 'center',
    },
    {
      title: '应充',
      dataIndex: 'last7DaysSpend',
      align: 'center',
    },
    {
      title: '实充',
      dataIndex: 'balance',
      align: 'center',
    },
    {
      title: '得分',
      dataIndex: 'action',
      align: 'center',
    },
  ];
  filterForm = {
    month: '',
    department: '',
    group: '',
    name: '',
  };
  search() {
  }
  resetQuery() {
  }
  refresh() {
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }
}
