
import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import SystemService from '@/services/system.service';
import lazyInject from '@/di';
import AddModal from './common/AddModal.vue';

@Component({
  components: {
    'add-modal': AddModal,
  },
})
export default class Config extends Vue {
  @lazyInject(SystemService)
  protected SystemService!: SystemService;

  @Ref('addModal')
  addModal?: AddModal;
  filterForm = {
    key: '',
  };

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    onChange: this.paginationChange,
    showTotal: (total: any) => `共 ${total} 条`,
  };

  columns = [
    {
      title: '参数名称',
      dataIndex: 'key',
      align: 'center',
      width: '200px',
    },
    {
      title: '参数值',
      dataIndex: 'value',
      align: 'center',
      width: '200px',
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      width: '200px',
      scopedSlots: { customRender: 'status' },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      align: 'center',
      width: '200px',
    },
    {
      title: '创建时间',
      dataIndex: 'create_at',
      align: 'center',
      width: '200px',
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      // fixed: 'right',
      width: '200px',
      scopedSlots: { customRender: 'action' },
    },
  ];
  dataSource: any[] = [];

  loading = false;

  @Watch('$route')
  queryChange() {
    const { key, page, pageSize } = this.$route.query;
    this.filterForm.key = key as string;
    this.pagination.current = page as any;
    this.pagination.pageSize = pageSize as any;
    const params: any = {
      key,
      page,
      pageSize,
    };
    this.ConfigList(params);
  }
  /**
   * 编辑
   * @param record 记录
   */
  handleEdit(type: string, record: any) {
    this.addModal?.open(type, record);
  }
  /**
   * 刷新缓存
   */
  async handleRefresh() {
    console.log('刷新缓存');
    const res = await this.SystemService.refreshCache();
    if (res) {
      this.$message.success('刷新缓存成功');
    }
  }
  /**
   * 新增
   */
  handleAdd(type: string) {
    console.log('新增');
    this.addModal?.open(type, null);
  }
  /**
   * 分页改变
   * @param page 当前页
   * @param pageSize 每页条数
   */
  paginationChange(page: number, pageSize: number) {
    console.log(page, pageSize);
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    const { key } = this.$route.query;
    const query: any = {
      key,
      page,
      pageSize,
    };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  /**
   * 搜索
   */
  search() {
    this.pagination.current = 1;
    const { key } = this.filterForm;
    const query: any = {
      key,
      page: 1,
      pageSize: this.pagination.pageSize,
    };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({ query });
  }
  /**
   * 重置
   */
  resetQuery() {
    console.log('重置');
    this.filterForm.key = '';
    this.pagination.current = 1;
    const query: any = {
      key: '',
      page: 1,
      pageSize: this.pagination.pageSize,
    };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({ query });
  }
  /**
   * 获取配置列表
   * @param params 参数
   */
  async ConfigList(params: any) {
    const res = (await this.SystemService.getConfigList(params)) as any;
    console.log(res);
    if (res) {
      this.dataSource = res.list;
      this.pagination.total = res.total;
    }
  }
  mounted() {
    this.queryChange();
  }
}
