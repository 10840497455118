/*
 * @Author: Src
 * @Date: 2025-03-25 10:12:29
 * @LastEditTime: 2025-04-01 15:04:58
 * @LastEditors: Src
 * @FilePath: /work-station/src/models/wark.ts
 * @Description: 头部注释配置模板
 */

import _ from 'lodash';

export class warkLabel {
  static init(type: any) {
    let data: any = [];
    if (type == 'OperationalLabel') {
      data = _.cloneDeep(OperationalLabel);
    }
    if (type == 'OperationalLabelKa') {
      data = _.cloneDeep(OperationalLabelKa);
    }
    if (type == 'MinisterialLabel') {
      data = _.cloneDeep(MinisterialLabel);
    }
    if (type == 'MinisterialLabelKa') {
      data = _.cloneDeep(MinisterialLabelKa);
    }
    if (type == 'PerformanceLeader') {
      data = _.cloneDeep(PerformanceLeader);
    }
    if (type == 'PerformanceLeaderKa') {
      data = _.cloneDeep(PerformanceLeaderKa);
    }
    if (type == 'PromotionColumns') {
      data = _.cloneDeep(PromotionColumns);
    }
    if (type == 'PromotionCompletion') {
      data = _.cloneDeep(PromotionCompletion);
    }
    return data;
  }
}
// 运营绩效标签数据模型
const OperationalLabel: any[] = [
  {
    title: '部长',
    dataIndex: 't_leader',
    align: 'center',
    width: 100,
  },
  {
    title: '组长',
    dataIndex: 'leader',
    align: 'center',
    width: 100,
  },
  {
    title: '档位级别',
    dataIndex: 'agent_level',
    align: 'center',
    width: 100,
  },
  {
    title: '组员',
    dataIndex: 'username',
    align: 'center',
    width: 160,
  },
  {
    title: '总分',
    dataIndex: 'total_score',
    align: 'center',
    width: 100,
  },
  {
    title: '客观得分',
    dataIndex: 'one',
    align: 'center',
    children: [
      {
        title: 'SKU要求',
        dataIndex: 'sku_score',
        align: 'center',
        width: 100,
      },
      {
        title: '门店巡查',
        dataIndex: 'inspection_and_exp',
        align: 'center',
        width: 100,
      },
      {
        title: '参加活动',
        dataIndex: 'activity_investment',
        align: 'center',
        width: 100,
      },
      {
        title: '应充实充量增长',
        dataIndex: 'recharge_rate',
        align: 'center',
        width: 160,
      },
      {
        title: '撞线完成率',
        dataIndex: 'complete_overflow',
        align: 'center',
        width: 120,
      },
      {
        title: '在投门店数量增长',
        dataIndex: 'investing_rate',
        align: 'center',
        width: 150,
      },
      {
        title: '合并支付完成投放',
        dataIndex: 'deployed_qualified',
        align: 'center',
        width: 160,
      },
      {
        title: '响应时长',
        dataIndex: 'response_time',
        align: 'center',
        width: 100,
      },
      {
        title: '门店客诉',
        dataIndex: 'customer_complaint',
        align: 'center',
        width: 100,
      },
      {
        title: '运营月考',
        dataIndex: 'monthly_test',
        align: 'center',
        width: 100,
      },
    ],
  },
  {
    title: '个人贡献加分',
    dataIndex: 'department',
    align: 'center',
    children: [
      {
        title: '商户质量抵扣分',
        dataIndex: 'deduct_points',
        align: 'center',
        width: 140,
      },
      {
        title: '加分',
        dataIndex: 'bonus_points',
        align: 'center',
        width: 100,
      },
      {
        title: '备注',
        dataIndex: 'department',
        align: 'center',
        width: 100,
      },
    ],
  },
];
// 运营绩效Ka标签数据模型
const OperationalLabelKa: any[] = [
  {
    title: '部长',
    dataIndex: 't_leader',
    align: 'center',
    width: 100,
  },
  {
    title: '组长',
    dataIndex: 'leader',
    align: 'center',
    width: 100,
  },
  {
    title: '档位级别',
    dataIndex: 'agent_level',
    align: 'center',
    width: 100,
  },
  {
    title: '组员',
    dataIndex: 'username',
    align: 'center',
    width: 100,
  },
  {
    title: '总分',
    dataIndex: 'total_score',
    align: 'center',
    width: 100,
  },
  {
    title: '客观得分',
    dataIndex: 'one',
    align: 'center',
    children: [
      {
        title: 'SKU上品(5分)',
        dataIndex: 'sku_score',
        align: 'center',
        width: 140,
      },
      {
        title: '品牌权益配置(5分)',
        dataIndex: 'brand_equity',
        align: 'center',
        width: 160,
      },
      {
        title: '门店巡店/异常处理(5分)',
        dataIndex: 'inspection_and_exp',
        align: 'center',
        width: 200,
      },
      {
        title: '活动招商(5分)',
        dataIndex: 'activity_investment',
        align: 'center',
        width: 140,
      },
      {
        title: '门店客诉(10分)',
        dataIndex: 'customer_complaint',
        align: 'center',
        width: 140,
      },
      {
        title: '响应时长(10分)',
        dataIndex: 'response_time',
        align: 'center',
        width: 140,
      },
      {
        title: '应充实充率增长(10分)',
        dataIndex: 'recharge_rate',
        align: 'center',
        width: 180,
      },
      {
        title: '品牌续费率(50分)',
        dataIndex: 'brand_renew_rate',
        align: 'center',
        width: 160,
      },
    ],
  },
  {
    title: '个人贡献加分',
    dataIndex: 'department',
    align: 'center',
    children: [
      {
        title: '商户质量抵扣分',
        dataIndex: 'deduct_points',
        align: 'center',
        width: 140,
      },
      {
        title: '加分',
        dataIndex: 'bonus_points',
        align: 'center',
        width: 100,
      },
      {
        title: '备注',
        dataIndex: 'department3',
        align: 'center',
        width: 100,
      },
    ],
  },
];
// 运营绩效部长标签数据模型
const MinisterialLabel: any[] = [
  {
    title: '部长',
    dataIndex: 't_leader',
    align: 'center',
    width: 120,
  },
  {
    title: '总分',
    dataIndex: 'total_score',
    align: 'center',
    width: 120,
  },
  {
    title: '商户运营',
    dataIndex: 'one',
    align: 'center',
    width: 120,
    children: [
      {
        title: 'SKU要求',
        dataIndex: 'sku_score',
        align: 'center',
        width: 120,
      },
      {
        title: '门店巡查',
        dataIndex: 'inspection_and_exp',
        align: 'center',
        width: 120,
      },
      {
        title: '参加活动',
        dataIndex: 'activity_investment',
        align: 'center',
        width: 120,
      },
    ],
  },
  {
    title: '目标管理',
    dataIndex: 'two',
    align: 'center',
    children: [
      {
        title: '应充实充率增长',
        dataIndex: 'recharge_rate',
        align: 'center',
        width: 140,
      },
      {
        title: '撞线完成率',
        dataIndex: 'complete_overflow',
        align: 'center',
        width: 120,
      },
      {
        title: '在投门店数量增长',
        dataIndex: 'investing_rate',
        align: 'center',
        width: 160,
      },
      {
        title: '合并支付完成投放',
        dataIndex: 'deployed_qualified',
        align: 'center',
        width: 160,
      },
      {
        title: '响应时长',
        dataIndex: 'response_time',
        align: 'center',
        width: 120,
      },
      {
        title: '门店客诉',
        dataIndex: 'customer_complaint',
        align: 'center',
        width: 120,
      },
      {
        title: '运营月考',
        dataIndex: 'monthly_test',
        align: 'center',
        width: 120,
      },
    ],
  },
];
// 运营绩效KA部长标签数据模型
const MinisterialLabelKa: any[] = [
  {
    title: '部长',
    dataIndex: 't_leader',
    align: 'center',
    width: 120,
  },
  {
    title: '总分',
    dataIndex: 'total_score',
    align: 'center',
    width: 120,
  },
  {
    title: '客观得分',
    dataIndex: 'one',
    align: 'center',
    children: [
      {
        title: 'SKU上品(5分)',
        dataIndex: 'sku_score',
        align: 'center',
        width: 140,
      },
      {
        title: '品牌权益配置(5分)',
        dataIndex: 'brand_equity',
        align: 'center',
        width: 160,
      },
      {
        title: '门店巡店/异常处理(5分)',
        dataIndex: 'inspection_and_exp',
        align: 'center',
        width: 200,
      },
      {
        title: '活动招商(5分)',
        dataIndex: 'activity_investment',
        align: 'center',
        width: 140,
      },
      {
        title: '门店客诉(10分)',
        dataIndex: 'customer_complaint',
        align: 'center',
        width: 140,
      },
      {
        title: '响应时长(10分)',
        dataIndex: 'response_time',
        align: 'center',
        width: 140,
      },
      {
        title: '应充实充率增长(10分)',
        dataIndex: 'recharge_rate',
        align: 'center',
        width: 180,
      },
      {
        title: '品牌续费率(50分)',
        dataIndex: 'brand_renew_rate',
        align: 'center',
        width: 160,
      },
    ],
  },
];
// 运营绩效组长标签数据模型
const PerformanceLeader: any[] = [
  {
    title: '部长',
    dataIndex: 't_leader',
    align: 'center',
    width: 120,
  },
  {
    title: '组长',
    dataIndex: 'leader',
    align: 'center',
    width: 120,
  },
  {
    title: '总分',
    dataIndex: 'total_score',
    align: 'center',
    width: 120,
  },
  {
    title: '小组推广通金额',
    dataIndex: 'real_price_all',
    align: 'center',
    width: 140,
  },
  {
    title: '商户运营',
    align: 'center',
    width: 120,
    children: [
      {
        title: 'SKU要求',
        dataIndex: 'sku_score',
        align: 'center',
        width: 120,
      },
      {
        title: '门店巡查',
        dataIndex: 'inspection_and_exp',
        align: 'center',
        width: 120,
      },
      {
        title: '参加活动',
        dataIndex: 'activity_investment',
        align: 'center',
        width: 120,
      },
    ],
  },
  {
    title: '目标管理',
    align: 'center',
    width: 120,
    children: [
      {
        title: '应充实充率增长',
        dataIndex: 'recharge_rate',
        align: 'center',
        width: 140,
      },
      {
        title: '撞线完成率',
        dataIndex: 'complete_overflow',
        align: 'center',
        width: 120,
      },
      {
        title: '在投门店数量增长',
        dataIndex: 'investing_rate',
        align: 'center',
        width: 160,
      },
      {
        title: '合并支付完成投放',
        dataIndex: 'deployed_qualified',
        align: 'center',
        width: 160,
      },
      {
        title: '响应时长',
        dataIndex: 'response_time',
        align: 'center',
        width: 120,
      },
      {
        title: '门店客诉',
        dataIndex: 'customer_complaint',
        align: 'center',
        width: 120,
      },
      {
        title: '运营月考',
        dataIndex: 'monthly_test',
        align: 'center',
        width: 120,
      },
    ],
  },
];
// 运营绩效KA组长标签数据模型
const PerformanceLeaderKa: any[] = [
  {
    title: '部长',
    dataIndex: 't_leader',
    align: 'center',
    width: 120,
  },
  {
    title: '组长',
    dataIndex: 'leader',
    align: 'center',
    width: 120,
  },
  {
    title: '总分',
    dataIndex: 'total_score',
    align: 'center',
    width: 120,
  },
  {
    title: '小组推广通金额',
    dataIndex: 'real_price_all',
    align: 'center',
    width: 140,
  },
  {
    title: '客观得分',
    dataIndex: 'one',
    align: 'center',
    children: [
      {
        title: 'SKU上品(5分)',
        dataIndex: 'sku_score',
        align: 'center',
        width: 140,
      },
      {
        title: '品牌权益配置(5分)',
        dataIndex: 'brand_equity',
        align: 'center',
        width: 160,
      },
      {
        title: '门店巡店/异常处理(5分)',
        dataIndex: 'inspection_and_exp',
        align: 'center',
        width: 200,
      },
      {
        title: '活动招商(5分)',
        dataIndex: 'activity_investment',
        align: 'center',
        width: 140,
      },
      {
        title: '门店客诉(10分)',
        dataIndex: 'customer_complaint',
        align: 'center',
        width: 140,
      },
      {
        title: '响应时长(10分)',
        dataIndex: 'response_time',
        align: 'center',
        width: 140,
      },
      {
        title: '应充实充率增长(10分)',
        dataIndex: 'recharge_rate',
        align: 'center',
        width: 180,
      },
      {
        title: '品牌续费率(50分)',
        dataIndex: 'brand_renew_rate',
        align: 'center',
        width: 160,
      },
    ],
  },
];

const PromotionColumns: any[] = [
  {
    title: '部长',
    dataIndex: 'leader',
    align: 'center',
    scopedSlots: { customRender: 'leader' },
    width: 120,
  },
  {
    title: '部推广通目标',
    dataIndex: 'monthTarget',
    align: 'center',
    width: 120,
  },
  {
    title: '部已完成金额',
    dataIndex: 'monthCompleted',
    align: 'center',
    width: 120,
  },
  {
    title: '部完成进度',
    dataIndex: 'monthCompletedRate',
    align: 'center',
    width: 120,
  },
  {
    title: '组推广通目标',
    dataIndex: 'groupTarget',
    align: 'center',
    width: 120,
  },
  {
    title: '组已完成金额',
    dataIndex: 'groupCompleted',
    align: 'center',
    width: 120,
  },
  {
    title: '组完成进度',
    dataIndex: 'groupCompletedRate',
    align: 'center',
    width: 120,
  },
  {
    title: '组别',
    dataIndex: 'group',
    align: 'center',
    width: 120,
  },
  {
    title: '组长',
    dataIndex: 'groupLeader',
    align: 'center',
    width: 120,
  },
  {
    title: '组员',
    dataIndex: 'groupMember',
    align: 'center',
    width: 120,
  },
  {
    title: '个人推广通目标',
    dataIndex: 'personalTarget',
    align: 'center',
    width: 150,
  },
  {
    title: '个人已完成金额',
    dataIndex: 'personalCompleted',
    align: 'center',
    width: 150,
  },
  {
    title: '个人今日业绩',
    dataIndex: 'personalTodayPerformance',
    align: 'center',
    width: 120,
  },
  {
    title: '个人推广通业绩完成率',
    dataIndex: 'personalCompletedRate',
    align: 'center',
    width: 200,
  },
];

const PromotionCompletion: any = [
  {
    title: '部长',
    dataIndex: 'dept_leader',
    align: 'center',
    scopedSlots: { customRender: 'dept_leader' },
    width: 120,
  },
  {
    title: '部门',
    dataIndex: 'dept_name',
    align: 'center',
    scopedSlots: { customRender: 'dept_name' },
    width: 120,
  },
  {
    title: '部推广通目标',
    dataIndex: 'monthTarget',
    align: 'center',
    width: 120,
  },
  {
    title: '部已完成金额',
    dataIndex: 'monthCompleted',
    align: 'center',
    width: 120,
  },
  {
    title: '部完成进度',
    dataIndex: 'monthCompletedRate',
    align: 'center',
    width: 120,
  },
  {
    title: '组别',
    dataIndex: 'group_name',
    align: 'center',
    width: 120,
  },
  {
    title: '组长',
    dataIndex: 'group_leader',
    align: 'center',
    width: 120,
  },
  {
    title: '组推广通目标',
    dataIndex: 'groupTarget',
    align: 'center',
    width: 120,
  },
  {
    title: '组已完成金额',
    dataIndex: 'groupCompleted',
    align: 'center',
    width: 120,
  },
  {
    title: '组完成进度',
    dataIndex: 'groupCompletedRate',
    align: 'center',
    width: 120,
  },
  {
    title: '组员',
    dataIndex: 'username',
    align: 'center',
    width: 120,
  },
  {
    title: '个人推广通目标',
    dataIndex: 'target_quota',
    align: 'center',
    width: 150,
  },
  {
    title: '个人已完成金额',
    dataIndex: 'month_real_price',
    align: 'center',
    width: 150,
  },
  // {
  //   title: '个人今日业绩',
  //   dataIndex: 'personalTodayPerformance',
  //   align: 'center',
  //   width: 120,
  // },
  {
    title: '个人推广通业绩完成率',
    dataIndex: 'ratio',
    align: 'center',
    width: 200,
    customRender: (text: any) => {
      if (text) {
        return `${(text * 100).toFixed(2)}%`;
      }
      return text;
    },
  },
];
