
import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';
import { warkLabel } from '@/models/wark';
import { AccountService } from '@/services/account_service';
import { getLastMonth } from '@/common/utils';

@Component({})
export default class OperationalPerformanceLeader extends Vue {
  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;


  @lazyInject(AccountService)
  protected accountService!: AccountService;
  permissionListOfRole: any = [];
  fetching = false;

  moment = moment;

  dataSource: any = [];
  dataSourceKa = [];
  columnsChild = warkLabel.init('PerformanceLeader');
  columnsKA = warkLabel.init('PerformanceLeaderKa');
  columnsRende = ['t_leader', 'leader'];

  filterForm: any = {
    month: '',
    dept_id: undefined,
    group_id: undefined,
    agent_name: '',
  };
  dept_list: any = [];

  categoryType = '1';

  type = 'normal';

  group_list: any = [];
  @Watch('$route')
  onRouteChange() {
    const { page, pageSize, month, dept_id, group_id, user_name, user_id } = this.$route.query;
    const params: any = {
      page: page || 1,
      pageSize: pageSize || 10,
      category: this.categoryType,
    };
    if (month) {
      this.filterForm.month = month;
      params.plan_month = month;
    }
    if (dept_id) {
      this.filterForm.dept_id = +dept_id;
      params.dept_id = +dept_id;
    }
    if (group_id) {
      this.filterForm.group_id = +group_id;
      params.group_id = +group_id;
    }
    if (user_name || user_id) {
      this.filterForm.agent_name = user_name;
      params.user_id = user_id;
      this.permissionListOfRole = {
        key: user_id,
        label: user_name,
      };
      this.searchPermission(user_name as string);
    }
    this.getList(params);
  }
  async getList(params: any) {
    const res = await this.workbenchesService.getGroupLeaderPerformanceList(params);
    this.dataSource = res;
  }

  async searchPermission(value: string) {
    try {
      const res = await this.accountService.getLeaderList({
        page: 1,
        pageSize: 1000,
        userName: value,
      });
      this.permissionOptions = res.list;
    } catch (error) {
      // console.log(error);
    }
  }

  permissionOptions: any[] = [];

  async handleChange(value: any) {
    this.permissionListOfRole = value;
  }
  changeType(type: string) {
    this.type = type;
    if (type === 'normal') {
      this.categoryType = '1';
    } else if (type === 'brand') {
      this.categoryType = '2';
    }
    this.dataSource = [];
    this.onRouteChange();
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }
  changeMonth(val: any) {
    const year = val._d.getFullYear(); // 获取当前年份，例如：2021
    let month: any = Number(val._d.getMonth()) + 1; // 获取当前月份，注意需要加1，例如：9
    if (month < 10) {
      month = `0${month}`;
    }
    const _d = `${year}-${month}`;
    this.filterForm.month = _d;
  }
  selectOption(val: any) {
    this.group_list = this.dept_list.find((res: any) => res.dept.id === val).group;
    this.filterForm.group_id = undefined;
  }
  refresh() {
    // console.log('refresh');
  }
  search() {
    const { month, dept_id, group_id, agent_name } = this.filterForm;
    const query: any = {};
    const { key, label } = this.permissionListOfRole;
    if (month) {
      query.month = month;
    }
    if (dept_id) {
      query.dept_id = +dept_id;
    }
    if (group_id) {
      query.group_id = +group_id;
    }
    if (agent_name) {
      query.agent_name = agent_name;
    }
    if (key || label) {
      query.user_id = key;
      query.user_name = label;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  mounted() {
    this.onRouteChange();
    this.getDeptInfo();
    this.changColums(this.columnsChild, this.columnsRende);
    this.changColums(this.columnsKA, this.columnsRende);
  }

  changColums(columns: any, rende: any) {
    const that = this;
    columns.forEach((element: any) => {
      if (rende.includes(element.dataIndex)) {
        element.customRender = (text: any, row: any, index: any) => {
          const obj = that.customRender(text, row, index, `${element.dataIndex}_id`);
          return obj;
        };
      }
    });
  }

  customRender(text: any, row: any, index: number, column: any) {
    const prevRow: any = this.dataSource[index - 1];
    if (prevRow && prevRow[column] === row[column]) {
      return {
        children: text,
        attrs: {
          rowSpan: 0,
        },
      };
    }
    let rowSpan = 1;
    for (let i = index + 1; i < this.dataSource.length; i++) {
      const currentRow: any = this.dataSource[i];
      if (currentRow[column] === row[column]) {
        rowSpan++;
      } else {
        break;
      }
    }
    return {
      children: text,
      attrs: {
        rowSpan,
      },
    };
  }

  async getDeptInfo() {
    const res = await this.workbenchesService.getDeptInfo();
    this.dept_list = res;
    const { dept_id } = this.$route.query;
    if (!dept_id) return;
    const deptId = Number(dept_id);
    this.group_list = this.dept_list.find((item: any) => item.dept.id === deptId).group;
  }
  resetQuery() {
    this.filterForm = {
      month: getLastMonth(),
      dept_id: undefined,
      group_id: undefined,
      agent_name: '',
    };
    const query: any = {
      month: getLastMonth(),
    };
    this.permissionListOfRole = [];
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
}
