
import { Vue, Component, Ref } from 'vue-property-decorator';
import ExamineModal from './common/ExamineModal.vue';
import lazyInject from '@/di';
import WithdrawService from '@/services/withdraw.service';
import { WithdrawExamineStatus } from '@/common/enum/withdraw';
import { IApply } from '@/common/interface/apply';
import { CApply } from '@/models/apply.model';
import {
  AuthEnum,
} from '@/services/permission.service';
import UTILService from '@/services/utils.service';

@Component({
  components: {
    'examine-modal': ExamineModal,
  },
})
export default class ExaminDetail extends Vue {
  @lazyInject(WithdrawService)
  withdrawService!: WithdrawService;

  WithdrawExamineStatus = WithdrawExamineStatus;

  AuthEnum = AuthEnum;

  @lazyInject(UTILService)
  utilService!: UTILService;

  encryptAccount = '';

  encryptAccountPhoone = '';

  selectedRowKeys: any = []; // Check here to configure the default column

  @Ref('examineModal')
  examineModal?: ExamineModal;

  withdrawAccount = '';

  loading = true;

  spin_loading = false;

  withdrawalAccountName = '';

  withdrawAmount = '0.00';

  status = '';

  columns = [
    {
      title: '序号',
      dataIndex: 'id',
      scopedSlots: { customRender: 'index' },
      align: 'center',
      width: 80,
    },
    {
      title: '高德订单号',
      dataIndex: 'orderNo',
      align: 'center',
      width: 240,
    },
    {
      title: '门店名称',
      dataIndex: 'shopName',
      align: 'center',
      width: 200,
    },
    {
      title: 'SKU',
      dataIndex: 'goodsId',
      align: 'center',
      width: 100,
    },
    {
      title: '商品名称',
      dataIndex: 'goodsName',
      align: 'center',
      width: 200,
    },
    {
      title: '品牌',
      dataIndex: 'kaName',
      align: 'center',
      width: 100,
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      align: 'center',
      width: 100,
    },
    {
      title: '总核销次数',
      dataIndex: 'tnum',
      align: 'center',
      width: 110,
    },
    {
      title: '核销次数',
      dataIndex: 'cnum',
      align: 'center',
      width: 100,
    },
    {
      title: '下单时间',
      dataIndex: 'ctime',
      align: 'center',
      width: 120,
    },
    {
      title: '订单金额',
      dataIndex: 'oriPrice',
      align: 'center',
      width: 100,
    },
    {
      title: '优惠金额',
      dataIndex: 'coupon',
      align: 'center',
      width: 100,
    },
    {
      title: '实收金额',
      dataIndex: 'price',
      align: 'center',
      width: 100,
    },
    {
      title: '抽拥比例',
      dataIndex: 'comRatio',
      align: 'center',
      width: 100,
    },
    {
      title: '抽拥金额',
      dataIndex: 'commissionPrice',
      align: 'center',
      width: 100,
    },
    {
      title: '结算金额',
      dataIndex: 'verifyPrice',
      align: 'center',
      width: 100,
    },
    // {
    //   title: '核销状态',
    //   dataIndex: 'verifyStatus',
    //   align: 'center',
    //   width: 100,
    // },
    {
      title: '核销时间',
      dataIndex: 'verifyts',
      align: 'center',
      width: 120,
    },
    // {
    //   title: '结算状态',
    //   dataIndex: 'settle_status',
    //   align: 'center',
    //   width: 100,
    // },
  ];

  logData = [];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
    showTotal: (total: any) => `共 ${total} 条`,
  };

  data = [];

  paginationChange(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    this.getOrderList({
      wid: this.$route.params.id,
      page,
      pageSize,
    });
  }

  back() {
    this.$router.push({
      name: 'withdraw.examine',
    });
  }

  onChangeData() { }
  onSelect() { }
  onSelectAll() { }
  getCheckboxProps() { }

  handleRowChange() { }

  refresh() { }
  async LookShopDetail(title: string, encryptAccount: string) {
    const data = {
      encrypt_phone: encryptAccount,
      operate_type: title,
    };
    try {
      const res = await this.utilService.postPhoneDecrypt(data);
      if (res) {
        this.encryptAccountPhoone = res;
      }
    } catch (error) {
      this.$message.error('解密失败');
    }
  }

  examine() {
    this.examineModal?.open();
  }

  created() {
  }

  getColor(status: string) {
    // const statusEnum = WithdrawExamineStatus[status as keyof typeof WithdrawExamineStatus];
    let color: any = 'blue';
    switch (Number(status)) {
      case WithdrawExamineStatus.提现中: {
        color = 'yellow';
        break;
      }
      case WithdrawExamineStatus.转账失败: {
        color = 'red';
        break;
      }
      case WithdrawExamineStatus.待审核: {
        color = 'blue';
        break;
      }
      case WithdrawExamineStatus.提现成功: {
        color = 'green';
        break;
      }
      case WithdrawExamineStatus.审核未通过: {
        color = 'red';
        break;
      }
      default: {
        break;
      }
    }
    return color;
  }

  getDetail() {
    const { id } = this.$route.params;
    // const no = this.$route.params.no;
    const params = {
      wid: id,
      page: 1,
      pageSize: 10,
    };
    this.getOrderList(params);
    this.getLog(id);
  }

  async getLog(params: any) {
    this.spin_loading = false;
    const res = await this.withdrawService.withdrawalOperateLog(params);
    if (res) {
      this.spin_loading = true;
      this.logData = res;
    }
  }

  async getOrderList(params: any) {
    const res = await this.withdrawService.withdrawalDetail(params);
    if (res) {
      this.loading = false;
      const applyList = res.list.map((item: IApply) => CApply.from(item));
      this.data = applyList;
      this.pagination.total = res.total;
      this.withdrawAccount = res.withdrawal_info.account;
      this.withdrawalAccountName = res.withdrawal_info.name;
      this.withdrawAmount = res.withdrawal_info.money;
      this.status = res.withdrawal_info.status;
      this.encryptAccount = res.withdrawal_info.encrypt_account;
    }
  }

  mounted() {
    this.getDetail();
  }
}
