
import { Vue, Component, Ref } from 'vue-property-decorator';
import LocationView from './components/LocationView.vue';
import ShopInspectionRecord from './components/ShopInspectionRecord.vue';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import {
  ShopDetail as ShopDetailInterface,
  Staff,
  MerchantInfo,
  MerchantSettledEnum,
  IShopFormField,
  IEditShopFieldAddress,
} from '@/common/interface/shop';
import {
  EOperationType,
  EShopParkNum,
  EShopBusinessStatus,
  EShopField,
} from '@/common/enum/shop';
import VueQr from 'vue-qr';
import BusinessStatusControl from './components/BusinessStatusControl.vue';
import ShopTransfer from './components/ShopTransfer.vue';
import { AuthEnum } from '@/services/permission.service';
import SPUTable from './components/SPUTable.vue';
import SuspendAccountControl from './components/SuspendAccountControl.vue';
import { $delay } from '@/common/utils';
import COrderListModal from './components/COrderListModal.vue';
import DrawQRCodePoster from './components/DrawQRCodePoster.vue';
import VideoUModal from '@/components/video/VideoModal.vue';
import ShopEditor from './components/ShopEditor.vue';
import { component as Viewer } from 'v-viewer';
import LookPhone from '@/components/LookPhone.vue';
import UTILService from '@/services/utils.service';
import ExitBrandModal from './components/ExitBrandModal.vue';
import StoreSupport from './components/StoreSupport.vue';

const initMerchantInfo = {
  info: '',
  status: -2,
  merchantShopId: '',
};
const initialDetail = {
  address: ' - ',
  bossIdImage: [],
  bossTels: ' - ',
  businessStatus: ' - ',
  businessTime: '',
  dataChed: null,
  discount: ' - ',
  latitude: ' - ',
  longitude: ' - ',
  mainBusiness: [],
  managerTels: ' - ',
  name: ' - ',
  parkNum: null,
  poi: ' - ',
  qrcode: ' - ',
  restArea: null,
  shopId: 0,
  shopImages: [],
  shopVideos: [],
  videos: [],
  shoplicense: [],
  startTime: ' - ',
  station: null,
  tels: ' - ',
  mechaniscs: [],
  staffList: {},
  advertiserMobilePhoneNumber: '',
  region: '',
  encrypt_ad_user_phone: '',
  encrypt_boss_phone: '',
  encrypt_manager_phone: '',
  encrypt_tels: '',
  isKaShop: '',
  onsiteServiceFlag: '-',
  commission_ratio: '',
  commission_time: '',
};

enum EPayeeAccountFreezeStatus {
  正常 = 0,
  冻结 = 1,
}

const PayeeAccountFreezeStatusMap = new Map([
  [EPayeeAccountFreezeStatus.正常, '正常'],
  [EPayeeAccountFreezeStatus.冻结, '冻结'],
]);

@Component({
  components: {
    LocationView,
    ShopInspectionRecord,
    VueQr,
    BusinessStatusControl,
    ShopTransfer,
    SPUTable,
    SuspendAccountControl,
    COrderListModal,
    DrawQRCodePoster,
    VideoUModal,
    ShopEditor,
    Viewer,
    'look-phone': LookPhone,
    'exit-brand-modal': ExitBrandModal,
    StoreSupport,
  },
})
export default class ShopDetail extends Vue {
  PayeeAccountFreezeStatusMap = PayeeAccountFreezeStatusMap;

  EPayeeAccountFreezeStatus = EPayeeAccountFreezeStatus;

  AuthEnum = AuthEnum;

  shopTotal = '';

  lng = '-1';

  lat = '-1';

  adUserBind = false;

  locationName = '';

  EShopBusinessStatus = EShopBusinessStatus;

  EShopParkNum = EShopParkNum;

  editShopDrawerVisible = false;

  EShopField = EShopField;

  MerchantSettledEnum = MerchantSettledEnum;

  isDrawing = false;

  @lazyInject(ShopService)
  shopService!: ShopService;

  @lazyInject(UTILService)
  private utilService!: UTILService;

  @Ref()
  transfer!: ShopTransfer;

  @Ref()
  locationView!: LocationView;

  @Ref()
  shopInspectionRecord!: ShopInspectionRecord;

  @Ref()
  businessControl!: BusinessStatusControl;

  @Ref('lookPhone')
  lookPhone!: LookPhone;

  @Ref('exitBrandModal')
  exitBrandModal!: ExitBrandModal;

  @Ref()
  suspendAccount!: SuspendAccountControl;

  @Ref()
  cOrderListModal!: COrderListModal;

  @Ref()
  drawQRCodePoster!: DrawQRCodePoster;

  @Ref()
  videoModal!: VideoUModal;

  @Ref()
  shopEditor!: ShopEditor;

  @Ref()
  storeSupport!: StoreSupport;

  shopId?: string;

  detail: ShopDetailInterface = initialDetail;

  merchantInfo: MerchantInfo = initMerchantInfo;

  addressInfo: IEditShopFieldAddress = {
    address: '',
    regions: [] as number[],
    latitude: '',
    longitude: '',
    locationName: '',
  };

  get mechaniscs() {
    return this.detail.mechaniscs.map(({ id, name, introduce, picId }) => ({
      id,
      name,
      introduce,
      url: picId[0]?.url,
    }));
  }

  get staffList(): Staff[] {
    return Object.values(this.detail.staffList)
      .map(({ data }) => data)
      .reduce((prev, cur) => prev.concat(cur), []);
  }

  get fullAddress() {
    if (!this.detail.shopId) {
      return '';
    }
    const regions = this.detail.region.split('/');
    if (regions[0] === regions[1]) {
      regions.shift();
    }
    return regions.join('') + this.detail.address;
  }

  isLoading = false;

  captureDate(val: any) {
    return val.split(' ')[0];
  }
  openStoreSupport() {
    this.storeSupport.open(this.detail);
  }

  async openCOrderListModal() {
    await this.cOrderListModal.open();
  }
  LookShopDetail(type: string, val: any, poi: string) {
    this.lookPhone.init(type, val, poi);
  }
  openExitBrandModal() {
    this.exitBrandModal.init();
  }

  onCopySuccess() {
    this.$message.success('复制成功');
  }

  created() {
    this.shopId = this.$route.params.id;
    this.init();
  }
  listTatle(val: any) {
    this.shopTotal = val;
  }
  touchShop() {
    if (!this.shopId) {
      return;
    }
    this.shopService.touchShop(this.shopId);
  }

  async touchShopMerchant() {
    if (!this.shopId) {
      return;
    }

    const res = await this.shopService.touchShopMerchant(this.shopId);

    if (res) {
      this.$message.success('推送成功，正在刷新!');
      await $delay(2500);
      await this.refresh();
    }
  }

  async intervention(secondConfirm?: boolean) {
    if (!this.shopId) {
      return;
    }
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    if (typeof secondConfirm !== 'undefined' && secondConfirm === true) {
      try {
        const res = await this.shopService.interventionShopMerchant(
          this.shopId,
          1,
        );
        if (res) {
          this.$message.success(res);
          await $delay(2500);
          await this.refresh();
        }
      } catch (error) {
        this.$message.error(`${error}`);
      }
      return;
    }

    const data = await this.shopService.interventionShopMerchant(this.shopId);
    if (data) {
      this.$confirm({
        title: '请仔细确认后再执行后续操作',
        content: `${data}`,
        okText: '继续执行',
        cancelText: '取消',
        onOk: async () => {
          await this.intervention(true);
        },
      });
      this.isLoading = false;
    }
  }

  /**
   * 页面刷新
   */
  async refresh(needShowToast = true) {
    await this.init();
    if (needShowToast === true) {
      this.$message.success('刷新成功!');
    }
  }

  /**
   * 初始化查询
   */
  async init() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = await this.shopService.getShopDetail(
      this.shopId as string,
      this.$el,
    );
    // const id = this.$route.params.id;
    // const res = (await this.shopService.adUserBk(id));
    // this.adUserBind = res?.bind || false;
    await this.$nextTick();
    const { id } = this.$route.params;
    const res = (await this.shopService.adUserBk(id));
    this.detail = data.detail;
    this.merchantInfo = data.merchantInfo;
    this.detail.advertiserMobilePhoneNumber = res?.ad_user_phone || '';
    this.detail.encrypt_ad_user_phone = res?.encrypt_ad_user_phone || '';


    const { regionid, regionpid, adcode } = data.city_code;
    this.addressInfo = {
      locationName: this.detail.name,
      address: this.detail.address,
      regions: [regionpid, regionid, adcode],
      latitude: this.detail.latitude,
      longitude: this.detail.longitude,
    };
  }

  showLocation() {
    const { longitude, latitude, name } = this.detail;
    this.lng = longitude;
    this.lat = latitude;
    this.locationName = name;
    this.locationView.show();
  }

  /** 修改营业状态 */
  async changeBusinessStatus(type: EShopBusinessStatus) {
    if (this.isLoading === true) {
      return;
    }
    this.isLoading = true;
    this.businessControl.changeBusinessStatus(type);

    setTimeout(() => {
      this.isLoading = false;
    }, 300);
  }

  /** 门店转让 */
  shopTransfer() {
    this.transfer.confirm();
  }

  /** 跳转至商品管理 */
  spuManage() {
    this.$router.push({
      name: 'main.shop.goods',
      params: {
        shopId: this.shopId as string,
      },
    });
  }

  /**
   * 返回
   */
  back() {
    this.$router.push({
      name: 'main.shop.list',
    });
  }

  /** 冻结 | 解冻 */
  changePayeeAccountStatus(status: EPayeeAccountFreezeStatus) {
    this.suspendAccount.confirm(status);
  }

  async migrate() {
    if (!this.shopId) {
      return;
    }
    // eslint-disable-next-line no-alert
    const targetPOI = prompt('请输入要迁往的 POI ID');

    if (targetPOI == null) {
      return;
    }

    this.shopService.migrateShop(this.shopId, targetPOI);
  }

  /**
   * 设为头图
   */
  setHeadImg(index: number) {
    const idList = this.detail.shopImages.map((item) => item.id);
    const head = idList.splice(index, 1);
    idList.unshift(head[0]);
    this.shopEdit({
      shopImage: idList,
    });
  }

  /**
   * 删除店铺图片
   */
  delShopImg(index: number) {
    if (this.detail.shopImages.length < 4) {
      this.$message.error('店铺相册图片不能小于3张');
    } else {
      const idList = this.detail.shopImages.map((item) => item.id);
    idList.splice(index, 1);
    this.shopEdit({
      shopImage: idList,
    });
    }
  }

  /**
   * 修改店铺信息
   */
  async shopEdit(payload: { [key: string]: number[] | string[] }) {
    const result = await this.shopService.editShop<{
      [key: string]: number[] | string[];
    }>(this.shopId as string, payload);
    if (result) {
      this.$message.success('修改成功!正在审核中!');
    }
  }

  downloadQrcode() {
    this.isDrawing = true;
    this.drawQRCodePoster.downloadQrcode();
  }

  EOperationType = EOperationType;

  openVideoModal(obj: { url: string; cover: string; id: number }) {
    this.videoModal.open(obj.url);
  }

  editingField: IShopFormField | null = null;

  shopFields: { [key: string]: IShopFormField } = {
    [`${EShopField.NAME}`]: {
      labelName: '店铺名称',
      label: EShopField.NAME,
      value: '',
      type: 'name',
      rule: [
        {
          required: true,
          message: '请填写店铺名称',
          trigger: 'blur',
        },
        {
          pattern: /^[\u4e00-\u9fa5\\(\\)_a-zA-Z0-9]+$/,
          message: '门店名称仅支持：中英文、数字和半角括号()',
          trigger: 'blur',
        },
      ],
      fieldTitle: '编辑店铺名称',
      placeholder: '请输入店铺名称',
      submitAction: this.editShop,
    },
    [`${EShopField.ADDRESS}`]: {
      labelName: '店铺地址',
      label: EShopField.ADDRESS,
      value: null,
      type: EShopField.ADDRESS,
      fieldTitle: '编辑店铺地址',
      submitAction: this.editAddressInfo,
    },
    [`${EShopField.ADVERTISER_MOBILE_PHONE_NUMBER}`]: {
      labelName: '广告主电话',
      label: EShopField.ADVERTISER_MOBILE_PHONE_NUMBER,
      value: '',
      rule: [
        {
          required: true,
          message: '手机号不能为空',
          trigger: 'blur',
        },
        {
          pattern: /1[3-9]\d{9}/,
          message: '请输入正确的手机号',
          trigger: 'blur',
        },
      ],
      type: EShopField.ADVERTISER_MOBILE_PHONE_NUMBER,
      fieldTitle: '编辑广告主电话',
      placeholder: '请输入广告主电话',
      submitAction: this.editAdvertiserPhoneNum,
    },
    [`${EShopField.MANAGER_TELS}`]: {
      labelName: '经理电话',
      label: EShopField.MANAGER_TELS,
      value: '',
      rule: [
        {
          required: true,
          message: '手机号不能为空',
          trigger: 'blur',
        },
        {
          pattern: /1[3-9]\d{9}/,
          message: '请输入正确的手机号',
          trigger: 'blur',
        },
      ],
      type: EShopField.MANAGER_TELS,
      fieldTitle: '编辑经理电话',
      placeholder: '请输入经理电话',
      submitAction: this.editShop,
    },
    [`${EShopField.START_TIME}`]: {
      labelName: '开业时间',
      label: EShopField.START_TIME,
      value: '',
      type: EShopField.START_TIME,
      fieldTitle: '编辑开业时间',
      placeholder: '请选择开业时间',
      submitAction: this.editShop,
    },
    [`${EShopField.MAIN_BUSINESS}`]: {
      labelName: '主营业务',
      label: EShopField.MAIN_BUSINESS,
      value: '',
      type: EShopField.MAIN_BUSINESS,
      fieldTitle: '编辑主营业务',
      placeholder: '请选择主营业务',
      submitAction: this.editShop,
    },
    [`${EShopField.TELS}`]: {
      labelName: '联系电话',
      label: EShopField.TELS,
      value: '',
      type: EShopField.TELS,
      fieldTitle: '编辑联系电话',
      placeholder: '支持11位手机号、400电话或固定座机号码',
      submitAction: this.editShop,
    },
    [`${EShopField.STATION}`]: {
      labelName: '洗车工位数',
      label: EShopField.STATION,
      value: '',
      type: EShopField.STATION,
      fieldTitle: '编辑洗车工位数',
      placeholder: '请选择洗车工位数',
      submitAction: this.editShop,
    },
    [`${EShopField.PARK_NUM}`]: {
      labelName: '免费停车位',
      label: EShopField.PARK_NUM,
      value: '',
      type: EShopField.PARK_NUM,
      fieldTitle: '编辑免费停车位',
      placeholder: '请选择免费停车位',
      submitAction: this.editShop,
    },
    [`${EShopField.DATA_CHED}`]: {
      labelName: '独立洗车位',
      label: EShopField.DATA_CHED,
      value: '',
      type: EShopField.DATA_CHED,
      fieldTitle: '是否有独立洗车位',
      submitAction: this.editShop,
    },
    [`${EShopField.REST_AREA}`]: {
      labelName: '休息区',
      label: EShopField.REST_AREA,
      value: '',
      type: EShopField.REST_AREA,
      fieldTitle: '是否有休息区',
      submitAction: this.editShop,
    },
    [`${EShopField.BUSINESS_TIME}`]: {
      labelName: '营业时间',
      label: EShopField.BUSINESS_TIME,
      value: '',
      type: EShopField.BUSINESS_TIME,
      fieldTitle: '编辑营业时间',
      submitAction: this.editShop,
    },
    [`${EShopField.SHOP_VIDEOS}`]: {
      labelName: '店铺视频',
      label: EShopField.SHOP_VIDEOS,
      value: '',
      type: EShopField.SHOP_VIDEOS,
      fieldTitle: '编辑店铺视频）',
      submitAction: this.editShop,
    },
    [`${EShopField.VIDEOS}`]: {
      labelName: '实拍视频（门店审核）',
      label: EShopField.VIDEOS,
      value: '',
      type: EShopField.VIDEOS,
      fieldTitle: '编辑实拍视频（门店审核）',
      submitAction: this.editShop,
    },
    [`${EShopField.SHOP_IMAGES}`]: {
      labelName: '店铺图片',
      label: EShopField.SHOP_IMAGES,
      value: '',
      type: EShopField.SHOP_IMAGES,
      fieldTitle: '编辑店铺图片',
      submitAction: this.editShop,
    },
    [`${EShopField.SHOP_LICENSE}`]: {
      labelName: '营业执照',
      label: EShopField.SHOP_LICENSE,
      value: '',
      type: EShopField.SHOP_LICENSE,
      fieldTitle: '编辑营业执照',
      submitAction: this.editShop,
    },
    [`${EShopField.BOSS_ID_IMAGES}`]: {
      labelName: '营业执照',
      label: EShopField.BOSS_ID_IMAGES,
      value: '',
      type: EShopField.BOSS_ID_IMAGES,
      fieldTitle: '编辑营业执照',
      submitAction: this.editShop,
    },
    [`${EShopField.STAFF}`]: {
      labelName: '子账户管理',
      label: EShopField.STAFF,
      value: '',
      type: EShopField.STAFF,
      fieldTitle: '编辑店铺子账户',
      submitAction: this.closeShopEditor,
    },
    [`${EShopField.ONSITESERVICEFLAG}`]: {
      labelName: '上门服务',
      label: EShopField.ONSITESERVICEFLAG,
      value: '',
      type: EShopField.ONSITESERVICEFLAG,
      fieldTitle: '上门服务',
      submitAction: this.editOnSiteService,
    },
  };

  /** @todo 1.待确定直接关闭，还是需要刷新； 2.若直接关闭，刷新时机是什么？ 关闭和确认编辑窗口？还是更新任何子账户信息 */
  closeShopEditor() {
    this.shopEditor.close();
  }
  async editOnSiteService(param: { [key: string]: any }) {
    if (!this.shopId) {
      return;
    }
    try {
      const data = {
        shopId: this.shopId,
        onSiteService: param.onsiteServiceFlag,
      };
      const res = await this.shopService.editOnSiteServiceFlag(data);
      if (res) {
        this.$message.success(`${res}`);
        await this.refresh(false);
        this.shopEditor.close();
      }
    } catch (error) {
      this.$message.error(`${error}`);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async openShopEditor(field: EShopField, val: any) {
    const target = Object.assign({}, this.shopFields[`${field}`]);
    target.value = val;
    this.editingField = target;
    if (field === EShopField.TELS) {
      const data = {
        encrypt_phone: this.detail.encrypt_tels,
        operate_type: '门店详情-联系电话编辑',
        poi: this.detail.poi,
      };
      const res = await this.utilService.postPhoneDecrypt(data);
      this.editingField.value = res;
    }

    setTimeout(() => {
      this.shopEditor.open();
    }, 500);
  }

  /** 编辑门店普通信息 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async editShop(param: { [key: string]: any }) {
    if (!this.shopId) {
      return;
    }
    const val = Object.entries(param)[0];
    if (val[0] === 'shopImages') {
      if (val[1].length < 3) {
        this.$message.error('店铺相册图片不能小于3张');
        return;
      }
    }
    try {
      const res = await this.shopService.modifyShop(this.shopId, {
        [`${val[0]}`]: val[1],
      });
      if (res) {
        this.$message.success(`${res}`);
        await this.refresh(false);
        this.shopEditor.close();
      }
    } catch (error) {
      this.$message.error(`${error}`);
    }
  }

  /** 编辑门店地址：
   * 1. 省市区
   * 2. 详细地址
   * 3. 经纬度扎点
   */
  async editAddressInfo(param: { [key: string]: IEditShopFieldAddress }) {
    if (!this.shopId) {
      return;
    }

    const value = Object.entries(param)[0];
    try {
      const val: {
        regionid: number;
        regionpid: number;
        adcode: number;
        address: string;
        latitude: number | string;
        longitude: number | string;
      } = {
        regionpid: value[1].regions[0],
        regionid: value[1].regions[1],
        adcode: value[1].regions[2],
        address: value[1].address,
        latitude: value[1].latitude,
        longitude: value[1].longitude,
      };

      const res = await this.shopService.editAddressInfo(
        Object.assign(val, { shopId: +this.shopId }),
      );
      if (res) {
        this.$message.success(`${res}`);
        await this.refresh();
        this.shopEditor.close();
      }
    } catch (error) {
      this.$message.error(`${error}`);
    }
  }

  /** 编辑广告主手机号码 */
  async editAdvertiserPhoneNum(param: { [key: string]: string }) {
    if (!this.shopId) {
      return;
    }
    try {
      const data = {
        ad_user_phone: param.advertiserMobilePhoneNumber,
      };
      const res = await this.shopService.adUserAddBk(this.shopId, data);
      // const val = Object.entries(param)[0];
      // const phone = val[1];

      // const res = await this.shopService.modifyAdvertiserMobilePhoneNumber(
      //   this.shopId,
      //   phone,
      // );
      if (res) {
        this.$message.success(`${res}`);
        await this.refresh();
        this.shopEditor.close();
      }
    } catch (error) {
      this.$message.error(`${error}`);
    }
  }
}
