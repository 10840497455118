

import { Vue, Component } from 'vue-property-decorator';
import * as echarts from 'echarts';
// type EChartsOption = echarts.EChartsOption;
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';


@Component({})
export default class DepartmentPage extends Vue {
  dataSource: any = [
    {
      month: '十二月',
      month_real_all: '-',
      prev_month_real_all: '-',
      ratio: '-',
    },
    {
      month: '一月',
      month_real_all: '-',
      prev_month_real_all: '-',
      ratio: '-',
    },
    {
      month: '二月',
      month_real_all: '-',
      prev_month_real_all: '-',
      ratio: '-',
    },
    {
      month: '三月',
      month_real_all: '-',
      prev_month_real_all: '-',
      ratio: '-',
    },
    {
      month: '四月',
      month_real_all: '-',
      prev_month_real_all: '-',
      ratio: '-',
    },
    {
      month: '五月',
      month_real_all: '-',
      prev_month_real_all: '-',
      ratio: '-',
    },
    {
      month: '六月',
      month_real_all: '-',
      prev_month_real_all: '-',
      ratio: '-',
    },
    {
      month: '七月',
      month_real_all: '-',
      prev_month_real_all: '-',
      ratio: '-',
    },
    {
      month: '八月',
      month_real_all: '-',
      prev_month_real_all: '-',
      ratio: '-',
    },
    {
      month: '九月',
      month_real_all: '-',
      prev_month_real_all: '-',
      ratio: '-',
    },
    {
      month: '十月',
      month_real_all: '-',
      prev_month_real_all: '-',
      ratio: '-',
    },
    {
      month: '十一月',
      month_real_all: '-',
      prev_month_real_all: '-',
      ratio: '-',
    },
    {
      month: '十二月',
      month_real_all: '-',
      prev_month_real_all: '-',
      ratio: '-',
    },
  ];


  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;

  // dataList: any = [];

  title = '月度部门详情';
  columns = [
    {
      title: '月份',
      dataIndex: 'month',
      align: 'center',
    },
    {
      title: '上月推广通',
      dataIndex: 'month_real_all',
      align: 'center',
    },
    {
      title: '本月推广通',
      dataIndex: 'prev_month_real_all',
      align: 'center',
    },
    {
      title: '环比',
      dataIndex: 'ratio',
      align: 'center',
    },
  ];
  columnsDetail: any[] = [
    {
      title: '月份',
      dataIndex: 'month',
      align: 'center',
      width: 100,
      fixed: 'left',
    },
    {
      title: '合计',
      dataIndex: 'total',
      align: 'center',
      width: 100,
      fixed: 'left',
      customRender: (text: any, row: any, index: number) => {
        const item = this.dataxList[index];
        let total: any = 0;
        for (const i in item) {
          if (i !== 'month' && i !== 'total') {
            total += Number(item[i]);
          }
        }
        return total;
      },
    },
  ];
  dataxList: any = [
    {
      month: '十二月',
      total: 0,
    },
    {
      month: '一月',
      total: 0,
    },
    {
      month: '二月',
      total: 0,
    },
    {
      month: '三月',
      total: 0,
    },
    {
      month: '四月',
      total: 0,
    },
    {
      month: '五月',
      total: 0,
    },
    {
      month: '六月',
      total: 0,
    },
    {
      month: '七月',
      total: 0,
    },
    {
      month: '八月',
      total: 0,
    },
    {
      month: '九月',
      total: 0,
    },
    {
      month: '十月',
      total: 0,
    },
    {
      month: '十一月',
      total: 0,
    },
    {
      month: '十二月',
      total: 0,
    },
  ];
  dataList = {
  };

  refresh() {
  }
  tableRowClassName(record: any, index: number) {
    // console.log(record.month, index % 3);
    if (index % 3 === 0) {
      return 'blue';
    } else if (index % 3 === 1) {
      return 'red';
    } else {
      return 'green';
    }
  }
  mounted() {
    const { department, deptId } = this.$route.query;
    this.title = `${department as string}月度部门详情`;
    this.getDeptSum(deptId);
  }

  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }

  async getDeptSum(deptId: any) {
    const p = {
      dept_id: deptId,
    };
    // console.log(this.dataList.agent_sum);
    // 环比
    const res = await this.workbenchesService.getAdSummaryLine(p);
    // table
    const dataList = await this.workbenchesService.getAdSummaryTable(p);
    // const res = [
    //   {
    //     year: 2024,
    //     month: 12,
    //     month_real_all: 0,
    //     prev_month_real_all: 0,
    //     ratio: 0,
    //   },
    //   {
    //     year: 2025,
    //     month: 1,
    //     month_real_all: 0,
    //     prev_month_real_all: 0,
    //     ratio: 0,
    //   },
    // ];

    const arrChart: any[] = [];
    res.forEach((r: any, i: number) => {
      arrChart.push(r.ratio);
      this.dataSource[i].month_real_all = r.month_real_all;
      this.dataSource[i].prev_month_real_all = r.prev_month_real_all;
      this.dataSource[i].ratio = r.ratio;
    });
    this.initChart(arrChart);


    const { agent_sum, agent_tree } = dataList;
    if (agent_sum) {
      agent_sum.forEach((item: any, i: number) => {
        let obj: any = {};
        item.children.forEach((item2: any) => {
          const _t = `${item2.group_id}_${item2.agent_id}`;
          const _d: any = {};
          _d[_t] = item2.month_real_price;
          obj = { ...obj, ..._d };
        });
        this.dataxList[i] = { ...this.dataxList[i], ...obj };
      });
    }
    if (agent_tree) {
      this.getcolumnsDetail(agent_tree);
    }
    // this.getcolumnsDetail(this.dataList.agent_tree);
  }
  getcolumnsDetail(agent_tree: any[]) {
    console.log(agent_tree, 'agent_tree');
    agent_tree.forEach((node: any) => {
      recursiveUpdate(node);
    });
    this.columnsDetail = [...this.columnsDetail, ...agent_tree[0].children];

    // 递归组装动态表头
    function recursiveUpdate(n: any, parentNode?: any) {
      const node = n;
      node.title = node.agent_name || node.dept_name || node.group_name;
      node.dataIndex = node.agent_id;
      node.key = node.agent_id || node.dept_id || node.group_id;
      if (node.group_id) {
        node.key = node.group_id;
        node.dataIndex = node.group_id;
      }
      if (node.agent_id) {
        node.key = `${parentNode.key}_${node.agent_id}`;
        node.dataIndex = `${parentNode.key}_${node.agent_id}`;
        node.minWidth = 100;
        node.customRender = (text: any) => {
          return text || '-';
        };
      }
      node.align = 'center';
      if (node.children) {
        node.children.forEach((child: any) => {
          recursiveUpdate(child, node);
        });
      }
    }
  }
  initChart(arrChart: any[]) {
    const option = {
      title: {
        text: '月度推广通环比',
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          // 自定义提示框内容
          return `${params[0].name}环比：${params[0].value}%`;
        },
      },
      xAxis: {
        type: 'category',
        data: ['十二月', '一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value}%',
        },
      },
      series: [
        {
          data: arrChart,
          type: 'line',
        },
      ],
    };
    setTimeout(() => {
      const chartWidth = document.getElementsByClassName('department_top_chart')[0];
      const chart: any = document.getElementById('chart');
      chart.style.width = `${chartWidth.clientWidth}px`;
      const myChart = echarts.init(chart);
      myChart.setOption(option);
    }, 1000);
  }
}
