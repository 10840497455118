
import { Vue, Component } from 'vue-property-decorator';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';

@Component({})
export default class MonthlyPerformance extends Vue {
  dataSource = [];

  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;

  columns = [
    {
      title: '1月',
      dataIndex: '1',
      align: 'center',
      customRender: (text: any) => {
        return text.real_finished || '-';
      },
    },
    {
      title: '2月',
      dataIndex: '2',
      align: 'center',
      customRender: (text: any) => {
        return text.real_finished || '-';
      },
    },
    {
      title: '3月',
      dataIndex: '3',
      align: 'center',
      customRender: (text: any) => {
        return text.real_finished || '-';
      },
    },
    {
      title: '4月',
      dataIndex: '4',
      align: 'center',
      customRender: (text: any) => {
        return text.real_finished || '-';
      },
    },
    {
      title: '5月',
      dataIndex: '5',
      align: 'center',
      customRender: (text: any) => {
        return text.real_finished || '-';
      },
    },
    {
      title: '6月',
      dataIndex: '6',
      align: 'center',
      customRender: (text: any) => {
        return text.real_finished || '-';
      },
    },
    {
      title: '7月',
      dataIndex: '7',
      align: 'center',
      customRender: (text: any) => {
        return text.real_finished || '-';
      },
    },
    {
      title: '8月',
      dataIndex: '8',
      align: 'center',
      customRender: (text: any) => {
        return text.real_finished || '-';
      },
    },
    {
      title: '9月',
      dataIndex: '9',
      align: 'center',
      customRender: (text: any) => {
        return text.real_finished || '-';
      },
    },
    {
      title: '10月',
      dataIndex: '10',
      align: 'center',
      customRender: (text: any) => {
        return text.real_finished || '-';
      },
    },
    {
      title: '11月',
      dataIndex: '11',
      align: 'center',
      customRender: (text: any) => {
        return text.real_finished || '-';
      },
    },
    {
      title: '12月',
      dataIndex: '12',
      align: 'center',
      customRender: (text: any) => {
        return text.real_finished || '-';
      },
    },
  ];
  yearAll: any = [
    // {
    //   1: {
    //     month: 1,
    //     real_finished: '0.00',
    //   },
    //   2: {
    //     month: 2,
    //     real_finished: 2919,
    //   },
    //   3: {
    //     month: 3,
    //     real_finished: 4008,
    //   },
    //   4: {
    //     month: 4,
    //     real_finished: '0.00',
    //   },
    //   5: {
    //     month: 5,
    //     real_finished: '0.00',
    //   },
    //   6: {
    //     month: 6,
    //     real_finished: '0.00',
    //   },
    //   7: {
    //     month: 7,
    //     real_finished: '0.00',
    //   },
    //   8: {
    //     month: 8,
    //     real_finished: '0.00',
    //   },
    //   9: {
    //     month: 9,
    //     real_finished: '0.00',
    //   },
    //   10: {
    //     month: 10,
    //     real_finished: '0.00',
    //   },
    //   11: {
    //     month: 11,
    //     real_finished: '0.00',
    //   },
    //   12: {
    //     month: 12,
    //     real_finished: '0.00',
    //   },
    // },
  ];
  columnsChild = [
    {
      title: '部门',
      dataIndex: 'dept_name',
      scopedSlots: { customRender: 'dept_name' },
      align: 'center',
      width: 120,
      fixed: 'left',
    },
  ];
  monthDetail = [
    { label: '一月', value: 'January' },
    { label: '二月', value: 'February' },
    { label: '三月', value: 'March' },
    { label: '四月', value: 'April' },
    { label: '五月', value: 'May' },
    { label: '六月', value: 'June' },
    { label: '七月', value: 'July' },
    { label: '八月', value: 'August' },
    { label: '九月', value: 'September' },
    { label: '十月', value: 'October' },
    { label: '十一月', value: 'November' },
    { label: '十二月', value: 'December' },
  ];

  data = [];
  departmentDetail(record: any) {
    console.log(record);
    this.$router.push({
      name: 'workbenches.DepartmentPage',
      query: {
        department: record.dept_name,
        deptId: record.id,
      },
    });
  }
  refresh() {
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }
  mounted() {
    this.getLsit();
    this.monthDetail.forEach((item) => {
      const obj: any = {
        title: item.label,
        align: 'center',
        children: [
          {
            title: '推广通目标',
            dataIndex: `${item.value}Target`,
            align: 'center',
            width: 120,
            customRender: (text: any) => {
              return text || '-';
            },
          },
          {
            title: '推广通完成',
            dataIndex: `${item.value}Completed`,
            align: 'center',
            width: 120,
            customRender: (text: any) => {
              return text || '-';
            },
          },
          {
            title: '推广通完成率',
            dataIndex: `${item.value}CompletionRate`,
            align: 'center',
            width: 120,
            customRender: (text: any) => {
              return text || '-';
            },
          },
        ],
      };
      this.columnsChild.push(obj);
    });
  }
  async getLsit() {
    // const p = {
    //   user_id: 62,
    // };
    const res = await this.workbenchesService.getAdsummary({});
    const { year_all, table } = res;
    if (table) {
      this.dataStructuring(table);
    }
    if (year_all) {
      this.yearAll[0] = year_all;
    }
  }
  dataStructuring(data: any[]) {
    const dataSource: any = [];
    data.forEach((item) => {
      let child: any = {
        dept_name: item.dept_name,
        id: item.id,
      };
      item.children.forEach((childItem: any) => {
        const obj: any = {
          [`${this.monthDetail[childItem.month - 1].value}Target`]: childItem.target_quota_all,
          [`${this.monthDetail[childItem.month - 1].value}Completed`]: childItem.real_finished_all,
          [`${this.monthDetail[childItem.month - 1].value}CompletionRate`]: childItem.ratio,
        };
        child = { ...child, ...obj };
      });
      dataSource.push(child);
    });
    this.dataSource = dataSource;
  }
  handleScroll() { }
}
