
import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';
import { AccountService } from '@/services/account_service';
import { getCurrentMonth } from '@/common/utils';

@Component({})
export default class MergePaymentIng extends Vue {
  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;
  @lazyInject(AccountService)
  accountService!: AccountService;
  moment = moment;
  pagination: any = {
    pageSize: 10,
    current: 1,
    total: 0,
    onChange: this.paginationChange,
  };
  dataSource = [];
  columns = [
    {
      title: '城市',
      dataIndex: 'city',
      key: 'city',
      align: 'center',
      width: 120,
    },
    {
      title: '地推员',
      dataIndex: 'staff_name',
      key: 'staff_name',
      align: 'center',
      width: 120,

    },
    {
      title: '门店名称',
      dataIndex: 'shop_name',
      key: 'shop_name',
      align: 'center',
      width: 220,

    },
    {
      title: 'POI',
      dataIndex: 'poi',
      key: 'poi',
      align: 'center',
      width: 120,

    },
    {
      title: '订单类型',
      dataIndex: 'order_category',
      key: 'order_category',
      align: 'center',
      width: 120,

    },
    {
      title: '支付方式',
      dataIndex: 'pay_channel',
      key: 'pay_channel',
      align: 'center',
      width: 120,

    },
    {
      title: '订单金额',
      dataIndex: 'pay_price',
      key: 'pay_price',
      align: 'center',
      width: 120,

    },
    {
      title: '余额',
      dataIndex: 'advertiser_amount',
      key: 'advertiser_amount',
      align: 'center',
      width: 120,

    },
    // {
    //   title: '划账流水号',
    //   dataIndex: 'remit_account_number',
    //   key: 'remit_account_number',
    //   align: 'center',
    //   width: 120,
    // },
    {
      title: '支付流水号',
      dataIndex: 'alitradeid',
      key: 'alitradeid',
      align: 'center',
      width: 120,

    },
    {
      title: '创建时间',
      dataIndex: 'ctime',
      key: 'ctime',
      align: 'center',
      width: 120,

    },
    {
      title: '支付时间',
      dataIndex: 'ad_campaign_time',
      key: 'ad_campaign_time',
      align: 'center',
      width: 120,

    },
    // {
    //   title: '充值次数',
    //   dataIndex: 'rechargeTimes',
    //   key: 'rechargeTimes',
    //   align: 'center',
    //   width: 120,

    // },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      align: 'center',
      width: 120,

    },
    {
      title: '操作',
      dataIndex: 'operation',
      scopedSlots: { customRender: 'operation' },
      key: 'operation',
      align: 'center',
      width: 120,

    },
  ];
  permissionListOfRole: any = [];
  fetching = false;
  permissionOptions: any = [];
  filterForm: any = {
    month: '',
    dept_id: undefined,
    group_id: undefined,
    agent_name: '',
  };

  dept_list: any = [];

  group_list: any = [];


  @Watch('$route')
  onRouteChange() {
    const { page, pageSize, month, dept_id, group_id, agent_name, agent_id } = this.$route.query;
    const params: any = {
      page: page || 1,
      pageSize: pageSize || 10,
    };
    if (month) {
      this.filterForm.month = month;
      params.month = month;
    }
    if (dept_id) {
      this.filterForm.dept_id = +dept_id;
      params.dept_id = +dept_id;
    }
    if (group_id) {
      this.filterForm.group_id = +group_id;
      params.group_id = +group_id;
    }
    if (agent_name) {
      this.filterForm.agent_name = agent_name;
      params.agent_name = agent_name;
      params.agent_id = agent_id;
      this.permissionListOfRole = {
        key: agent_id,
        label: agent_name,
      };
      this.searchPermission(agent_name as string);
    }
    this.getList(params);
  }
  async searchPermission(value: string) {
    // console.log(value);
    try {
      const res = await this.accountService.getLeaderList({
        page: 1,
        pageSize: 1000,
        userName: value,
      });
      this.permissionOptions = res.list;
    } catch (error) {
      // console.log(error);
    }
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }
  handleChange(val: any) {
    this.permissionListOfRole = val;
  }
  selectOption(val: any) {
    this.group_list = this.dept_list.find((res: any) => res.dept.id === val).group;
    console.log(this.group_list);
    this.filterForm.group_id = undefined;
  }
  changeMonth(val: any) {
    const year = val._d.getFullYear(); // 获取当前年份，例如：2021
    let month: any = Number(val._d.getMonth()) + 1; // 获取当前月份，注意需要加1，例如：9
    if (month < 10) {
      month = `0${month}`;
    }
    const _d = `${year}-${month}`;
    this.filterForm.month = _d;
  }
  search() {
    console.log('search');
    const { month, dept_id, group_id } = this.filterForm;
    const { key, label } = this.permissionListOfRole;
    const query: any = {
      page: 1,
      pageSize: 10,
    };
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    if (month) {
      query.month = month;
    }
    if (dept_id) {
      query.dept_id = +dept_id;
    }
    if (group_id) {
      query.group_id = +group_id;
    }
    if (key || label) {
      query.agent_id = key;
      query.agent_name = label;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    console.log(query);
    this.$router.push({
      query,
    });
  }

  goToShopDetail(id: string) {
    const routeUrl = this.$router.resolve({
      name: 'main.shop.detail',
      params: {
        id: `${id}`,
      },
    });
    window.open(routeUrl.href, '_blank');
  }
  mounted() {
    this.onRouteChange();
    this.getDeptInfo();
  }

  async getDeptInfo() {
    const res = await this.workbenchesService.getDeptInfo();
    this.dept_list = res;
    const { dept_id } = this.$route.query;
    if (!dept_id) return;
    const deptId = Number(dept_id);
    this.group_list = this.dept_list.find((item: any) => item.dept.id === deptId).group;
  }
  resetQuery() {
    this.filterForm = {
      month: getCurrentMonth(),
      dept_id: undefined,
      group_id: undefined,
      agent_name: '',
    };
    const query: any = {
      page: 1,
      pageSize: 10,
      month: getCurrentMonth(),
    };
    this.permissionListOfRole = [];
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  refresh() {
    console.log('refresh');
  }
  async getList(params: any) {
    const res = await this.workbenchesService.getNeedDeployedShops(params);
    this.dataSource = res.list;
    this.pagination.current = res.page;
    this.pagination.total = res.total;
  }

  paginationChange(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    const { month, dept_id, group_id, agent_name, agent_id } = this.$route.query;
    const query: any = {
      page,
      pageSize,
    };
    if (month) {
      query.month = month;
    }
    if (dept_id) {
      query.dept_id = +dept_id;
    }
    if (group_id) {
      query.group_id = +group_id;
    }
    if (agent_id) {
      query.agent_id = agent_id;
      query.agent_name = agent_name;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
}
