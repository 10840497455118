/*
 * @Author: Src
 * @Date: 2024-12-29 16:30:01
 * @LastEditTime: 2025-04-01 10:53:09
 * @LastEditors: Src
 * @FilePath: /work-station/src/config/config.test.ts
 * @Description: 头部注释配置模板
 */
export default {
  API_URL: 'http://api.pre.test.deyouhui.com.cn',
  RBAC_URL: 'http://rbac.pre.test.deyouhui.com.cn',
  AUDIT_URL: 'http://audit.pre.test.deyouhui.com.cn',
};
