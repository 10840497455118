
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Loading from '@/components/loading/index.vue';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';

@Component({
  components: {
    Loading,
  },
})
export default class Individual extends Vue {
  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;

  @Prop({ type: String, default: '' })
  type?: any;

  ladoing = false;

  objList: any = {
    kaTeam: ['shouldBeCharged', 'promotionTong'],
    kaTeamLeader: ['shouldBeCharged', 'promotionTong'],
    kaTeamMinister: ['shouldBeCharged', 'promotionTong'],
    ordinaryTeam: ['shouldBeCharged', 'storeConsumed', 'mergePaymentIng', 'promotionTong'],
    ordinaryTeamLeader: ['shouldBeCharged', 'storeConsumed', 'mergePaymentIng', 'promotionTong'],
    ordinaryTeamMinister: ['shouldBeCharged', 'storeConsumed', 'mergePaymentIng', 'promotionTong'],
    assistantDirector: [],
    director: [],
  };


  dataObj: any = {
    overflow_rate: {
      data: 0, // 最多保留4位小数，替换成%，需要*100
      pass: true, // 是否合格，判断颜色展示
    },
    deployed_qualified: { // 合并支付投放完成
      data: true, // 是否合格，只有true和false
    },
    ad_campaign_rate: { // 推广通完成率
      data: 1, // 最多保留4位小数，替换成%，需要*100
      pass: true, // 是否合格，判断颜色展示
    },
  };


  @Watch('type')
  WatchData() {
    // console.log(this.type);
    // const typeList = [
    //   'ordinaryTeamLeader',
    //   'kaTeamLeader',
    //   'ordinaryTeam',
    //   'kaTeam',
    // ];
    // console.log(typeList);
    this.getMyList();
  }
  async getMyList() {
    try {
      const res = await this.workbenchesService.getMyAchievement();
      this.ladoing = true;
      if (res) {
        this.dataObj = res;
      }
    } catch (error) {
      this.ladoing = true;
    }
  }

  convertNumber(val: any) {
    return `${val * 100}%`;
  }
  mounted() {
    // console.log(this.type, 'lllllll');
    // this.getMyList();
    // getMyAchievement
  }
}
