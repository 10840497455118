
import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';
import { getCurrentMonth } from '@/common/utils';

@Component({})
export default class ReportForms extends Vue {
  moment = moment;

  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;

  pagination = {
    pageSize: 10,
    current: 1,
    total: 100,
    onChange: this.paginationChange,
  };
  dataSource = [];
  columns = [
    {
      title: '部门',
      dataIndex: 'dept_name',
      align: 'center',
    },
    {
      title: '部长',
      dataIndex: 'leader_name',
      align: 'center',
    },
    {
      title: '推广通金额',
      dataIndex: 'real_price_all',
      align: 'center',
    },
    {
      title: '部门总人数',
      dataIndex: 'agent_num',
      align: 'center',
    },
    {
      title: '人效',
      dataIndex: 'agent_real_price',
      align: 'center',
    },
    // {
    //   title: '操作',
    //   dataIndex: 'action',
    //   align: 'center',
    // },
  ];
  filterForm: any = {
    month: '',
  };

  @Watch('$route')
  onRouteChange() {
    const { month } = this.$route.query;
    const params: any = {};
    if (month) {
      this.filterForm.month = month;
      params.month = month;
    }
    this.getList(params);
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }
  changeMonth(date: any) {
    this.filterForm.month = date ? moment(date).format('YYYY-MM') : '';
  }
  refresh() {
  }
  search() {
    const { month } = this.filterForm;
    const query: any = {};
    if (month) {
      query.month = month;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  mounted() {
    this.onRouteChange();
  }

  resetQuery() {
    this.filterForm = {
      month: getCurrentMonth(),
    };
    const query: any = {};
    query.month = getCurrentMonth();
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  paginationChange(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    const query: any = {
      page,
      pageSize,
    };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
 async getList(params: any) {
    const res = await this.workbenchesService.getAdDeptAgentEffect(params);
    this.dataSource = res;
  }
}
