/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
// import { SPU } from '@/common/interface/shop';
import {
  IResSPU,
  IResPlatformSPU,
  IResPlatformSPUDetail,
  ISPUCategory,
  IResKASPU,
  IResKASPUDetail,
  IResLeadingSPUDetail,
  IResLeadingSPU,
  ILeadingSPUBrief,
} from '@/common/interface/spu';
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';
import StorageService from './storage.service';

@injectable()
export default class SPUService {
  @inject(HttpClient)
  protected http!: HttpClient;

  @inject(StorageService)
  protected storage!: StorageService;

  /** 获取SPU自动下架时间：不区分SPU类型 */
  async getAutoOffShelfTime(spuId: string | number, loadingEl?: Element | Vue) {
    try {
      const url = `/shop/spu/${spuId}/closetime`;
      const res = (await this.http.get(url, {
        data: {
          loadingEl,
        },
      })) as string;
      return res;
    } catch (error) {
      return null;
    }
  }
  /** 设置SPU自动下架时间：不区分SPU类型 */
  async setAutoOffShelfTime(
    params: { spuId: string | number; autoOffShelfTime: string },
    loadingEl?: Element | Vue,
  ) {
    try {
      const { spuId, autoOffShelfTime } = params;
      const url = `/shop/spu/${spuId}/autoclose`;
      const res = (await this.http.post(
        url,
        {
          autoOffShelfTime,
        },
        {
          data: {
            loadingEl,
          },
        },
      )) as string;
      return res;
    } catch (error) {
      return null;
    }
  }

  /** 获取类目参考价：门店id 可选，传代表取区域类目中位价，不传代表传全国类目中位价； */
  async indicativePrice(param: {
    categoryId: number;
    shopId?: number;
    loadingEl?: Element | Vue;
  }) {
    try {
      const { categoryId, shopId, loadingEl } = param;
      let url = `spu/category/indicative-price?categoryId=${categoryId}`;
      if (shopId) {
        url += `&shopId=${shopId}`;
      }
      const data = (await this.http.get(url, {
        data: {
          loadingEl,
        },
      })) as number;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 获取SPU不可用时段组合文案 */
  async spuUnavailableTimeText() {
    const texts: {
      expired: number;
      data: {
        [key: string]: string;
      };
    } | null = this.storage.get('spu_unavailable_time_text');
    const now = Math.round(Date.now() / 1000);
    if (texts && texts.expired > now) {
      return texts.data;
    }
    try {
      const res = (await this.http.get('/spu/unavailable_time/config')) as {
        [key: string]: string;
      };
      this.storage.set('spu_unavailable_time_text', {
        expired: now + 24 * 60 * 60,
        data: res,
      });
      return res;
    } catch (error) {
      return null;
    }
  }

  /** 单个SPU复制 */
  async spuClone(shopId: string, sourceSpuId: number) {
    try {
      const data = (await this.http.post(
        `/spu/${sourceSpuId}/clone?targetShopId=${shopId}`,
      )) as string;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 门店商品复制 */
  async shopSpusClone(
    sourceShopId: number | string,
    targetShopId: number | string,
  ) {
    try {
      const data = (await this.http.post(
        `/shop/spu/clone?sourceShopId=${sourceShopId}&targetShopId=${targetShopId}`,
      )) as string;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** spu分类类目 */
  async spuCategories(
    param: {
      aggregation: boolean;
      shopId?: string;
      loadingEl?: Element | Vue;
    } = { aggregation: true },
  ) {
    // const categories: { expired: number; data: ISPUCategory[] } | null =
    //   this.storage.get('spu_category_list');
    const now = Math.round(Date.now() / 1000);
    // if (categories && categories.expired > now) {
    //   return categories.data;
    // }
    try {
      const { aggregation, shopId, loadingEl } = param;
      let url = `/spu/category?aggregation=${aggregation}`;
      if (shopId && shopId !== '') {
        url += `&shopId=${shopId}`;
      }
      const data = (await this.http.get(url, {
        data: {
          loadingEl,
        },
      })) as ISPUCategory[];
      this.storage.set('spu_category_list', {
        expired: now + 60 * 60,
        data,
      });
      return data;
    } catch (error) {
      return [];
    }
  }

  /** 获取门店下的spu列表（包含：普通SPU、多门店SPU） */
  async shopSpus(
    shopId: string,
    loadingEl?: Element | Vue,
  ): Promise<IResSPU[]> {
    try {
      const data = (await this.http.get(
        `/shop/${shopId}/spu/brief_backend?online=false`,
        {
          data: {
            loadingEl,
          },
        },
      )) as IResSPU[];
      return data;
    } catch (error) {
      return [];
    }
  }

  /** 获取门店可绑定的平台标准商品SPU列表 */
  async shopPlatformSpus(
    shopId: number,
    params: {
      pageSize: number;
      page: number;
      name?: string;
      spuId?: string;
      fcate?: string | number;
      cate?: string | number;
    },
    loadingEl?: Element | Vue,
  ) {
    const { pageSize, page, name, spuId, fcate, cate } = params;
    const newParams: {
      page_size?: number;
      page?: number;
      spu_name?: string;
      spu_id?: string;
      fcate?: number | string;
      cate?: number | string;
    } = {
      page,
      page_size: pageSize,
    };
    if (name) {
      newParams.spu_name = name;
    }
    if (spuId) {
      newParams.spu_id = spuId;
    }
    if (fcate) {
      newParams.fcate = Number(fcate);
    }
    if (cate) {
      newParams.cate = Number(cate);
    }
    try {
      const res = (await this.http.get(`/template-spu/${shopId}/list`, {
        params: newParams,
        data: {
          loadingEl,
        },
      })) as {
        list: IResPlatformSPU[];
        total: number;
        pageSize: string;
        page: number;
      };
      return res;
    } catch (error) {
      return null;
    }
  }

  /** 获取门店已绑定的平台标准商品SPU列表 */
  async shopPlatformSpusBound(
    shopId: number | string,
    loadingEl?: Element | Vue,
  ) {
    try {
      const data = (await this.http.get(`template-spu/${shopId}/bind_list`, {
        data: {
          loadingEl,
        },
      })) as Array<{ name: string }>;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 普通SPU创建 */
  async spuCreate(shopId: string, spu: any, loadingEl?: Element | Vue) {
    try {
      const data = (await this.http.post<any, any, any>(
        `/shop/${shopId}/spu/draft`,
        spu,
        {
          data: {
            loadingEl,
          },
        },
      )) as IResSPU;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 普通SPU编辑修改 */
  async spuEdit(shopId: string, spu: any, loadingEl?: Element | Vue) {
    try {
      const data = (await this.http.post<any, any, any>(
        `/shop/${shopId}/spu/${spu.id}/draft`,
        spu,
        {
          data: {
            loadingEl,
          },
        },
      )) as any;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 普通SPU删除 */
  async spuDelete(shopId: string, id: number, loadingEl?: Element | Vue) {
    try {
      const data = (await this.http.delete(`/shop/${shopId}/spu/${id}`, {
        data: {
          loadingEl,
        },
      })) as string;
      if (data) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /** 普通SPU上架、下架 */
  async spuOnlineStatusChange(
    shopId: string,
    spuId: number,
    isOnline: boolean,
    loadingEl?: Element | Vue,
  ) {
    try {
      const data = (await this.http.put(
        `/shop/${shopId}/spu/${spuId}`,
        {
          isOnline,
        },
        {
          data: {
            loadingEl,
          },
        },
      )) as string;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 普通SPU详情 */
  async spuDetail(shopId: string, id: number, loadingEl?: Element | Vue) {
    try {
      const data = (await this.http.get(`/shop/${shopId}/spu/${id}`, {
        data: {
          loadingEl,
        },
      })) as IResSPU;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 平台标品SPU列表 */
  async platformSpuList(
    params: {
      pageSize: number;
      page: number;
      name?: string;
      spuId?: string;
      categoryId?: number;
    },
    loadingEl?: Element | Vue,
  ) {
    const { pageSize, page, name, spuId, categoryId } = params;
    const newParams: {
      page_size?: number;
      page?: number;
      spu_name?: string;
      spu_id?: string;
      cate?: number;
    } = {};
    if (name) {
      newParams.spu_name = name;
    }
    if (spuId) {
      newParams.spu_id = spuId;
    }
    if (categoryId) {
      newParams.cate = categoryId;
    }
    if (page) {
      newParams.page = page;
    }
    if (pageSize) {
      newParams.page_size = pageSize;
    }
    try {
      const res = (await this.http.get('/template-spu/list', {
        params: newParams,
        data: {
          loadingEl,
        },
      })) as {
        list: IResPlatformSPU[];
        total: number;
        pageSize: string;
        page: number;
      };
      return res;
    } catch (error) {
      return null;
    }
  }

  /** 平台标品SPU详情 */
  async platformSpuDetail(
    spuId: string,
    loadingEl?: Element | Vue,
  ): Promise<IResPlatformSPUDetail | null> {
    try {
      const data = (await this.http.get(`template-spu/${spuId}/detail`, {
        data: {
          loadingEl,
        },
      })) as IResPlatformSPUDetail;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 平台标品SPU创建 */
  async platformSpuCreate(spu: any, loadingEl?: Element | Vue) {
    try {
      const data = (await this.http.post<any, any, any>(
        '/template-spu/add',
        spu,
        {
          data: {
            loadingEl,
          },
        },
      )) as IResPlatformSPUDetail;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 平台标品SPU修改 */
  async platformSpuEdit(spuId: string, spu: any, loadingEl?: Element | Vue) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (await this.http.post<any, any, any>(
        `/template-spu/${spuId}/edit`,
        spu,
        {
          data: {
            loadingEl,
          },
        },
      )) as IResPlatformSPUDetail;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 平台标品SPU删除 */
  async platformSpuDelete(spuId: string, loadingEl?: Element | Vue) {
    try {
      const res = await this.http.delete(`template-spu/${spuId}/delete`, {
        data: {
          loadingEl,
        },
      });
      if (res) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /** 平台标品SPU上下线 */
  async platformSpuOnlineStatusChange(
    spuId: string,
    online: boolean,
    loadingEl?: Element | Vue,
  ) {
    try {
      const res = (await this.http.post<any, any, any>(
        `/template-spu/${spuId}/online`,
        { online },
        {
          data: {
            loadingEl,
          },
        },
      )) as string;
      return res;
    } catch (error) {
      return null;
    }
  }

  /**
   * 平台标品SPU 门店绑定/解绑
   * @param shopId 门店ID
   * @param spuId 平台标品ID
   * @param bindAction 1: 绑定；-1：删除绑定
   * @param loadingEl
   * @returns
   */
  async platformSpuBoundStatusChange(
    shopId: number | string,
    spuId: number | string,
    bindAction: 1 | -1,
    loadingEl?: Element | Vue,
  ) {
    try {
      const url = `/template-spu/${shopId}/bind`;
      const res = (await this.http.post<any, any, any>(
        url,
        { bind: bindAction, spu_id: spuId },
        {
          data: {
            loadingEl,
          },
        },
      )) as string;
      return res;
    } catch (error) {
      return null;
    }
  }

  /** * 平台标品SPU批量删除适用门店（poi） */
  async platformSpusPoisBatchRemove(pois: string, loadingEl?: Element | Vue) {
    try {
      const url = '/template-spu/batch_del';
      const res = (await this.http.post<any, any, any>(
        url,
        { poi: pois },
        {
          data: {
            loadingEl,
          },
        },
      )) as string;
      return res;
    } catch (error) {
      return null;
    }
  }

  /** 品牌标品SPU列表 */
  async kaSpuList(
    params: {
      pageSize: number;
      page: number;
      name?: string;
      spuId?: string;
      categoryId?: number;
    },
    loadingEl?: Element | Vue,
  ) {
    const { pageSize, page, name, spuId, categoryId } = params;
    const newParams: {
      page_size?: number;
      page?: number;
      spu_name?: string;
      spu_id?: string;
      cate?: number;
    } = {};
    if (name) {
      newParams.spu_name = name;
    }
    if (spuId) {
      newParams.spu_id = spuId;
    }
    if (categoryId) {
      newParams.cate = categoryId;
    }
    if (page) {
      newParams.page = page;
    }
    if (pageSize) {
      newParams.page_size = pageSize;
    }
    try {
      const res = (await this.http.get('/template-spu-ka/list', {
        params: newParams,
        data: {
          loadingEl,
        },
      })) as {
        list: IResKASPU[];
        total: number;
        pageSize: string;
        page: number;
      };

      return res;
    } catch (error) {
      return null;
    }
  }

  /** 品牌标品SPU详情 */
  async kaSpuDetail(spuId: string, loadingEl?: Element | Vue) {
    try {
      const data = (await this.http.get(`template-spu-ka/${spuId}/detail`, {
        data: {
          loadingEl,
        },
      })) as IResKASPUDetail;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 品牌标品SPU创建 */
  async kaSpuCreate(spu: any, loadingEl?: Element | Vue) {
    try {
      const data = (await this.http.post<any, any, any>(
        '/template-spu-ka/add',
        spu,
        {
          data: {
            loadingEl,
          },
        },
      )) as IResPlatformSPUDetail;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 品牌标品SPU修改 */
  async kaSpuEdit(spuId: string, spu: any, loadingEl?: Element | Vue) {
    try {
      return (await this.http.post<any, any, any>(
        `/template-spu-ka/${spuId}/edit`,
        spu,
        {
          data: {
            loadingEl,
          },
        },
      )) as string;
    } catch (error) {
      return null;
    }
  }

  /** 品牌标品SPU删除 */
  async kaSpuDelete(spuId: string, loadingEl?: Element | Vue) {
    try {
      const res = await this.http.delete(`template-spu-ka/${spuId}/delete`, {
        data: {
          loadingEl,
        },
      });
      if (res) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /** 品牌标品SPU上下线 */
  async kaSpuOnlineStatusChange(
    spuId: string,
    online: boolean,
    loadingEl?: Element | Vue,
  ) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = (await this.http.post<any, any, any>(
        `/template-spu-ka/${spuId}/online`,
        { online },
        {
          data: {
            loadingEl,
          },
        },
      )) as string;
      return res;
    } catch (error) {
      return null;
    }
  }

  /** 品牌标品SPU 可选KA列表 */
  async kaSpuAccessibleKas() {
    const now = Math.round(Date.now() / 1000);

    const kaSpuAccessibleKas: {
      expired: number;
      data: Array<{ key: string; val: number }>;
    } | null = this.storage.get('ka_spu_accessible_kas');

    if (kaSpuAccessibleKas && kaSpuAccessibleKas.expired > now) {
      return kaSpuAccessibleKas.data;
    }

    try {
      const res = await this.http.get('/template-spu-ka/ka_right');
      if (res) {
        const list: Array<{ key: string; val: number }> = [];
        for (const [k, v] of Object.entries(res)) {
          list.push({ key: k, val: v });
        }
        this.storage.set('ka_spu_accessible_kas', {
          expired: now + 1 * 60 * 60,
          data: [...list],
        });
        return list;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  /** 品牌标品SPU KA列表（全部） */
  async kaSpuKas() {
    const now = Math.round(Date.now() / 1000);

    const kaSpuKas: {
      expired: number;
      data: Array<{ key: string; val: number }>;
    } | null = this.storage.get('ka_spu_kas');

    if (kaSpuKas && kaSpuKas.expired > now) {
      return kaSpuKas.data;
    }

    try {
      const res = await this.http.get('/template-spu-ka/ka');
      if (res) {
        const list: Array<{ key: string; val: number }> = [];
        for (const [k, v] of Object.entries(res)) {
          list.push({ key: k, val: v });
        }
        this.storage.set('ka_spu_kas', {
          expired: now + 24 * 60 * 60,
          data: [...list],
        });
        return list;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  /** 品牌标品SPU 可选择门店列表 */
  async kaSpuAccessibleShops(
    reqParams: {
      pageSize: number;
      page: number;
      id?: number;
      poi?: string;
      name?: string;
      ka?: number;
      regionId?: number;
      provinceId?: number;
      businessStatus?: string;
      pick: number;
      shopids?: string;
      onsite_service?: string;
    },
    loadingEl?: Element | Vue,
  ) {
    const {
      pageSize,
      page,
      poi,
      name,
      id,
      regionId,
      provinceId,
      ka,
      businessStatus,
      shopids,
      pick,
      onsite_service,
    } = reqParams;
    const params: any = {
      page_size: pageSize,
      page,
      pick,
    };
    if (poi) {
      params.poi = poi;
    }
    if (name) {
      params.shop_name = name;
    }
    if (id) {
      params.shop_id = id;
    }
    if (ka) {
      params.ka_id = ka;
    }
    if (typeof businessStatus !== 'undefined') {
      params.onlinestatus = businessStatus;
    }
    if (typeof shopids !== 'undefined') {
      params.shopids = shopids;
    }

    if (regionId) {
      params.regionid = regionId;
    }
    if (provinceId) {
      params.regionpid = provinceId;
    }
    if (onsite_service) {
      params.onsite_service = onsite_service;
    }

    try {
      const data = (await this.http.post(
        'template-spu-ka/search-shop',
        params,
        {
          data: {
            loadingEl,
          },
        },
      )) as {
        list: Array<{
          AutoRepairShopDataId: number;
          name: string;
          poi: string;
          ka_id: number;
          regionid: number;
          regionpid: number;
          onlinestatus: any;
          onsite_service_flag: any;
        }>;
        lastPage: number;
        page: number;
        pageSize: string;
        total: number;
      };
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 品牌标品SPU 校验适用门店IDS */
  async kaSpuCheckShopIds(shopIds: any, loadingEl?: Element | Vue) {
    try {
      const res = (await this.http.post(
        'template-spu-ka/check-shop',
        shopIds,
        {
          data: {
            loadingEl,
          },
        },
      )) as {
        data: string;
        error: string;
        forever: string;
        stop: string;
        num_error: string;
        onsite_error: string;
      };
      return res;
    } catch (error) {
      return null;
    }
  }

  /** 批量多门店商品SPU列表 */
  async leadingSpuList(
    params: {
      pageSize: number;
      page: number;
      cate?: number;
      fcate?: number;
      spuName?: string;
      spuId?: number;
      shopId?: number;
      createStartTime?: string;
      createEndTime?: string;
      filterTag?: string;
    },
    loadingEl?: Element | Vue,
  ): Promise<{
    total: number;
    pageSize: number;
    page: number;
    list: ILeadingSPUBrief[];
  } | null> {
    const {
      pageSize,
      page,
      cate,
      fcate,
      spuName,
      spuId,
      shopId,
      createStartTime,
      createEndTime,
      filterTag,
    } = params;

    const newParams: {
      page_size: number;
      page: number;
      spu_name?: string;
      spu_id?: number;
      fcate?: number;
      cate?: number;
      shop_id?: number;
      start_time?: string;
      end_time?: string;
      tag?: string;
    } = {
      page_size: pageSize,
      page,
    };
    if (fcate) {
      newParams.fcate = fcate;
    }
    if (cate) {
      newParams.cate = cate;
    }
    if (shopId) {
      newParams.shop_id = shopId;
    }
    if (spuName) {
      newParams.spu_name = spuName;
    }
    if (spuId) {
      newParams.spu_id = spuId;
    }
    if (createStartTime) {
      newParams.start_time = createStartTime;
    }
    if (createEndTime) {
      newParams.end_time = createEndTime;
    }
    if (filterTag) {
      newParams.tag = filterTag;
    }

    try {
      const categoryMap = new Map<number, string>();
      const allCategories = await this.spuCategories();
      const setCategoryMap = (list: ISPUCategory[]) => {
        for (const item of list) {
          categoryMap.set(item.id, item.name);
          if (item.children && item.children.length) {
            setCategoryMap(item.children);
          }
        }
      };
      setCategoryMap(allCategories);

      const res = (await this.http.get('/template-spu-ba/list', {
        params: newParams,
        data: {
          loadingEl,
        },
      })) as {
        list: IResLeadingSPU[];
        total: number;
        pageSize: number;
        page: number;
      };

      return {
        total: res.total,
        pageSize: res.pageSize,
        page: res.page,
        list: res.list.map((item) => {
          const {
            headlineImage,
            id,
            name,
            isdel,
            last_update_time,
            operatorName,
            fcid,
            scid,
            isOnline,
            tempSpuShopIds,
            vipPriceCent,
            createTime,
            remark,
            note_tag,
          } = item;
          return {
            id,
            name,
            headlineImage,
            primaryCategoryName: categoryMap.get(fcid),
            secondaryCategoryName: categoryMap.get(scid),
            vipPriceCent,
            tempSpuShopIds,
            isOnline,
            isDeleted: isdel === 1,
            operatorName,
            updateTime: last_update_time || createTime || '',
            filterTag: note_tag || '',
            remark: remark || '',
          };
        }) as unknown as ILeadingSPUBrief[],
      };
    } catch (error) {
      return null;
    }
  }

  /** 批量多门店商品SPU详情 */
  async leadingSpuDetail(spuId: string, loadingEl?: Element | Vue) {
    try {
      const data = (await this.http.get(`template-spu-ba/${spuId}/detail`, {
        data: {
          loadingEl,
        },
      })) as IResLeadingSPUDetail;

      return data;
    } catch (error) {
      return null;
    }
  }

  /** 批量多门店商品SPU创建 */
  async leadingSpuCreate(spu: any, loadingEl?: Element | Vue) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (await this.http.post<any, any, any>(
        '/template-spu-ba/add',
        spu,
        {
          data: {
            loadingEl,
          },
        },
      )) as any;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 批量多门店商品SPU编辑 */
  async leadingSpuEdit(
    spuId: number | string,
    spu: any,
    loadingEl?: Element | Vue,
  ) {
    try {
      const url = `/template-spu-ba/${spuId}/edit`;
      const data = (await this.http.post<any, any, any>(url, spu, {
        data: {
          loadingEl,
        },
      })) as IResKASPUDetail;
      return data;
    } catch (error) {
      return null;
    }
  }

  /** 批量多门店商品SPU删除 */
  async leadingSpuDelete(id: number, loadingEl?: Element | Vue) {
    try {
      const res = await this.http.delete(`template-spu-ba/${id}/delete`, {
        data: {
          loadingEl,
        },
      });
      if (res) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /** 批量多门店商品SPU上下架 */
  async leadingSpuOnlineStatusChange(
    id: number,
    isOnline: boolean,
    loadingEl?: Element | Vue,
    secondConfirm?: boolean,
  ) {
    let url = `/template-spu-ba/${id}/online_push`;
    if (secondConfirm) {
      url = `${url}?ok=1`;
    }
    const online = isOnline === true ? 1 : 2;
    try {
      const res = (await this.http.post<any, any, any>(
        url,
        { online },
        {
          data: {
            loadingEl,
          },
        },
      )) as any;
      return res;
    } catch (error) {
      return null;
    }
  }

  /** 批量多门店商品SPU KA列表（全部） */
  async leadingSpuKas() {
    const now = Math.round(Date.now() / 1000);

    const kas: {
      expired: number;
      data: Array<{ key: string; val: number }>;
    } | null = this.storage.get('leading_spu_kas');

    if (kas && kas.expired > now) {
      return kas.data;
    }
    try {
      const res = await this.http.get('/template-spu-ba/ka');
      if (res) {
        const list: Array<{ key: string; val: number }> = [];
        for (const [k, v] of Object.entries(res)) {
          list.push({ key: k, val: v });
        }
        this.storage.set('leading_spu_kas', {
          expired: now + 24 * 60 * 60,
          data: [...list],
        });
        return list;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  /** 批量多门店商品SPU 校验适用门店IDS */
  async leadingSpuCheckShopIds(shopIds: any, loadingEl?: Element | Vue) {
    try {
      const res = (await this.http.post(
        'template-spu-ba/check-shop',
        shopIds,
        {
          data: {
            loadingEl,
          },
        },
      )) as {
        data: string;
        error: string;
        forever: string;
        stop: string;
        num_error: string;
        onsite_error: string;
      };
      return res;
    } catch (error) {
      return null;
    }
  }

  /** 批量多门店商品SPU 可选择门店列表 */
  async leadingSpuAccessibleShops(
    reqParams: {
      pageSize: number;
      page: number;
      id?: number;
      poi?: string;
      name?: string;
      ka?: number;
      regionId?: number;
      provinceId?: number;
      businessStatus?: string;
      pick: number;
      shopids?: string;
      onsite_service?: string;
    },
    loadingEl?: Element | Vue,
  ) {
    const {
      pageSize,
      page,
      poi,
      name,
      id,
      regionId,
      provinceId,
      ka,
      businessStatus,
      shopids,
      pick,
      onsite_service,
    } = reqParams;
    const params: any = {
      page_size: pageSize,
      page,
      pick,
    };
    if (poi) {
      params.poi = poi;
    }
    if (name) {
      params.shop_name = name;
    }
    if (id) {
      params.shop_id = id;
    }
    if (ka) {
      params.ka_id = ka;
    }
    if (typeof businessStatus !== 'undefined') {
      params.onlinestatus = businessStatus;
    }
    if (typeof shopids !== 'undefined') {
      params.shopids = shopids;
    }

    if (regionId) {
      params.regionid = regionId;
    }
    if (provinceId) {
      params.regionpid = provinceId;
    }
    if (onsite_service) {
      params.onsite_service = onsite_service;
    }
    try {
      const data = (await this.http.post(
        'template-spu-ba/search-shop',
        params,
        {
          data: {
            loadingEl,
          },
        },
      )) as {
        list: Array<{
          AutoRepairShopDataId: number;
          name: string;
          poi: string;
          ka_id: number;
          regionid: number;
          regionpid: number;
          onlinestatus: any;
          onsite_service_flag: any;
        }>;
        lastPage: number;
        page: number;
        pageSize: string;
        total: number;
      };
      return data;
    } catch (error) {
      return null;
    }
  }

  async leadingSpuFilterTags(str: string) {
    try {
      const url = 'template-spu-ba/note_tag';
      const params = {
        tag: str || '',
        show_default: !str || str === ' ' ? 1 : 0,
      };
      const res = (await this.http.get(url, { params })) as {
        lastPage: number;
        page: number;
        pageSize: number;
        total: number;
        list: Array<{ note_tag: string }>;
      };
      if (res) {
        const { lastPage, page, pageSize, total, list } = res;
        return {
          lastPage,
          page,
          pageSize,
          total,
          list: list.map((item) => {
            return {
              label: item.note_tag,
              value: item.note_tag,
            };
          }),
        };
      }
    } catch (error) {
      return null;
    }
  }

  async getShopDetailItemList(params: any) {
    try {
      const res = await this.http.get('shop_detail_item/list', {
        params,
      }) as {
        list: any[];
        lastPage: number;
        page: number;
        pageSize: number;
        total: number;
      };
      return res;
    } catch (error) {
      return null;
    }
  }
  async getShopDetailItemDetail(item_id: number) {
    try {
      const res = await this.http.get(`shop_detail_item/detail/${item_id}`) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
  async editShopDetailItem(item_id: any, data: any) {
    try {
      const res = await this.http.post(`shop_detail_item/edit/${item_id}`, data) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
  async addShopDetailItem(data: any) {
    try {
      const res = await this.http.post('shop_detail_item/add', data) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
  async copyProperty(data: any) {
    try {
      const res = await this.http.post('shop_detail_item/copy', data) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
  async deleteShopDetailItem(item_id: number) {
    try {
      const res = await this.http.post(`shop_detail_item/delete/${item_id}`) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
  async getShopDetailItemListBySpuId(params: any) {
    try {
      const res = await this.http.get('spu/category', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
  async postSpuDefaultDefaultItem(data: any) {
    try {
      const res = await this.http.post('spu/edit/default_item', data) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
  async postShopDetailItem(params: any) {
    try {
      const res = await this.http.get('shop_detail_item/items', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
  async getcategoryIdDetail(categoryId: number) {
    try {
      const res = await this.http.get(`spu/${categoryId}/detail`) as any;
      return res;
    } catch (error) {
      return null;
    }
  }

  async deleteSpuDefaultItem(categoryId: number) {
    try {
      const res = await this.http.delete(`spu/${categoryId}/default_item`) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
  async getShopDetailItemLogList(params: any) {
    try {
      const res = await this.http.get('shop_detail_item/log/list', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
  async getRule(key: string) {
    try {
      const result = await this.http.get(`query/${key}/keyValue`) as any;
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getShopDetailItemLogDetail(params: any) {
    try {
      const res = await this.http.get('shop_detail_item/log/detail', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
  async editOnSiteService(data: any) {
    try {
      const res = await this.http.post('onSiteService/spu/edit/onSiteService', data) as any;
      return res;
    } catch (error) {
      return null;
    }
  }

  /**
   * 返回false表示门店支持门店到家服务，返回true表示门店不支持门店到家服务
   * @param shopId
   * @returns
   */
  async getIsSupport(shopId: number) {
    try {
      const res = await this.http.get(`onSiteService/is_support/${shopId}`) as any;
      return res;
    } catch (error) {
      return null;
    }
  }


  async checkSpuBat(kaId: any, params: any) {
    try {
      const res = await this.http.post(`shop/spu-bat/check?ka_id=${kaId}`, params) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
  async getSpuBat(data: any, params: any) {
    console.log(data);
    try {
      const { categoryId, regionid, regionpid, regionId, ka_id, scid, shop_name, service_name, shop_id, poi, pick, spu_ids, spu_id } = data;
      if (categoryId) {
        params.category_id = categoryId;
      }
      if (regionid) {
        params.regionid = regionid;
      }
      if (regionId) {
        params.regionId = regionId;
      }
      if (ka_id) {
        params.ka_id = ka_id;
      }
      if (scid) {
        params.scid = scid;
      }
      if (shop_name) {
        params.shop_name = shop_name;
      }
      if (service_name) {
        params.service_name = service_name;
      }
      if (shop_id) {
        params.shop_id = shop_id;
      }
      if (spu_id) {
        params.spu_id = spu_id;
      }
      if (poi) {
        params.poi = poi;
      }
      if (regionpid) {
        params.regionpid = regionpid;
      }
      if (pick) {
        params.pick = pick;
        if (pick === 1) {
          params.spu_ids = spu_ids;
        }
        if (pick === 2) {
          params.spu_ids = spu_ids;
        }
      }

      const res = await this.http.get('shop/spu-bat/search', { params }) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
  async postSpuBatDelete(data: any) {
    try {
      const res = await this.http.post('shop/spu-bat/delete', data) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
}

