
import { DebounceTime } from '@/common/decorator';
import { ESPUField, ENProductSource } from '@/common/enum/spu';
import {
  ILeadingSPUBrief,
  ISPUCategory,
  ISPUField,
  ISPUProduct,
} from '@/common/interface/spu';
import SPUEditor from '@/components/spu/SPUEditor.vue';
import SPUPreview from '@/components/spu/SPUPreview.vue';
import lazyInject from '@/di';
import { LeadingSPU } from '@/models/spu.model';
import { SPUFieldFactory } from '@/models/spuFieldFactory';
import SPUService from '@/services/spu.service';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import SpuLeadingLog from './components/SpuLeadingLog.vue';

const InitFilterValue = {
  spuName: '',
  spuId: '',
  shopName: '',
  shopId: '',
  poiId: '',
  cate: '',
  fcate: '',
  filterTag: [],
  createStartTime: null,
  createEndTime: null,
};

@Component({
  components: {
    SPUEditor,
    SPUPreview,
    SpuLeadingLog,
  },
})
export default class SpuLeading extends Vue {
  form: {
    spuName: string; // 商品名称
    spuId: string; // 商品ID
    shopName: string; // 门店名称
    shopId: string; // 德友惠店铺ID
    poiId: string; // POIID
    createStartTime: Moment | null;
    createEndTime: Moment | null;
    cate: string | number; // 二级类目
    fcate: string | number; // 一级类目
    filterTag: string[]; // 筛选标签
  } = { ...InitFilterValue };

  listData: ILeadingSPUBrief[] = [];

  ENProductSource = ENProductSource;

  pagination = {
    total: 0,
    pageSize: 20,
    current: 1,
    onChange: this.paginationChange,
  };

  editingSpu: LeadingSPU = new LeadingSPU();

  spuForm: { [key: string]: any } = {};

  spuConfig: ISPUField[] = [];

  isLoading = false;

  fetching = false;

  spuEditorTitle = '';

  initSpuFields: ISPUField[] = SPUFieldFactory.init({
    spuType: 'leadingSpu',
    vipPriceValidator: this.vipPriceValidator,
    originalPriceValidator: this.originalPriceValidator,
    newUserPriceValidator: this.newUserPriceValidator,
  });

  columns = [
    {
      title: '商品ID',
      dataIndex: 'id',
      key: 'id',
      scopedSlots: { customRender: 'id' },
      width: 110,
    },
    {
      title: '商品图片',
      dataIndex: 'headlineImage',
      key: 'headlineImage',
      scopedSlots: { customRender: 'headlineImage' },
      width: 80,
    },
    {
      title: '商品名称',
      dataIndex: 'name',
      key: 'name',
      width: 240,
    },
    {
      title: '筛选标签',
      dataIndex: 'filterTag',
      key: 'filterTag',
      scopedSlots: { customRender: 'filterTag' },
      width: 100,
    },
    {
      title: '一级分类',
      dataIndex: 'primaryCategoryName',
      key: 'primaryCategoryName',
      width: 80,
    },
    {
      title: '二级分类',
      dataIndex: 'secondaryCategoryName',
      key: 'secondaryCategoryName',
      width: 80,
    },
    {
      title: '黑卡价',
      dataIndex: 'vipPriceCent',
      key: 'vipPriceCent',
      scopedSlots: { customRender: 'vipPriceCent' },
      width: 100,
    },
    {
      title: '适用门店',
      dataIndex: 'tempSpuShopIds',
      key: 'tempSpuShopIds',
      scopedSlots: { customRender: 'tempSpuShopIds' },
      width: 120,
    },
    {
      title: '创建人',
      dataIndex: 'operatorName',
      key: 'operatorName',
      width: 120,
    },
    {
      title: '操作时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 180,
    },
    {
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      scopedSlots: { customRender: 'actions' },
      width: 120,
    },
  ];

  /** 分类数据 */
  secondCategoryList: ISPUCategory[] = [];

  allSpuCategory: ISPUCategory[] = [];

  filterTagOptions: Array<{ label: string; value: string }> = [];

  @Ref()
  spuEditor!: SPUEditor;

  @Ref()
  spuLeadingLog!: SpuLeadingLog;

  @Ref()
  spuPreview!: SPUPreview;

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { pageSize, current } = this.pagination;
    const param: any = await this.extractRouterQueryAndFillForm(true);
    param.pageSize = pageSize;
    param.page = current;
    await this.all(param);
  }

  @DebounceTime(300)
  async onSearchTags(tag: string) {
    await this.searchFilterTags(tag);
  }

  getRemarkText(remark: string) {
    if (remark.length < 100) {
      return remark;
    }
    return `${remark.substring(0, 99)}...`;
  }

  async onChangeTags(selected: Array<{ label: string; value: string }>) {
    this.$set(this.form, 'filterTag', selected);
    if (!selected.length) {
      await this.searchFilterTags(' ');
    }
  }

  async onFocusTags() {
    if (this.filterTagOptions.length) {
      return;
    }
    await this.searchFilterTags(' ');
  }

  async searchFilterTags(tag: string) {
    if (this.fetching) {
      return;
    }
    this.fetching = true;

    try {
      const res = await this.spuService.leadingSpuFilterTags(tag);
      if (res) {
        this.filterTagOptions = res.list;
      }
      this.fetching = false;
    } catch (error) {
      this.fetching = false;
    }
  }

  getPopupContainer(trigger: { parentElement: any }) {
    return trigger.parentElement;
  }

  onClosePreiew() {
    this.editingSpu = new LeadingSPU();
  }

  /** 上架商品 */
  async putOnSpu(id: number, isOnline = true, secondConfirm?: boolean) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const res =
      typeof secondConfirm !== 'undefined' && secondConfirm === true
        ? await this.spuService.leadingSpuOnlineStatusChange(
            id,
            isOnline,
            this.$el,
            true,
          )
        : await this.spuService.leadingSpuOnlineStatusChange(
            id,
            isOnline,
            this.$el,
          );

    if (res) {
      if (typeof secondConfirm === 'undefined') {
        this.$confirm({
          title: `${res}`,
          onOk: async () => {
            this.isLoading = false;
            await this.putOnSpu(id, isOnline, true);
          },
          onCancel: () => {
            this.isLoading = false;
          },
        });
        return;
      }
      this.$message.success(res);
      this.isLoading = false;
      setTimeout(() => {
        this.handleSearch();
      }, 500);
    } else {
      this.isLoading = false;
    }
  }

  /** 下架商品 */
  async takeOffSpu(id: number, isOnline: boolean) {
    const res = await this.spuService.leadingSpuOnlineStatusChange(
      id,
      !isOnline,
      this.$el,
    );
    if (res) {
      this.$message.success(res);
      setTimeout(() => {
        this.handleSearch();
      }, 500);
    }
  }
  /** @todo 查看 */
  async previewSpu(id: number) {
    const autoOffShelfTime = await this.spuService.getAutoOffShelfTime(
      id,
      this.$el,
    );
    const data = await this.spuService.leadingSpuDetail(`${id}`, this.$el);

    if (data) {
      this.editingSpu = LeadingSPU.from(data);
      const indicativePrice = await this.spuService.indicativePrice({
        categoryId: this.editingSpu.category.id,
      });
      this.editingSpu.autoOffShelfTime = autoOffShelfTime || '';
      this.editingSpu.isAutoOffShelf = !!(
        autoOffShelfTime && autoOffShelfTime !== ''
      );
      if (indicativePrice) {
        this.editingSpu.indicativePrice = indicativePrice;
      }
      this.initSpuForm(this.editingSpu, true);
    }
  }

  /** 复制SPU */
  async cloneSpu(id: number) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.spuEditorTitle = '复制批量商品';
    const data = await this.spuService.leadingSpuDetail(`${id}`);
    if (data) {
      data.id = 0;
      this.editingSpu = LeadingSPU.from(data);
      this.editingSpu.autoOffShelfTime = '';
      this.editingSpu.isAutoOffShelf = false;
      this.editingSpu.tempSpuShopIds = '';
      this.initSpuForm(this.editingSpu);
      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
  }

  /** 删除批量商品 */
  async deleteSpu(id: number) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const res = await this.spuService.leadingSpuDelete(id, this.$el);
    if (res) {
      this.$message.success('删除成功');
      setTimeout(() => {
        this.handleSearch();
        this.isLoading = false;
      }, 500);
    } else {
      this.isLoading = false;
    }
  }

  /** 新增批量商品 */
  add() {
    this.spuEditorTitle = '新增批量商品';
    this.editingSpu = new LeadingSPU();
    this.initSpuForm(this.editingSpu);
  }

  /** 修改批量商品 */
  async edit(id: number) {
    this.spuEditorTitle = '编辑批量商品';
    const autoOffShelfTime = await this.spuService.getAutoOffShelfTime(
      id,
      this.$el,
    );
    const data = await this.spuService.leadingSpuDetail(`${id}`, this.$el);
    if (data) {
      this.editingSpu = LeadingSPU.from(data);
      this.editingSpu.autoOffShelfTime = autoOffShelfTime || '';
      this.editingSpu.isAutoOffShelf = !!(
        autoOffShelfTime && autoOffShelfTime !== ''
      );
      this.initSpuForm(this.editingSpu);
    }
  }

  async initSpuForm(editingSpu: LeadingSPU, isViewMode?: boolean) {
    const form: { [key: string]: any } = {};
    const times =
      editingSpu.effectiveTimes > 0 ? editingSpu.effectiveTimes * 100 : 100;

    for (const [key, value] of Object.entries(editingSpu)) {
      switch (key) {
        case ESPUField.EFFECTIVE_TIMES: {
          if (Number(value) > 1) {
            form.isCombo = true;
          } else {
            form.isCombo = false;
          }
          form[key] = value;
          break;
        }
        case ESPUField.ORIGINAL_PRICE_CENT: {
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }
        case ESPUField.VIP_PRICE_CENT: {
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }
        case ESPUField.NEW_USER_PRICE_CENT: {
          form.hasNewUserPrice = value > 0;
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }

        default: {
          form[key] = value;
          break;
        }
      }
    }

    form.comboTotalPrice = {
      originalPriceCent: editingSpu.originalPriceCent,
      vipPriceCent: editingSpu.vipPriceCent,
      newUserPriceCent: editingSpu.newUserPriceCent,
    };

    if (isViewMode) {
      this.spuForm = { ...form };
      this.spuPreview.setup(
        await this.initSpuConfig({ isViewMode: true }),
        this.spuForm,
      );
    } else {
      this.handleUpdate(form, true);
    }
  }

  async initSpuConfig(params: {
    isViewMode: boolean;
    refreshIndicative?: boolean;
  }) {
    const { isViewMode, refreshIndicative } = params;
    const spu = { ...this.spuForm };
    const detail = _.cloneDeep(this.initSpuFields);

    const autoOffShelfTimeIdx = detail.findIndex(
      (item) => item.label === ESPUField.AUTO_OFF_SHELF_TIME,
    );
    const isComboIndex = detail.findIndex(
      (item) => item.label === ESPUField.IS_COMBO,
    );
    const idIdx = detail.findIndex((item) => item.label === ESPUField.ID);
    const originalPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.ORIGINAL_PRICE_CENT,
    );
    const vipPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.VIP_PRICE_CENT,
    );
    const newUserPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.NEW_USER_PRICE_CENT,
    );
    const comboTotalPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.COMBO_TOTAL_PRICE,
    );
    const effectiveTimesIdx = detail.findIndex(
      (item) => item.label === ESPUField.EFFECTIVE_TIMES,
    );
    const headlineImageIdx = detail.findIndex(
      (item) => item.label === ESPUField.HEADLINE_IMAGE,
    );
    const hasNewUserPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.HAS_NEW_USER_PRICE,
    );
    const imagesIdx = detail.findIndex(
      (item) => item.label === ESPUField.IMAGES,
    );

    if (spu.id === 0) {
      // 新增品牌标品时允许更换是否套餐、允许更换ka选项
      detail[isComboIndex].readonly = false;
      detail[idIdx].isHidden = true;
    } else {
      // 新增品牌标品时不允许更改是否套餐的选项、不允许更换ka选项
      detail[isComboIndex].readonly = true;
      detail[idIdx].isHidden = false;
    }

    if (spu.isCombo === true) {
      detail[effectiveTimesIdx].isHidden = false;
      detail[comboTotalPriceIdx].isHidden = false;
      detail[originalPriceIdx].labelName = '单次门市价(元)';
      detail[vipPriceIdx].labelName = '单次黑卡价(元)';
      detail[newUserPriceIdx].labelName = '单次特价(元)';
    } else {
      detail[effectiveTimesIdx].isHidden = true;
      detail[comboTotalPriceIdx].isHidden = true;
      detail[originalPriceIdx].labelName = '门市价(元)';
      detail[vipPriceIdx].labelName = '黑卡价(元)';
      detail[newUserPriceIdx].labelName = '特价(元)';
    }

    if (spu.hasNewUserPrice === true) {
      detail[newUserPriceIdx].isHidden = false;
    } else {
      detail[newUserPriceIdx].isHidden = true;
    }

    if (refreshIndicative === true) {
      const indicativePrice = await this.queryIndicativePrice({
        categoryId: spu.category.id,
      });
      if (indicativePrice) {
        this.editingSpu.indicativePrice = indicativePrice;
        // eslint-disable-next-line standard/computed-property-even-spacing
        detail[
          vipPriceIdx
        ].extra = `参考价：该商品分类，全国均价¥${indicativePrice}`;
      } else {
        detail[vipPriceIdx].extra = '暂无参考价';
      }
    } else {
      detail[vipPriceIdx].extra =
        this.editingSpu.indicativePrice > 0
          ? `参考价：该商品分类，全国均价¥${this.editingSpu.indicativePrice}`
          : '暂无参考价';
    }

    if (spu.isAutoOffShelf === true) {
      detail[autoOffShelfTimeIdx].isHidden = false;
      detail[autoOffShelfTimeIdx].rule = [
        { required: true, message: '自动下架时间不能为空', trigger: 'blur' },
        {
          validator: this.autoOffShelfTimeValidator,
          message: '自动下架时间至少设置在10分钟以后',
          trigger: 'blur',
        },
      ];
    } else {
      detail[autoOffShelfTimeIdx].isHidden = true;
      if (detail[autoOffShelfTimeIdx].rule) {
        delete detail[autoOffShelfTimeIdx].rule;
      }
    }

    if (isViewMode === true) {
      delete detail[hasNewUserPriceIdx].extra;
      delete detail[headlineImageIdx].extra;
      delete detail[imagesIdx].extra;
      if (spu.hasNewUserPrice === true) {
        detail[hasNewUserPriceIdx].isHidden = true;
      } else {
        detail[hasNewUserPriceIdx].isHidden = false;
      }
    }

    return detail;
  }

  vipPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { originalPriceCent, products } = this.spuForm;

    if (products.length) {
      return +value < +this.spuForm.comboTotalPrice.originalPriceCent;
    } else {
      if (+value > 0 && (!originalPriceCent || isNaN(+originalPriceCent))) {
        return true;
      }
      return +value < +originalPriceCent;
    }
  }

  newUserPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { vipPriceCent } = this.spuForm;
    if (!vipPriceCent || isNaN(+vipPriceCent)) {
      return true;
    }
    return +value < +vipPriceCent;
  }

  originalPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { products } = this.spuForm;
    if (products.length) {
      return +this.spuForm.comboTotalPrice.originalPriceCent > 0;
    } else {
      return +value > 0;
    }
  }

  autoOffShelfTimeValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const duration = 60 * 10;
    const now = Math.round(Date.now() / 1000);
    const offShelfTime = Math.round(new Date(value).getTime() / 1000);
    if (offShelfTime - now < duration) {
      this.$message.error('自动下架时间至少设置在10分钟以后');
      return false;
    }
    return true;
  }

  async tempSpuShopIdsValidator(value: string, d: any): Promise<boolean> {
    const list = value.split(',');

    if (!list.length) {
      this.$message.error('适配门店id不能为空');
      return false;
    }
    const _d = {
      shop_ids: value,
      scid: d.id,
    };
    const res = (await this.spuService.leadingSpuCheckShopIds(
      _d,
    )) as unknown as {
      data: string;
      error: string;
      forever: string;
      stop: string;
      num_error: string;
      onsite_error: string;
    };
    const rule = await this.spuService.getRule('store_spu_online_limit');


    if (res && (res.error || res.forever || res.stop || res.num_error || res.onsite_error)) {
      let msg = '适用门店ID不合法，其中：';

      if (res.error) {
        msg += `店铺ID错误（${res.error}）;`;
      }
      if (res.stop) {
        msg += `暂停停业（${res.stop}）`;
      }
      if (res.forever) {
        msg += `永久停业（${res.forever}）`;
      }
      if (res.num_error) {
        msg += `SKU数量大于${rule}个（${res.num_error}）`;
      }
      if (res.onsite_error) {
        msg += `该分类不适用于上门服务门店（${res.onsite_error}）`;
      }
      this.$message.error(msg);
      return false;
    }

    return true;
  }

  async handleSubmit(val: { [key: string]: any }) {
    const {
      category,
      effectiveTimes,
      headlineImage,
      introduction,
      isHot,
      name,
      products,
      images,
      unavailableTime,
      id,
      tempSpuShopIds,
      autoOffShelfTime,
      filterTag,
      remark,
    } = val;
    if (!(await this.tempSpuShopIdsValidator(tempSpuShopIds, category))) {
      return;
    }
    const remarkFilterContent = (str: string) => {
      const regex =
        /[^a-zA-Z0-9\u4e00-\u9fa5\s\u3002\uff0c\uff1b\uff1a\u201c\u201d\uff01\uff1f]+/g;
      return str.replace(regex, '');
    };
    const { originalPriceCent, vipPriceCent, newUserPriceCent } =
      val.comboTotalPrice;

    const spu: { [key: string]: any } = {
      autoOffShelfTime,
      category,
      headlineImage,
      effectiveTimes,
      name,
      unavailableTime,
      products,
      originalPriceCent,
      vipPriceCent,
      newUserPriceCent,
      isHot,
      introduction,
      images,
      tempSpuShopIds,
      note_tag: filterTag || '',
      remark: remark !== '' ? remarkFilterContent(remark) : '',
    };
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    try {
      if (id !== 0) {
        // 编辑商品
        spu.id = id;
        const res = await this.spuService.leadingSpuEdit(id, spu, this.$el);
        if (res) {
          this.$message.success(`${res}`);
          this.spuEditor.close();
          setTimeout(() => {
            this.handleSearch();
          }, 500);
        }
      } else {
        // 新增商品
        const res = await this.spuService.leadingSpuCreate(spu, this.$el);
        if (res) {
          this.$message.success('新增成功');
          this.spuEditor.close();
          setTimeout(() => {
            this.handleSearch();
          }, 500);
        }
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  async handleUpdate(form: { [key: string]: any }, initial?: boolean) {
    let shouldGetIndicativePrice = false;
    const prevCategoryId =
      this.spuForm && this.spuForm.category ? this.spuForm.category.id : 0;

    const duplicate = Object.assign({}, form);

    this.spuForm = { ...duplicate };

    if (initial && initial === true) {
      shouldGetIndicativePrice = this.spuForm.category.id !== 0;
      // 初始化商品
      this.spuEditor.setup(
        await this.initSpuConfig({
          isViewMode: false,
          refreshIndicative: shouldGetIndicativePrice,
        }),
        this.spuForm,
      );
      return;
    }

    // 更新商品属性
    shouldGetIndicativePrice =
      prevCategoryId !== form.category.id && form.category.id !== 0;

    if (!this.spuForm.hasNewUserPrice) {
      this.$set(this.spuForm, ESPUField.NEW_USER_PRICE_CENT, '0.00');
    }
    if (!this.spuForm.isCombo) {
      this.$set(this.spuForm, ESPUField.EFFECTIVE_TIMES, 1);
    }
    if (this.spuForm.products && this.spuForm.products.length) {
      const priceCent = (this.spuForm.products as ISPUProduct[])
        .map(({ originalPriceCent }) => +originalPriceCent)
        .reduce((prev, cur) => prev + cur, 0);

      this.$set(
        this.spuForm,
        ESPUField.ORIGINAL_PRICE_CENT,
        (priceCent / 100).toFixed(2),
      );
    }
    const times = this.spuForm.effectiveTimes * 100;
    const comboTotalPrice = {
      originalPriceCent: Math.round(times * this.spuForm.originalPriceCent),
      vipPriceCent: Math.round(times * this.spuForm.vipPriceCent),
      newUserPriceCent:
        this.spuForm.hasNewUserPrice === true
          ? Math.round(times * this.spuForm.newUserPriceCent)
          : 0,
    };
    this.$set(this.spuForm, ESPUField.COMBO_TOTAL_PRICE, comboTotalPrice);

    this.spuEditor.setup(
      await this.initSpuConfig({
        isViewMode: false,
        refreshIndicative: shouldGetIndicativePrice,
      }),
      this.spuForm,
      true,
    );
  }

  getApplicableShops(ids: string, length: number) {
    const shopIdList = !ids ? [] : ids.split(',');
    if (!shopIdList.length) {
      return [];
    }
    if (shopIdList.length < length) {
      return shopIdList;
    }
    return shopIdList.splice(0, length);
  }

  /** 查询类目参考价：批量商品取全国中位价，无需shopId */
  async queryIndicativePrice(param: { categoryId: number }) {
    return this.spuService.indicativePrice(param);
  }

  async all(params: {
    pageSize: number;
    page: number;
    cate?: number;
    fcate?: number;
    spuName?: string;
    spuId?: number;
    shopId?: number;
    createStartTime?: string;
    createEndTime?: string;
    filterTags?: string;
  }) {
    const res = await this.spuService.leadingSpuList(params, this.$el);

    if (res && res.list) {
      this.listData = [...res.list];
      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: Number(res.pageSize),
        onChange: this.paginationChange,
      };
    }
  }

  filterBySpuCategory() {
    if (Number(this.form.fcate) < 0 || !this.form.fcate) {
      return this.secondCategoryList;
    } else {
      for (const idx of this.allSpuCategory) {
        if (Number(this.form.fcate) === idx.id) {
          return idx.children;
        }
      }
    }
  }

  openLogModal(id: number) {
    this.spuLeadingLog.open(id);
  }

  /** begin 搜索表单 */
  onChangeEditTime(value: Moment[]) {
    if (!value.length) {
      this.form.createStartTime = null;
      this.form.createEndTime = null;
      return;
    }

    this.$set(
      this.form,
      'createStartTime',
      moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
    );
    this.$set(
      this.form,
      'createEndTime',
      moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    );
  }

  onChangeCate(val: number) {
    if (!val) {
      return;
    }

    const allSpuCategory = [...this.allSpuCategory];
    const extractFcate = (all: ISPUCategory[]) => {
      let fcateId;
      for (const item of all) {
        for (const child of item.children!) {
          if (val === child.id) {
            fcateId = item.id;
            break;
          }
        }
      }
      return fcateId;
    };

    this.$set(this.form, 'fcate', extractFcate(allSpuCategory));
    this.filterBySpuCategory();
    this.$set(this.form, 'cate', val);
  }

  onChangeFcate(val: number) {
    this.$set(this.form, 'cate', '');
    if (val) {
      this.$set(this.form, 'fcate', val);
      this.filterBySpuCategory();
    }
  }
  /** end 搜索表单 */

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = this.extractFormParam();
    query.page = `${this.pagination.current}`;
    query.timestamp = Math.round(Date.now() / 1000);

    this.$router.push({
      query,
    });
  }

  /** 翻页 */
  async paginationChange(current: number) {
    this.pagination.current = current;
    const query = await this.extractRouterQueryAndFillForm();
    Object.assign(query, { page: current });

    this.$router.push({ query });
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    this.form = { ...InitFilterValue };
    this.$router.push({
      query: {},
    });
  }

  extractFormParam() {
    const {
      cate,
      fcate,
      spuName,
      spuId,
      shopId,
      createStartTime,
      createEndTime,
      filterTag,
    } = this.form;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {};

    if (spuName) {
      param.spuName = spuName;
    }
    if (spuId) {
      param.spuId = spuId;
    }
    if (shopId) {
      param.shopId = shopId;
    }

    if (createStartTime && createEndTime) {
      param.createStartTime =
        typeof createStartTime === 'string'
          ? createStartTime
          : createStartTime.format('YYYY-MM-DD HH:mm:ss');
      param.createEndTime =
        typeof createEndTime === 'string'
          ? createEndTime
          : createEndTime.format('YYYY-MM-DD HH:mm:ss');
    }
    if (fcate && fcate !== -1) {
      param.fcate = fcate;
    }
    if (cate && cate !== -1) {
      param.cate = cate;
    }
    if (filterTag && filterTag.length) {
      param.filterTag = filterTag.join(',');
    }
    return param;
  }

  async extractRouterQueryAndFillForm(shouldFillForm?: boolean) {
    const {
      cate,
      fcate,
      spuName,
      spuId,
      shopId,
      createStartTime,
      createEndTime,
      filterTag,
    } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {};

    if (spuName) {
      param.spuName = spuName;
      if (shouldFillForm) {
        this.form.spuName = spuName as string;
      }
    }

    if (spuId) {
      param.spuId = spuId;
      if (shouldFillForm) {
        this.form.spuId = spuId as string;
      }
    }

    if (shopId) {
      param.shopId = shopId;
      if (shouldFillForm) {
        this.form.shopId = shopId as string;
      }
    }

    if (fcate) {
      param.fcate = fcate;
      if (shouldFillForm) {
        this.form.fcate = +fcate;
      }
    } else if (shouldFillForm) {
      this.form.fcate = '';
    }

    if (filterTag) {
      param.filterTag = filterTag;
      if (shouldFillForm) {
        const tags = (filterTag as string).split(',');
        await this.onSearchTags(tags[0]);
        this.form.filterTag = (filterTag as string).split(',');
      }
    }

    if (cate) {
      param.cate = cate;

      if (shouldFillForm) {
        this.form.cate = +cate;
      }
    } else if (shouldFillForm) {
      this.form.cate = '';
    }

    if (
      createStartTime &&
      moment(createStartTime as string, moment.ISO_8601).isValid() &&
      createEndTime &&
      moment(createEndTime as string, moment.ISO_8601).isValid()
    ) {
      param.createStartTime = createStartTime;
      param.createEndTime = createEndTime;

      if (shouldFillForm) {
        if (this.form.createStartTime == null) {
          this.form.createStartTime = moment(createStartTime as string);
        } else if (
          this.form.createStartTime.valueOf() !==
          moment(createStartTime as string).valueOf()
        ) {
          this.form.createStartTime = moment(createStartTime as string);
        }

        if (this.form.createEndTime == null) {
          this.form.createEndTime = moment(
            createEndTime as string,
            moment.ISO_8601,
          );
        } else if (
          this.form.createEndTime.valueOf() !==
          moment(createEndTime as string).valueOf()
        ) {
          this.form.createEndTime = moment(createEndTime as string);
        }
      }
    }
    return param;
  }

  async getCategory() {
    const data = await this.spuService.spuCategories({
      aggregation: true,
      loadingEl: this.$el,
    });

    this.allSpuCategory = data;

    this.secondCategoryList = data.reduce((prev, cur) => {
      return prev.concat(cur.children || []);
    }, [] as ISPUCategory[]);
  }
  mounted() {
    const { page } = this.$route.query;
    if (typeof page !== 'undefined') {
      this.pagination.current = +page;
    }
    this.handleSearch();
    this.getCategory();
  }
}
