/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { EAuditProject, EAuditTemplate } from '@/common/enum/audit';
import {
  IResAuditTask,
  IReqSubmitAuditParam,
  IResToAuditTaskBrief,
  IResAuditProject,
  IResAuditTaskBrief,
  IResCityAuditTaskNum,
  IAuditAggregation,
} from '@/common/interface/audit';
import { injectable, inject } from 'inversify';
import HttpClient from './http.service';
import { BASE_AUDIT_URL } from '@/common/constant';
import StorageService from './storage.service';
import AuditTask from '@/models/audit.model';
import SPUService from './spu.service';

@injectable()
export default class AuditService {
  @inject(HttpClient)
  protected http!: HttpClient;

  @inject(StorageService)
  protected storage!: StorageService;

  @inject(SPUService)
  protected spuService!: SPUService;

  /** 获取审核聚合任务 */
  async auditAggregation(
    projectId: string,
    polymerizeId: string,
    loadingEl?: Element | Vue,
  ): Promise<{
    auditData: any;
    auditTaskAggregation: IAuditAggregation[];
  }> {
    const user = this.storage.get('user');
    const userId = user ? user.id : null;
    try {
      const result: {
        auditData: any;
        auditTaskAggregation: IAuditAggregation[];
      } = {
        auditTaskAggregation: [],
        auditData: null,
      };

      const res = (await this.http.get(
        `${BASE_AUDIT_URL}/user/${userId}/project/${projectId}/task/${polymerizeId}`,
        { data: { loadingEl } },
      )) as IResAuditTask;

      if (res) {
        const { auditData, polymerizeTaskUpdateData, template, projectID } =
          res;
        result.auditData = auditData;

        for (const [taskId, v] of Object.entries(polymerizeTaskUpdateData)) {
          const { nodeId, data } = v;

          /** @note 非入驻门店： 聚合任务data为null, 表示是新创建的审核内容。因接口的实现会将新创建的待审核data直接写入到 auditData审核原始数据，故前端需要把auditData[taskId]填充为待审核的data */
          const data_: { [key: string]: any } = data || auditData[taskId];
          const task: {
            nodeId: string;
            taskId: string;
            auditUpdateData: any;
            tplType: EAuditTemplate;
            shouldShowOriginalData: boolean;
          } = {
            nodeId,
            auditUpdateData: AuditTask.from(data_, projectID),
            tplType: template,
            taskId,
            shouldShowOriginalData: projectID !== 3 && data != null,
          };

          if (projectID === EAuditProject.店铺商品审核) {
            // eslint-disable-next-line no-await-in-loop
            const indicativePrice = await this.spuService.indicativePrice({
              categoryId: task.auditUpdateData?.category.id,
              shopId: Number(task.auditUpdateData?.shopId),
              loadingEl,
            });
            if (task.auditUpdateData && indicativePrice) {
              task.auditUpdateData.indicativePrice = indicativePrice || '';
            }
          }

          result.auditTaskAggregation.push(task);
        }
      }

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** 审核类型 done */
  async auditProjects() {
    return (await this.http.get(
      'audit_service/project',
    )) as IResAuditProject[];
  }

  /** 审核列表 done */
  async list(
    params: {
      pageSize?: number;
      page: number;
      name?: string;
      projectId?: number;
      auditStatus?: number;
      createStartTime?: string;
      createEndTime?: string;
      auditStartTime?: string;
      auditEndTime?: string;
    },
    loadingEl: Element | Vue,
  ) {
    try {
      const res = (await this.http.get('audit_service/tasks', {
        params,
        data: { loadingEl },
      })) as {
        list: IResAuditTaskBrief[];
        lastPage: number;
        page: number;
        pageSize: string;
        total: number;
      };
      return res;
    } catch (error) {
      return null;
    }
  }

  /** 审核任务详情 done  isModified后续移除  */
  async task(taskId: number, loadingEl: Element | Vue) {
    try {
      const res = (await this.http.get(`${BASE_AUDIT_URL}/task/${taskId}`, {
        data: { loadingEl },
      })) as {
        projectID: number;
        taskId: number;
        auditNode: Array<{
          auditStatus: number;
          auditNodeId: number;
          auditUserName: string;
          auditTime: string;
        }>;
        template: EAuditTemplate;
        auditData: any;
        auditUpdateData: any;
        createTime: string;
        createUserName: string;
      };

      /** @note 非入驻门店： auditUpdateData 为null, 表示是新创建的审核内容，故前端需要把auditData填充到待审核的 auditUpdateData； */
      if (res) {
        const {
          auditNode,
          template,
          auditData,
          auditUpdateData,
          createTime,
          createUserName,
          projectID,
        } = res;

        const result: {
          taskId: number;
          auditNode: Array<{
            auditStatus: number;
            auditNodeId: number;
            auditUserName: string;
            auditTime: string;
          }>;
          auditData: any;
          tplType: EAuditTemplate;
          auditUpdateData: any;
          createTime: string;
          creator: string;
          shouldShowOriginalData: boolean;
        } = {
          taskId,
          auditNode,
          auditData,
          auditUpdateData: AuditTask.from(
            auditUpdateData != null ? auditUpdateData : auditData,
            projectID,
          ),

          tplType: template,
          createTime,
          creator: createUserName || '未知用户',
          shouldShowOriginalData: projectID !== 3 && auditUpdateData != null,
        };

        if (projectID === EAuditProject.店铺商品审核) {
          const indicativePrice = await this.spuService.indicativePrice({
            categoryId: auditUpdateData
              ? auditUpdateData.category.id
              : auditData.category.id,
            shopId: auditUpdateData
              ? Number(auditUpdateData.shopId)
              : Number(auditData.shopId),
            loadingEl,
          });
          result.auditUpdateData.indicativePrice = indicativePrice || '';
        }

        // console.log('task======', result);

        return result;
      }
    } catch (error) {
      return null;
    }
  }

  /** 待审核列表 done */
  async toAuditList(
    params: {
      pageSize?: number;
      page: number;
      name?: string;
      startTime?: string;
      endTime?: string;
    },
    loadingEl: Element | Vue,
  ) {
    try {
      // const user = this.storage.get('user');
      // const userId = user ? user.id : null;

      const res = (await this.http.get(
        'audit_service/wait-tasks',
        {
          params,
          data: {
            loadingEl,
          },
        },
      )) as {
        list: IResToAuditTaskBrief[];
        lastPage: number;
        page: number;
        pageSize: string;
        total: number;
      };
      return res;
    } catch (error) {
      return null;
    }
  }

  /** 城市待审核任务数 done */
  async cityAuditTaskNums() {
    try {
      // const userStorage = localStorage.getItem('user');
      // const userId = userStorage ? JSON.parse(userStorage).id : null;
      const res = (await this.http.get(
        'audit_service/user/wait-tasks/city',
      )) as IResCityAuditTaskNum[];
      return res;
    } catch (error) {
      throw error;
    }
  }

  /** 提交审核: 包含通过、拒绝 done  */
  async submit(
    param: IReqSubmitAuditParam,
    loadingEl: Element | Vue,
  ): Promise<string> {
    try {
      const userStorage = localStorage.getItem('user');
      const userId = userStorage ? JSON.parse(userStorage).id : null;
      const { auditStatus, auditUpdateData, auditData, auditReason, nodeId } =
        param;
      const submitParam: Omit<IReqSubmitAuditParam, 'nodeId'> & {
        loadingEl: Element | Vue;
      } = {
        auditStatus,
        auditData,
        loadingEl,
      };

      if (typeof auditUpdateData !== 'undefined') {
        // 审核不通过，不会传递此参数
        submitParam.auditUpdateData = auditUpdateData;
      }

      if (auditReason) {
        // 审核不通过原因
        submitParam.auditReason = auditReason;
      }

      const res = (await this.http.post(
        `${BASE_AUDIT_URL}/user/${userId}/task-node/${nodeId}/audit`,
        submitParam,
      )) as string;

      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
