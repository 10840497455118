/*
 * @Author: Src
 * @Date: 2024-07-16 14:41:02
 * @LastEditTime: 2025-03-24 08:57:52
 * @LastEditors: Src
 * @FilePath: /work-station/src/di.ts
 * @Description: 头部注释配置模板
 */
import { Container } from 'inversify';
import getDecorators from 'inversify-inject-decorators';
import 'reflect-metadata';

import AuthService from './services/auth.service';
import HttpClient from './services/http.service';
import StorageService from './services/storage.service';
import UserService from './services/user.service';
import ShopService from './services/shop.service';
import OrderService from './services/order.service';
import PermissionService from './services/permission.service';
import ActivityService from './services/activity.service';
import BaseService from './services/base.service';
import LabelService from './services/label.service';
import AuditService from './services/audit.service';
import { AgentService } from './services/agent.service';
import RegionService from './services/region.service';
import WithdrawService from './services/withdraw.service';
import { AccountService } from './services/account_service';
import SPUService from './services/spu.service';
import ExamineService from './services/examine.service';
import { AgencyOperationService } from './services/agency_operation.service';
import { ShopOperationLogService } from './services/shop_operation_log.service';
import UTILService from './services/utils.service';
import { BrandService } from './services/brand_service';
import SystemService from './services/system.service';
import OperationService from './services/operation.service';
import WorkbenchesService from './services/workbenches.service';


const container = new Container({
  skipBaseClassChecks: true,
});

container.bind<AgentService>(AgentService).to(AgentService);
container
  .bind<AgencyOperationService>(AgencyOperationService)
  .to(AgencyOperationService);
container.bind<AuditService>(AuditService).to(AuditService);
container.bind<AuthService>(AuthService).to(AuthService);
container.bind<HttpClient>(HttpClient).to(HttpClient);
container.bind<StorageService>(StorageService).to(StorageService);
container.bind<UserService>(UserService).to(UserService);
container.bind<ShopService>(ShopService).to(ShopService);
container.bind<OrderService>(OrderService).to(OrderService);
container.bind<PermissionService>(PermissionService).to(PermissionService);
container.bind<ActivityService>(ActivityService).to(ActivityService);
container.bind<BaseService>(BaseService).to(BaseService);
container.bind<LabelService>(LabelService).to(LabelService);
container.bind<RegionService>(RegionService).to(RegionService);
container.bind<AccountService>(AccountService).to(AccountService);
container.bind<WithdrawService>(WithdrawService).to(WithdrawService);
container.bind<SPUService>(SPUService).to(SPUService);
container.bind<ExamineService>(ExamineService).to(ExamineService);
container
  .bind<ShopOperationLogService>(ShopOperationLogService)
  .to(ShopOperationLogService);
container.bind<UTILService>(UTILService).to(UTILService);
container.bind<BrandService>(BrandService).to(BrandService);
container.bind<SystemService>(SystemService).to(SystemService);
container.bind<OperationService>(OperationService).to(OperationService);
container.bind<WorkbenchesService>(WorkbenchesService).to(WorkbenchesService);

const { lazyInject } = getDecorators(container, false);

export default lazyInject;
