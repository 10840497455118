
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class GrowthData extends Vue {
  filterForm: any = {};

  //  部门
  //  组
  //  推广通代运营
  //  考核月份在投门店
  //  考核月份上一个月在投门店
  //  差值
  //  得分
  dataSource = [];
  columns = [
    {
      title: '部门',
      dataIndex: 'city',
      key: 'city',
      align: 'center',
    },
    {
      title: '组',
      dataIndex: 'city',
      key: 'city',
      align: 'center',
    },
    {
      title: '推广通代运营',
      dataIndex: 'city',
      key: 'city',
      align: 'center',
    },
    {
      title: 'X月',
      dataIndex: 'city',
      key: 'city',
      align: 'center',
    },
    {
      title: ' X-1 月',
      dataIndex: 'city',
      key: 'city',
      align: 'center',
    },
    {
      title: '差值',
      dataIndex: 'city',
      key: 'city',
      align: 'center',
    },
    {
      title: '得分',
      dataIndex: 'city',
      key: 'city',
      align: 'center',
    },
  ];
  refresh() {
    console.log('refresh');
  }
  search() {
    console.log('search');
  }
  resetQuery() {
    console.log('resetQuery');
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }
}
