
import { Vue, Component } from 'vue-property-decorator';
import Welcome from '../../../public/welcome.svg';
import manager from '@/common/utils/permission-manager';

@Component({})
export default class Home extends Vue {
  visible = true;

  svg = Welcome;

  start() {
    manager.send(['menu:operation-workbench']).subscribe((permissions: any) => {
      const type = 'menu:operation-workbench';
      if (Object.keys(permissions).length > 0) {
        if (permissions[type]) {
          this.$router.push({
            name: 'workbenches.index',
          });
        } else {
          this.$router.push({
            name: 'main.shop.list',
          });
        }
      }
      // name: 'workbenches.index',
      // name: 'main.shop.list',
    });
  }
}
