
import { BASE_URL } from '@/common/constant';
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import SPUService from '@/services/spu.service';
import BatchDeletePois from '../shop/components/BatchDeletePois.vue';
import { SPUFieldFactory } from '@/models/spuFieldFactory';
import {
  IResPlatformSPU,
  ISPUCategory,
  ISPUField,
  ISPUProduct,
} from '@/common/interface/spu';

import { PlatformSPU } from '@/models/spu.model';
import { ESPUField, ENProductSource } from '@/common/enum/spu';
import SPUEditor from '@/components/spu/SPUEditor.vue';
import _ from 'lodash';
import { KeyObject } from '@/common/interface';
import PermissionService, { AuthEnum } from '@/services/permission.service';

@Component({
  components: {
    BatchDeletePois,
    SPUEditor,
  },
})
export default class SpuPlatform extends Vue {
  AuthEnum = AuthEnum;

  BASE_URL = BASE_URL;

  spuVideoPermmison: KeyObject<boolean> = {};

  form: {
    name: string;
    spuId: string;
    categoryId?: string | number;
  } = {
    name: '',
    spuId: '',
    categoryId: '',
  };

  listData: IResPlatformSPU[] = [];

  ENProductSource = ENProductSource;

  pagination = {
    total: 0,
    pageSize: 20,
    current: 1,
    onChange: this.paginationChange,
  };

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: '商品名称',
      dataIndex: 'name',
      key: 'name',
      width: 240,
    },
    {
      title: '商品类目',
      dataIndex: 'scategory',
      key: 'scategory',
      scopedSlots: { customRender: 'scategory' },
      width: 80,
    },
    {
      title: '商品照片',
      dataIndex: 'headlineImage',
      key: 'headlineImage',
      scopedSlots: { customRender: 'headlineImage' },
      width: 80,
    },
    {
      title: '门市价',
      dataIndex: 'originalPriceCent',
      scopedSlots: { customRender: 'originalPriceCent' },
      width: 100,
    },
    {
      title: '黑卡价',
      dataIndex: 'vipPriceCent',
      key: 'vipPriceCent',
      scopedSlots: { customRender: 'vipPriceCent' },
      width: 100,
    },
    {
      title: '服务详情',
      dataIndex: 'introduction',
      key: 'introduction',
      scopedSlots: { customRender: 'introduction' },
      width: 160,
    },
    {
      title: '商品相册',
      dataIndex: 'images',
      key: 'images',
      scopedSlots: { customRender: 'images' },
      width: 120,
    },
    {
      title: '适用门店',
      dataIndex: 'tempSpuShopPois',
      key: 'tempSpuShopPois',
      scopedSlots: { customRender: 'tempSpuShopPois' },
      width: 120,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      scopedSlots: { customRender: 'createTime' },
      width: 180,
    },
    {
      title: '操作',
      dataIndex: 'actions',
      fixed: 'right',
      key: 'actions',
      scopedSlots: { customRender: 'actions' },
      width: 120,
    },
  ];

  /** 分类数据 */
  secondCategoryList: ISPUCategory[] = [];

  spuConfig: ISPUField[] = [];

  initSpuFields: ISPUField[] = SPUFieldFactory.init({
    spuType: 'platformSpu',
    vipPriceValidator: this.vipPriceValidator,
    originalPriceValidator: this.originalPriceValidator,
    newUserPriceValidator: this.newUserPriceValidator,
  });

  editingSpu: PlatformSPU = new PlatformSPU();

  spuForm: { [key: string]: any } = {};

  @Ref()
  batchDeletePois!: BatchDeletePois;

  @Ref()
  spuEditor!: SPUEditor;

  @lazyInject(ShopService)
  shopService!: ShopService;

  @lazyInject(PermissionService)
  protected permissionService!: PermissionService;

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { name, spuId, categoryId } = this.$route.query;
    const { pageSize, current } = this.pagination;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {
      pageSize,
      page: current,
    };

    if (name) {
      this.form.name = name as string;
      param.name = name;
    }
    if (spuId) {
      this.form.spuId = spuId as string;
      param.spuId = spuId;
    }
    if (categoryId) {
      this.form.categoryId = +categoryId;
      param.categoryId = categoryId;
    }

    await this.all(param);
  }

  getApplicableShops(poiids: string, length: number) {
    const poiList = !poiids ? [] : poiids.split(',');
    if (!poiList.length) {
      return [];
    }
    if (poiList.length < length) {
      return poiList;
    }
    return poiList.splice(0, length);
  }

  async getCategory() {
    const data = await this.spuService.spuCategories({
      aggregation: true,
      loadingEl: this.$el,
    });
    this.secondCategoryList = data.reduce((prev, cur) => {
      return prev.concat(cur.children || []);
    }, [] as ISPUCategory[]);
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    const { name, spuId, categoryId } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: current,
    };
    if (name) {
      query.name = name;
    }

    if (spuId) {
      query.spuId = spuId;
    }
    if (categoryId) {
      query.categoryId = categoryId;
    }

    this.$router.push({
      query,
    });
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    const { name, spuId, categoryId } = this.form;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: `${this.pagination.current}`,
    };
    if (name) {
      query.name = name;
    }

    if (spuId) {
      query.spuId = spuId;
    }

    if (categoryId) {
      query.categoryId = categoryId;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  /** 页面刷新 */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    this.form = {
      name: '',
      spuId: '',
      categoryId: '',
    };
    this.$router.push({
      query: {},
    });
  }

  async all(params: {
    pageSize: number;
    page: number;
    name?: string;
    spuId?: string;
    categoryId?: number;
  }) {
    const res = await this.spuService.platformSpuList(params, this.$el);

    if (res) {
      this.listData = res.list;
      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: Number(res.pageSize),
        onChange: this.paginationChange,
      };
    }
  }

  async changeOnlineStatus(spuId: string, isOnline: boolean) {
    const res = await this.spuService.platformSpuOnlineStatusChange(
      spuId,
      !isOnline,
      this.$el,
    );
    if (res) {
      this.$message.success(res);
      setTimeout(() => {
        this.handleSearch();
      }, 500);
    }
  }

  async deleteSpu(spu: IResPlatformSPU) {
    const res = await this.spuService.platformSpuDelete(`${spu.id}`, this.$el);
    if (res) {
      this.$message.success('删除成功');
      setTimeout(() => {
        this.handleSearch();
      }, 500);
    }
  }

  openBatchDeletePoisModal() {
    this.batchDeletePois.visible = true;
  }

  vipPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { originalPriceCent, products } = this.spuForm;

    if (products.length) {
      return +value < +this.spuForm.comboTotalPrice.originalPriceCent;
    } else {
      if (+value > 0 && (!originalPriceCent || isNaN(+originalPriceCent))) {
        return true;
      }
      return +value < +originalPriceCent;
    }
  }

  newUserPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { vipPriceCent } = this.spuForm;
    if (!vipPriceCent || isNaN(+vipPriceCent)) {
      return true;
    }
    return +value < +vipPriceCent;
  }

  originalPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { products } = this.spuForm;
    if (products.length) {
      return +this.spuForm.comboTotalPrice.originalPriceCent > 0;
    } else {
      return +value > 0;
    }
  }

  /** 新增商品 */
  add() {
    this.editingSpu = new PlatformSPU();
    this.initSpuForm(this.editingSpu);
  }

  /** 修改商品 */
  async edit(id: number) {
    const data = await this.spuService.platformSpuDetail(`${id}`);
    if (data) {
      this.editingSpu = PlatformSPU.from(data);
      this.initSpuForm(this.editingSpu);
    }
  }

  initSpuForm(editingSpu: PlatformSPU) {
    const form: { [key: string]: any } = {};
    const times =
      editingSpu.effectiveTimes > 0 ? editingSpu.effectiveTimes * 100 : 100;

    for (const [key, value] of Object.entries(editingSpu)) {
      switch (key) {
        case ESPUField.EFFECTIVE_TIMES: {
          if (Number(value) > 1) {
            form.isCombo = true;
          } else {
            form.isCombo = false;
          }
          form[key] = value;
          break;
        }
        case ESPUField.ORIGINAL_PRICE_CENT: {
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }
        case ESPUField.VIP_PRICE_CENT: {
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }
        case ESPUField.NEW_USER_PRICE_CENT: {
          form.hasNewUserPrice = value > 0;
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }

        default: {
          form[key] = value;
          break;
        }
      }
    }

    form.comboTotalPrice = {
      originalPriceCent: editingSpu.originalPriceCent,
      vipPriceCent: editingSpu.vipPriceCent,
      newUserPriceCent: editingSpu.newUserPriceCent,
    };

    this.handleUpdate(form, true);
  }

  initSpuConfig() {
    const spu = { ...this.spuForm };
    const detail = _.cloneDeep(this.initSpuFields);
    const isComboIndex = detail.findIndex(
      (item) => item.label === ESPUField.IS_COMBO,
    );
    const idIdx = detail.findIndex((item) => item.label === ESPUField.ID);

    const originalPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.ORIGINAL_PRICE_CENT,
    );
    const vipPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.VIP_PRICE_CENT,
    );
    const newUserPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.NEW_USER_PRICE_CENT,
    );
    const comboTotalPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.COMBO_TOTAL_PRICE,
    );
    const effectiveTimesIdx = detail.findIndex(
      (item) => item.label === ESPUField.EFFECTIVE_TIMES,
    );
    const videosIdx = detail.findIndex(
      (item) => item.label === ESPUField.VIDEOS,
    );

    if (spu.id === 0) {
      // 新增商品时允许更换是否套餐
      detail[isComboIndex].readonly = false;
      detail[idIdx].isHidden = true;
    } else {
      // 编辑商品不允许更改是否套餐的选项
      detail[isComboIndex].readonly = true;
      detail[idIdx].isHidden = false;
    }

    if (spu.isCombo === true) {
      detail[effectiveTimesIdx].isHidden = false;
      detail[comboTotalPriceIdx].isHidden = false;
      detail[originalPriceIdx].labelName = '单次门市价(元)';
      detail[vipPriceIdx].labelName = '单次黑卡价(元)';
      detail[newUserPriceIdx].labelName = '单次特价(元)';
    } else {
      detail[effectiveTimesIdx].isHidden = true;
      detail[comboTotalPriceIdx].isHidden = true;
      detail[originalPriceIdx].labelName = '门市价(元)';
      detail[vipPriceIdx].labelName = '黑卡价(元)';
      detail[newUserPriceIdx].labelName = '特价(元)';
    }

    if (spu.hasNewUserPrice === true) {
      detail[newUserPriceIdx].isHidden = false;
    } else {
      detail[newUserPriceIdx].isHidden = true;
    }

    if (
      this.spuVideoPermmison &&
      this.spuVideoPermmison[AuthEnum.标品视频管理]
    ) {
      detail[videosIdx].isHidden = false;
    } else {
      detail[videosIdx].isHidden = true;
    }

    return detail;
  }

  async handleSubmit(val: { [key: string]: any }) {
    const {
      category,
      effectiveTimes,
      introduction,
      isHot,
      name,
      products,
      images,
      unavailableTime,
      id,
      shortComment,
      isDrawCommission,
      tempSpuShopPois,
      platFlag,
      videos,
      headlineImages,
      commissionRatio,
    } = val;
    const { originalPriceCent, vipPriceCent, newUserPriceCent } =
      val.comboTotalPrice;
    const spu: { [key: string]: any } = {
      category,
      effectiveTimes,
      name,
      unavailableTime,
      products,
      originalPriceCent,
      vipPriceCent,
      newUserPriceCent,
      isHot,
      introduction,
      images,
    };
    if (videos.length) {
      spu.videos = videos;
    }
    spu.headlineImage = headlineImages.shift() || { id: 0, url: '' };
    spu.headlineOtherImage = [...headlineImages];
    spu.platformProduct = {
      commissionRatio,
      isCopyPlatformProduct: 0,
      isDrawCommission: isDrawCommission === true ? 1 : 0,
      isPlatformProduct: platFlag === 1 ? 1 : 0,
      shortComment: shortComment || '',
      tempSpuShopPois: tempSpuShopPois || '',
    };
    if (id !== 0) {
      // 编辑商品
      spu.id = id;
      const res = await this.spuService.platformSpuEdit(spu.id, spu, this.$el);
      if (!res) {
        return;
      }
      this.$message.success('修改成功');
      await this.handleSearch();
      this.spuEditor.close();
    } else {
      // 新增商品
      const res = await this.spuService.platformSpuCreate(spu, this.$el);
      if (!res) {
        return;
      }
      this.$message.success('新增成功');
      await this.handleSearch();
      this.spuEditor.close();
    }
  }

  async handleUpdate(form: { [key: string]: any }, initial?: boolean) {
    this.spuForm = { ...form };

    if (initial && initial === true) {
      // 初始化商品
      this.spuEditor.setup(this.initSpuConfig(), this.spuForm);
      return;
    }

    // 更新商品属性
    if (!this.spuForm.hasNewUserPrice) {
      this.$set(this.spuForm, ESPUField.NEW_USER_PRICE_CENT, '0.00');
    }
    if (!this.spuForm.isCombo) {
      this.$set(this.spuForm, ESPUField.EFFECTIVE_TIMES, 1);
    }
    if (this.spuForm.products && this.spuForm.products.length) {
      const priceCent = (this.spuForm.products as ISPUProduct[])
        .map(({ originalPriceCent }) => +originalPriceCent)
        .reduce((prev, cur) => prev + cur, 0);

      this.$set(
        this.spuForm,
        ESPUField.ORIGINAL_PRICE_CENT,
        (priceCent / 100).toFixed(2),
      );
    }
    const times = this.spuForm.effectiveTimes * 100;
    const comboTotalPrice = {
      originalPriceCent: Math.round(times * this.spuForm.originalPriceCent),
      vipPriceCent: Math.round(times * this.spuForm.vipPriceCent),
      newUserPriceCent:
        this.spuForm.hasNewUserPrice === true
          ? Math.round(times * this.spuForm.newUserPriceCent)
          : 0,
    };
    this.$set(this.spuForm, ESPUField.COMBO_TOTAL_PRICE, comboTotalPrice);
    this.spuEditor.setup(this.initSpuConfig(), this.spuForm, true);
  }

  mounted() {
    const { page } = this.$route.query;
    if (typeof page !== 'undefined') {
      this.pagination.current = +page;
    }
    this.handleSearch();
    this.getCategory();
  }

  async created() {
    const $asyncP = await this.permissionService.asyncPermissions([
      AuthEnum.标品视频管理,
    ]);
    $asyncP.subscribe((permissions: KeyObject<boolean>) => {
      if (permissions) {
        this.spuVideoPermmison = permissions;
      } else {
        this.spuVideoPermmison = {};
      }
    });
  }
}
