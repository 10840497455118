
import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { ENProductSource } from '@/common/enum/spu';
import LogDrawer from './components/LogDrawer.vue';
import lazyInject from '@/di';
import { ShopOperationLogService } from '@/services/shop_operation_log.service';
import SPUService from '@/services/spu.service';

const OperationTypeMap = new Map([
  [ENProductSource.BRAND_STANDARD, '品牌标品'],
  [ENProductSource.PLATFORM_STANDARD, '平台标品'],
  [ENProductSource.GENERAL_MERCHANDISE, '普通商品'],
  [ENProductSource.BATCH_PRODUCTS, '批量商品'],
]);

@Component({
  components: {
    LogDrawer,
  },
})
export default class ProductLog extends Vue {
  OperationTypeMap = OperationTypeMap;

  @Ref()
  logDrawer!: LogDrawer;

  @lazyInject(ShopOperationLogService)
  operationLogService!: ShopOperationLogService;

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  columns = [
    {
      title: '时间',
      dataIndex: 'operateTime',
      key: 'operateTime',
      align: 'center',
      ellipsis: true,
    },
    {
      title: '操作人',
      dataIndex: 'operatorName',
      key: 'operatorName',
      align: 'center',
      ellipsis: true,
    },
    {
      title: '操作人工号',
      dataIndex: 'operatorId',
      key: 'operatorId',
      align: 'center',
      ellipsis: true,
    },
    {
      title: '操作内容',
      dataIndex: 'address',
      key: 'operation',
      align: 'center',
      scopedSlots: { customRender: 'operation' },

    },
  ];

  data: any = [

  ];

  title = '批量删除商品';

  type: any = '';

  @Watch('$route')
  async handleSearch() {
    const { pageSize, current } = this.pagination;
    const param = {
      pageSize,
      page: current,
    };
    await this.getList(param);
  }

  lookLog(val: any) {
    console.log(val);
    this.logDrawer.init(val.content, val.id);
  }

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };

  paginationChange(current: number) {
    this.pagination.current = current;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = { page: `${current}` };

    this.$router.push({
      query,
    });
  }

  mounted() {
    const { id, name, type } = this.$route.params;
    this.type = type;
    const text = `${this.OperationTypeMap.get(this.type)}: ${name}(商品ID: ${id}): 操作日志`;
    this.title = text;
    const parm = {
      pageSize: 10,
      page: 1,
    };
    this.getList(parm);
  }

  async getList(param: { pageSize: number; page: number }) {
    console.log(this.type);
    let operate_type = 'spuHistory';
    if (this.type === ENProductSource.BATCH_PRODUCTS) {
      operate_type = 'batchTemplate';
    } else {
      operate_type = 'spuHistory';
    }
    const { pageSize, page } = param;
    const reqParam = {
      page_size: pageSize || 10,
      page: page || 1,
      operate_type,
      // ext_idx: 658499,
      ext_idx: Number(this.$route.params.id),
    };
    const res = await this.operationLogService.list(reqParam);
    console.log(res);
    if (res) {
      this.data = res.list;
      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: Number(res.pageSize),
        onChange: this.paginationChange,
      };
    }
  }
}
