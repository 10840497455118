/*
 * @Author: Src
 * @Date: 2025-03-14 10:05:38
 * @LastEditTime: 2025-03-28 09:41:11
 * @LastEditors: Src
 * @FilePath: /work-station/src/router/workbenchesRouter.ts
 * @Description: 头部注释配置模板
 */

import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import Workbenches from '@/views/workbenches/index.vue';

import MergePayment from '@/views/workbenches/page/MergePayment.vue';
import MinisterialPerformance from '@/views/workbenches/page/MinisterialPerformance.vue';
import OperationalPerformance from '@/views/workbenches/page/OperationalPerformance.vue';
import OperationalPerformanceLeader from '@/views/workbenches/page/OperationalPerformanceLeader.vue';
import PromoteCollision from '@/views/workbenches/page/PromoteCollision.vue';
import PromotionCompletion from '@/views/workbenches/page/PromotionCompletion.vue';
import PromotionOfBilling from '@/views/workbenches/page/PromotionOfBilling.vue';
import ReportForms from '@/views/workbenches/page/ReportForms.vue';
import ShouldEnrich from '@/views/workbenches/page/ShouldEnrich.vue';
import MonthlyPerformance from '@/views/workbenches/page/MonthlyPerformance.vue';
import GrowthData from '@/views/workbenches/page/GrowthData.vue';
import ShouldBeCharged from '@/views/workbenches/page/ShouldBeCharged.vue';
import CollisionLineStore from '@/views/workbenches/page/CollisionLineStore.vue';
import MergePaymentIng from '@/views/workbenches/page/MergePaymentIng.vue';
import StoreConsumed from '@/views/workbenches/page/StoreConsumed.vue';
import DepartmentPage from '@/views/workbenches/page/DepartmentPage.vue';

const workbenchesRouter: RouteConfig = {
  path: '/workbenches',
  component: Main,
  redirect: {
    name: 'workbenches.index',
  },
  meta: {
    title: '工作台',
  },
  children: [
    {
      name: 'workbenches.index',
      path: '/workbenches/index',
      component: Workbenches,
      meta: {
        title: '运营工作台',
      },
    },
    {
      name: 'workbenches.mergePayment',
      path: '/workbenches/mergePayment',
      component: MergePayment,
      meta: {
        title: '合并支付投放数据',
      },
    },
    {
      name: 'workbenches.ministerialPerformance',
      path: '/workbenches/ministerialPerformance',
      component: MinisterialPerformance,
      meta: {
        title: '部长绩效',
      },
    },
    {
      name: 'workbenches.operationalPerformance',
      path: '/workbenches/operationalPerformance',
      component: OperationalPerformance,
      meta: {
        title: '运营绩效',
      },
    },
    {
      name: 'workbenches.operationalPerformanceLeader',
      path: '/workbenches/operationalPerformanceLeader',
      component: OperationalPerformanceLeader,
      meta: {
        title: '组长管理绩效',
      },
    },
    {
      name: 'workbenches.promoteCollision',
      path: '/workbenches/promoteCollision',
      component: PromoteCollision,
      meta: {
        title: '推广通撞线数据',
      },
    },
    {
      name: 'workbenches.promotionCompletion',
      path: '/workbenches/promotionCompletion',
      component: PromotionCompletion,
      meta: {
        title: '推广通完成率',
      },
    },
    {
      name: 'workbenches.promotionOfBilling',
      path: '/workbenches/promotionOfBilling',
      component: PromotionOfBilling,
      meta: {
        title: '推广通开单数据',
      },
    },
    {
      name: 'workbenches.reportForms',
      path: '/workbenches/reportForms',
      component: ReportForms,
      meta: {
        title: '部长推广通人效',
      },
    },
    {
      name: 'workbenches.shouldEnrich',
      path: '/workbenches/shouldEnrich',
      component: ShouldEnrich,
      meta: {
        title: '应充实充数据',
      },
    },
    {
      name: 'workbenches.monthlyPerformance',
      path: '/workbenches/monthlyPerformance',
      component: MonthlyPerformance,
      meta: {
        title: '月度业绩统计',
      },
    },
    {
      name: 'workbenches.growthData',
      path: '/workbenches/growthData',
      component: GrowthData,
      meta: {
        title: '在投门店增长数据',
      },
    },
    {
      name: 'workbenches.ShouldBeCharged',
      path: '/workbenches/ShouldBeCharged',
      component: ShouldBeCharged,
      meta: {
        title: '应充商家',
      },
    },
    {
      name: 'workbenches.CollisionLineStore',
      path: '/workbenches/CollisionLineStore',
      component: CollisionLineStore,
      meta: {
        title: '撞线门店',
      },
    },
    {
      name: 'workbenches.MergePaymentIng',
      path: '/workbenches/MergePaymentIng',
      component: MergePaymentIng,
      meta: {
        title: '合并支付待投放',
      },
    },
    {
      name: 'workbenches.StoreConsumed',
      path: '/workbenches/StoreConsumed',
      component: StoreConsumed,
      meta: {
        title: '待消耗门店',
      },
    },
    {
      name: 'workbenches.DepartmentPage',
      path: '/workbenches/monthlyPerformance/DepartmentPage',
      component: DepartmentPage,
      meta: {
        title: '月度部门详情',
      },
    },
  ],
};

export default workbenchesRouter;
