/*
 * @Author: Src
 * @Date: 2024-07-16 14:41:02
 * @LastEditTime: 2025-02-12 09:29:34
 * @LastEditors: Src
 * @FilePath: /work-station/src/router/spuRoutes.ts
 * @Description: 头部注释配置模板
 */
import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import SpuKa from '@/views/spu/SpuKa.vue';
import SpuPlatform from '@/views/spu/SpuPlatform.vue';
import SpuLeading from '@/views/spu/SpuLeading.vue';
import SpuServices from '@/views/spu/SpuServices.vue';
import SpuDefaultServices from '@/views/spu/SpuDefaultServices.vue';
import LogList from '@/views/spu/LogList.vue';
import ClassificationList from '@/views/spu/ClassificationList.vue';
import ProductDeletion from '@/views/spu/ProductDeletion.vue';
import ProductLog from '@/views/spu/ProductLog.vue';

const spuRoutes: RouteConfig = {
  name: 'spu',
  path: '/spu',
  component: Main,
  redirect: {
    name: 'spu.platform',
  },
  meta: {
    title: '商品管理',
  },
  children: [
    {
      name: 'spu.platform',
      path: '/spu/platform',
      component: SpuPlatform,
      meta: {
        title: '平台标准商品',
      },
    },
    {
      name: 'spu.ka',
      path: '/spu/ka',
      component: SpuKa,
      meta: {
        title: '品牌标准商品',
      },
    },
    {
      name: 'spu.leading',
      path: '/spu/leading',
      component: SpuLeading,
      meta: {
        title: '批量操作商品',
      },
    },
    {
      name: 'spu.services',
      path: '/spu/services',
      component: SpuServices,
      meta: {
        title: '商品服务详情属性管理',
      },
    },
    {
      name: 'spu.defaultServices',
      path: '/spu/defaultServices',
      component: SpuDefaultServices,
      meta: {
        title: '商品服务详情默认属性管理',
      },
    },
    {
      name: 'spu.logList',
      path: '/spu/logList/:id/:type',
      component: LogList,
      meta: {
        title: '商品服务详情日志',
      },
    },
    {
      name: 'spu.classificationList',
      path: '/spu/classificationList',
      component: ClassificationList,
      meta: {
        title: '分类属性管理',
      },
    },
    {
      name: 'spu.product.deletion',
      path: '/spu/productDeletion/list',
      component: ProductDeletion,
      meta: {
        title: '商品删除记录',
      },
    },
    {
      name: 'spu.productLog',
      path: '/spu/productLog/:id/:name/:type',
      component: ProductLog,
      meta: {
        title: '商品日志详情',
      },
    },
  ],
};

export default spuRoutes;
