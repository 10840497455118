
import { Vue, Component, Ref } from 'vue-property-decorator';
import {
  ISPUCategory,
} from '@/common/interface/spu';
import {
  City,
} from '@/common/interface/shop';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import ShopService from '@/services/shop.service';
import { FormModel } from 'ant-design-vue';

enum ESelectedStatus {
  全部 = 0,
  选中 = 1,
  未选中 = 2,
}

@Component
export default class GoodsModal extends Vue {
  ruleForm: any = {
    categoryId: '',
    regionid: '',
    regionpid: '',
    regionId: '',
    ka_id: '',
    scid: '',
    shop_name: '',
    service_name: '',
    shop_id: '',
    poi: '',
    name: '',
    pick: 0,
    spu_ids: '',
    spu_id: '',
  };

  ESelectedStatus = ESelectedStatus;

  selectedStatausList: ESelectedStatus[] = [
    ESelectedStatus.全部,
    ESelectedStatus.选中,
    ESelectedStatus.未选中,
  ];

  citys: any[] = [];

  /** 分类数据 */
  secondCategoryList: ISPUCategory[] = [];

  allSpuCategory: ISPUCategory[] = [];

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @lazyInject(ShopService)
  protected shopService!: ShopService;

  @Ref()
  formRule!: FormModel;

  visible = false;

  columns = [
    {
      title: '商品ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      scopedSlots: { customRender: 'id' },
    },
    {
      title: '门店POI',
      dataIndex: 'poi',
      key: 'poi',
      align: 'center',
    },
    {
      title: '门店名称',
      dataIndex: 'shop_name',
      key: 'shop_name',
      align: 'center',
    },
    {
      title: '城市',
      dataIndex: 'cityName',
      key: 'cityName',
      align: 'center',
    },
    {
      title: '商品名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '商品分类',
      dataIndex: 'scid',
      key: 'scid',
      align: 'center',
    },
    {
      title: '门市价',
      dataIndex: 'ormb',
      key: 'ormb',
      align: 'center',
    },
    {
      title: '黑卡价',
      dataIndex: 'rmb',
      key: 'rmb',
      align: 'center',
      scopedSlots: { customRender: 'operation' },
    },
  ];

  data = [];

  selectedRowKeys: number[] = [];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };


  async getCategory() {
    const data = await this.spuService.spuCategories({
      aggregation: true,
      // loadingEl: this.$el,
    });

    this.allSpuCategory = data;

    this.secondCategoryList = data.reduce((prev, cur) => {
      return prev.concat(cur.children || []);
    }, [] as ISPUCategory[]);
  }
  paginationChange(current: number) {
    this.pagination.current = current;
    const { pageSize } = this.pagination;
    const params = {
      pageSize,
      page: current,
    };
    this.getSpuBatList(this.ruleForm, params);
  }

  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.shopService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;
    } else {
      this.citys = [];
    }
  }

  getCheckboxProps(record: any) {
    return {
      props: {
        checked: this.selectedRowKeys.includes(record.id),
      },
    };
  }

  /** 省市过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  submitForm() {
    console.log(this.ruleForm);
    const { pageSize } = this.pagination;
    const params = {
      pageSize,
      page: 1,
    };
    this.pagination.current = 1;
    this.getSpuBatList(this.ruleForm, params);
  }
  resetForm() {
    this.formRule.resetFields();
    const params = {
      pageSize: 10,
      page: 1,
    };
    this.pagination.current = 1;
    this.getSpuBatList(this.ruleForm, params);
  }

  diff(original: number[], current: number[]) {
    const intersection = current.filter((item) => original.indexOf(item) > -1);
    const toAdd = current.filter((item) => intersection.indexOf(item) === -1);
    return ([] as number[]).concat(intersection, toAdd);
  }

  handleRowChange(selectedRowKeys: number[]) {
    const newKeys = this.diff(this.selectedRowKeys, selectedRowKeys);
    this.selectedRowKeys = [...newKeys];
  }

  init(kaID: any, spuIds: any) {
    this.getCategory();
    this.getCityList();
    this.ruleForm.ka_id = kaID;
    const page = {
      pageSize: 10,
      page: 1,
    };
    console.log(spuIds);
    this.pagination.current = 1;
    this.ruleForm.spu_ids = spuIds;
    this.selectedRowKeys = spuIds ? spuIds.split(',').map((item: any) => {
      return Number(item);
    }) : [];
    this.getSpuBatList(this.ruleForm, page);
  }

  async getSpuBatList(data: any, pages: any) {
    const { pageSize, page } = pages;
    const pageNumber = {
      page_size: pageSize,
      page,
    };

    const s = await this.spuService.getSpuBat(data, pageNumber);
    console.log(s);
    if (s) {
      const list: any = s.list.map((item: any) => {
        const cityTarget = this.citys.find((city) => city.id === item.regionid);
        return {
          id: item.id,
          poi: item.poi,
          shop_name: item.shop_name,
          regionid: item.regionid,
          regionpid: item.regionpid,
          ka_id: item.ka_id,
          name: item.name,
          scid: item.scid,
          ormb: item.ormb,
          rmb: item.rmb,
          online: 1,
          cityName: cityTarget ? `${cityTarget.name}` : '',
        };
      });
      this.data = list;
      this.visible = true;
      this.pagination.total = s.total;
    }
  }
  handleOk() {
    this.visible = false;
    console.log(this.selectedRowKeys);
    this.formRule.resetFields();
    this.data = [];
    this.$emit('updateGoods', this.selectedRowKeys);
    this.selectedRowKeys = [];
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
  }
  handleCancel() {
    this.visible = false;
    this.formRule.resetFields();
    this.data = [];
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
  }
}
