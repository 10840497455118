
import { Vue, Component, Ref } from 'vue-property-decorator';
import lazyInject from '@/di';
import UserService from '@/services/user.service';
import { FormModel } from 'ant-design-vue';
import AuthService, { Auth } from '@/services/auth.service';
import { validatePassword } from '@/common/utils';

@Component({})
export default class Header extends Vue {
  @Ref()
  ruleForm!: FormModel;

  @lazyInject(UserService)
  user!: UserService;


  @lazyInject(AuthService)
  authService!: AuthService;

  visible = false;

  auth: Auth = new Auth();

  typeFocus = false;
  ptype = false;
  patype = false;

  form: {
    password: any;
    passwordAgain: number | string;
  } = {
    password: '',
    passwordAgain: '',
  };

  rules = {

    password: [
      { required: true, message: '请输入新密码' },
      { validator: this.funPassword },
    ],
    passwordAgain: [
      { required: true, message: '请输入新密码' },
      { validator: this.funPasswordAgin },
    ],
  };

  loading = false;


  get userName(): string | undefined {
    return this.user.currentUser()?.name || this.user.currentUser()?.phone;
  }

  logout() {
    this.$confirm({
      title: '确定要退出登陆吗？',
      onOk: () => this.user.logout(),
      onCancel: () => {},
    });
  }

  handleFocus() {
    this.typeFocus = !this.typeFocus;
  }

  funPassword(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
    callback: (err?: string) => void,
  ) {
    const { isPass, message } = validatePassword(value);
    if (!isPass) {
      // eslint-disable-next-line standard/no-callback-literal
      callback(message);
    } else {
      callback();
    }
  }
  funPasswordAgin(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
    callback: (err?: string) => void,
  ) {
    console.log(this.form.password, value);
    const { isPass, message } = validatePassword(value);
    if (!isPass) {
      // eslint-disable-next-line standard/no-callback-literal
      callback(message);
    } else if (this.form.password !== value) {
      // eslint-disable-next-line standard/no-callback-literal
      callback('两次密码不一致');
    } else {
      callback();
    }
  }
  handleOk() {
    this.ruleForm.validate(async (valid) => {
      if (valid) {
        try {
          this.auth.password = this.form.password;
          const result = await this.authService.resetPassword(this.auth);
          console.log(result);
          this.$message.success('修改成功');
          this.ruleForm.resetFields();
          this.ptype = false;
          this.patype = false;
          this.visible = false;
        } catch (error) {
          // this.$message.error(`${error}`);
    }
      }
    });
  }
  close() {
    this.ruleForm.resetFields();
    this.ptype = false;
    this.patype = false;
    this.visible = false;
  }
}
