
import { Vue, Component, Prop } from 'vue-property-decorator';
// import SPUAudit from '@/views/audit/components/SPUAudit.vue';
import { ESPUField } from '@/common/enum/spu';
import { ISPUField } from '@/common/interface/spu';
import AutoOffShelfTimeField from '@/components/spu/fields/AutoOffShelfTimeField.vue';
import CategoryField from '@/components/spu/fields/CategoryField.vue';
import ComboTotalPriceField from '@/components/spu/fields/ComboTotalPriceField.vue';
import CommissionRatioField from '@/components/spu/fields/CommissionRatioField.vue';
import EffectiveTimesField from '@/components/spu/fields/EffectiveTimesField.vue';
import FilterTagField from '@/components/spu/fields/FilterTagField.vue';
import HasNewUserPriceField from '@/components/spu/fields/HasNewUserPriceField.vue';
import HeadlineImageField from '@/components/spu/fields/HeadlineImageField.vue';
import HeadlineImagesField from '@/components/spu/fields/HeadlineImagesField.vue';
import IdField from '@/components/spu/fields/IdField.vue';
import ImagesField from '@/components/spu/fields/ImagesField.vue';
import IntroductionField from '@/components/spu/fields/IntroductionField.vue';
import IsAutoOffShelfField from '@/components/spu/fields/IsAutoOffShelfField.vue';
import IsComboField from '@/components/spu/fields/IsComboField.vue';
import IsDrawCommissionField from '@/components/spu/fields/IsDrawCommissionField.vue';
import IsHotField from '@/components/spu/fields/IsHotField.vue';
import KaField from '@/components/spu/fields/KaField.vue';
import NameField from '@/components/spu/fields/NameField.vue';
import NewUserPriceCentField from '@/components/spu/fields/NewUserPriceCentField.vue';
import OriginalPriceCentField from '@/components/spu/fields/OriginalPriceCentField.vue';
import ProductsField from '@/components/spu/fields/ProductsField.vue';
import RemarkField from '@/components/spu/fields/RemarkField.vue';
import ShortCommentField from '@/components/spu/fields/ShortCommentField.vue';
import TempSpuShopIdsField from '@/components/spu/fields/TempSpuShopIdsField.vue';
import TempSpuShopPoisField from '@/components/spu/fields/TempSpuShopPoisField.vue';
import TyreSpecField from '@/components/spu/fields/TyreSpecField.vue';
import TyreTagField from '@/components/spu/fields/TyreTagField.vue';
import UnavailableTimeField from '@/components/spu/fields/UnavailableTimeField.vue';
import VideosField from '@/components/spu/fields/VideosField.vue';
import VipPriceCentField from '@/components/spu/fields/VipPriceCentField.vue';
import IsAutoBrandField from '@/components/spu/fields/IsAutoBrand.vue';
// import { PlatformSPU } from '@/models/spu.model';

// import lazyInject from '@/di';
// import { SPU } from '@/models/spu.model';
import { SPUFieldFactory } from '@/models/spuFieldFactory';
// import SPUService from '@/services/spu.service';
// import _ from 'lodash';

import lazyInject from '@/di';
import { ShopOperationLogService } from '@/services/shop_operation_log.service';

@Component({
  components: {
    // SPUAudit,
  },
})
export default class LogDrawer extends Vue {
  visible = false;

  auditUpdateData: any = {};
  shouldShowOriginalData = false;
  auditData: any = null; // 审核任务原始数据
  initSpuFields: ISPUField[] = SPUFieldFactory.init({
    spuType: 'spu',
  });

  @Prop()
  type: any;

  @lazyInject(ShopOperationLogService)
  operationLogService!: ShopOperationLogService;


  toAuditForm: any = {};

  async init(obj: { previous: string[]; current: string[] }, id: any) {
    const prev = obj.previous.length !== 0 ? obj.previous : [];
    const curr = obj.current || [];
    this.toAuditForm = this.initSpuForm(curr);
    if (this.type === 'batch_products') {
      this.initSpuFields = SPUFieldFactory.init({
        spuType: 'spu',
      });
      const reqParam = {
        id,
        ext_idx: Number(this.$route.params.id),
      };
      const res = await this.operationLogService.traceLogPrev(reqParam);
      console.log(res, 'res');
      this.auditData = this.initSpuForm(res.detail);
    } else {
      this.initSpuFields = SPUFieldFactory.init({
        spuType: 'spu',
      });
      this.auditData = this.initSpuForm((prev));
    }


    //  'batch_products', // 批量商品

    //  'platform_standard', // 平台标品
    if (this.type === 'platform_standard') {
      this.initSpuFields = SPUFieldFactory.init({
        spuType: 'platformSpu',
      });
    }
    //   'general_merchandise', // 普通商品
    if (this.type === 'general_merchandise') {
      this.initSpuFields = SPUFieldFactory.init({
        spuType: 'spu',
      });
    }

    //    'brand_standard', // 品牌标品
    if (this.type === 'brand_standard') {
      this.initSpuFields = SPUFieldFactory.init({
        spuType: 'kaSpu',
      });
    }
    this.visible = true;
  }

  close() {
    this.visible = false;
    this.toAuditForm = {};
    this.auditData = {};
  }

  getComponent(type: ESPUField) {
    switch (type) {
      case ESPUField.AUTO_OFF_SHELF_TIME: {
        return AutoOffShelfTimeField;
      }
      case ESPUField.CATEGORY: {
        return CategoryField;
      }
      case ESPUField.COMBO_TOTAL_PRICE: {
        return ComboTotalPriceField;
      }
      case ESPUField.EFFECTIVE_TIMES: {
        return EffectiveTimesField;
      }
      case ESPUField.FILTER_TAG: {
        return FilterTagField;
      }
      case ESPUField.HAS_NEW_USER_PRICE: {
        return HasNewUserPriceField;
      }
      case ESPUField.HEADLINE_IMAGE: {
        return HeadlineImageField;
      }
      case ESPUField.HEADLINE_IMAGES: {
        return HeadlineImagesField;
      }
      case ESPUField.ID: {
        return IdField;
      }
      case ESPUField.IMAGES: {
        return ImagesField;
      }
      case ESPUField.INTRODUCTION: {
        return IntroductionField;
      }
      case ESPUField.IS_AUTO_OFF_SHELF: {
        return IsAutoOffShelfField;
      }
      case ESPUField.IS_COMBO: {
        return IsComboField;
      }
      case ESPUField.IS_DRAW_COMMISSION: {
        return IsDrawCommissionField;
      }
      case ESPUField.IS_HOT: {
        return IsHotField;
      }
      case ESPUField.KA: {
        return KaField;
      }
      case ESPUField.NAME: {
        return NameField;
      }
      case ESPUField.NEW_USER_PRICE_CENT: {
        return NewUserPriceCentField;
      }
      case ESPUField.ORIGINAL_PRICE_CENT: {
        return OriginalPriceCentField;
      }
      case ESPUField.PRODUCTS: {
        return ProductsField;
      }
      case ESPUField.REMAK: {
        return RemarkField;
      }
      case ESPUField.SHORT_COMMENT: {
        return ShortCommentField;
      }
      case ESPUField.TEMP_SPU_SHOP_IDS: {
        return TempSpuShopIdsField;
      }
      case ESPUField.TEMP_SPU_SHOP_POIS: {
        return TempSpuShopPoisField;
      }
      case ESPUField.TYRE_SPEC: {
        return TyreSpecField;
      }
      case ESPUField.TYRE_TAG: {
        return TyreTagField;
      }
      case ESPUField.UNAVAILABLE_TIME: {
        return UnavailableTimeField;
      }
      case ESPUField.VIDEOS: {
        return VideosField;
      }
      case ESPUField.VIP_PRICE_CENT: {
        return VipPriceCentField;
      }
      case ESPUField.COMMISSION_RATIO: {
        return CommissionRatioField;
      }
      case ESPUField.IS_AUTO_BRAND: {
        return IsAutoBrandField;
      }
      default: {
        return null;
      }
    }
  }


  initSpuForm(editingSpu: any) {
    const form: { [key: string]: any } = {};
    const times =
      editingSpu.effectiveTimes > 0 ? editingSpu.effectiveTimes * 100 : 100;

    for (const [key, value] of Object.entries(editingSpu)) {
      switch (key) {
        case ESPUField.EFFECTIVE_TIMES: {
          if (Number(value) > 1) {
            form.isCombo = true;
          } else {
            form.isCombo = false;
          }
          form[key] = value;
          break;
        }
        case ESPUField.ORIGINAL_PRICE_CENT: {
          form[key] = value !== 0 ? (Number(value) / times).toFixed(2) : '';
          break;
        }
        case ESPUField.VIP_PRICE_CENT: {
          form[key] = value !== 0 ? (Number(value) / times).toFixed(2) : '';
          break;
        }
        case ESPUField.NEW_USER_PRICE_CENT: {
          form.hasNewUserPrice = Number(value) > 0;
          form[key] = value !== 0 ? (Number(value) / times).toFixed(2) : '';
          break;
        }

        default: {
          form[key] = value;
          break;
        }
      }
    }

    form.comboTotalPrice = {
      originalPriceCent: editingSpu.originalPriceCent,
      vipPriceCent: editingSpu.vipPriceCent,
      newUserPriceCent: editingSpu.newUserPriceCent,
    };
    form.commissionRatio = editingSpu.platformProduct.commissionRatio;
    form.isCopyPlatformProduct = editingSpu.isCopyPlatformProduct;
    form.isDrawCommission = editingSpu.isDrawCommission;
    form.isPlatformProduct = editingSpu.isPlatformProduct;
    form.shortComment = editingSpu.shortComment;
    form.tempSpuShopPois = editingSpu.tempSpuShopPois;
    return form;
  }

  onClose() {
    this.visible = false;
  }
}
