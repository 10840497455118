import Vue from 'vue';
import VueRouter, { RouteConfig, Route, NavigationGuardNext } from 'vue-router';
import shopRoutes from './shopRoutes';
import authRoutes from './authRoutes';
import orderRoutes from './orderRoutes';
import biRoutes from './biRoutes';
import activityUnitRoutes from './activityUnitRoutes';
import asyncPermission from '@/common/utils/permission';
import { AuthEnum } from '@/services/permission.service';
import { KeyObject } from '@/common/interface';
import { message } from 'ant-design-vue';
import HomeIndex from '@/views/home/index.vue';
import Home from '@/views/home/Home.vue';
import accountManagementRoutes from './accountManagementRoutes';
import permissionRoutes from './permissionRoutes';
import labelRoutes from './labelRoutes';
import auditRoutes from './auditRoutes';
import regionCoverRoutes from './regionCoverRoutes';
import withdrawRoutes from './withdrawRoutes';
import leadRoutes from './leadRoutes';
import spuRoutes from './spuRoutes';
import examineRoutes from './examineRoutes';
import questionnaireRoutes from './questionnaireRoutes';
import systemRoutes from './systemRoutes';
import operationRoutes from './operationRoutes';
import workbenchesRouter from './workbenchesRouter';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  /** 首页 */
  {
    path: '/',
    name: 'home',
    component: HomeIndex,
    redirect: {
      name: 'home.index',
    },
    children: [
      {
        name: 'home.index',
        path: '/index',
        component: Home,
        meta: {
          title: '欢迎使用德友惠工作台',
        },
      },
    ],
  },
  /** 店铺管理路由 */
  shopRoutes,
  /** 标品管理路由 */
  spuRoutes,
  /** 开城管理路由 */
  regionCoverRoutes,
  /** 鉴权路由 */
  authRoutes,
  /** 订单路由 */
  orderRoutes,
  biRoutes,
  /** 提现管理 */
  withdrawRoutes,
  /** 活动路由 */
  activityUnitRoutes,
  /** 权限路由 */
  permissionRoutes,
  /** 标签 */
  labelRoutes,
  /** 审核 */
  auditRoutes,
  /** 账号管理 */
  accountManagementRoutes,
  /** 留资线索 */
  leadRoutes,
  /** 考核调研 */
  examineRoutes,
  questionnaireRoutes,
  /** 系统管理 */
  systemRoutes,
  /** 品牌运营 */
  operationRoutes,
  /** 运营工作台 */
  workbenchesRouter,
  {
    name: 'main.shop.running-report',
    path: '/shop/running-report/:shopId',
    // component: ShopRunningReport,
    // props: true,
    component: () => import('@/views/shop/ShopRunningReport.vue'),
    meta: { title: '门店经营周报' },
  },
  /** 异常页面，放在最后 */
  {
    name: 'exception',
    path: '/exception/:type',
    component: () => import('@/views/exception/ExceptionPage.vue'),
    meta: { title: '错误' },
  },
  {
    path: '*',
    name: 'notFount',
    component: () => import('@/views/exception/ExceptionPage.vue'),
    meta: {
      title: '该页面不存在',
    },
  },
];

/** 避免重复点击相同路由报错 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (originalPush.call(this, location) as unknown as Promise<any>).catch(
    (err) => err,
  );
};

const router = new VueRouter({
  // mode: 'history',
  routes,
});

/** 全局路由拦截 */
router.beforeEach(
  async (
    to: Route,
    from: Route,
    next: NavigationGuardNext<Vue>,
  ): Promise<void> => {
    /** 动态修改店铺标题 */
    document.title = (to.meta && to.meta.title) || '德友惠';
    // const whiteList = ['/login', '/examine/questionnaire', '/examine/activity_aules', '/examine/notice', '/home'];
    if (
      to.fullPath.startsWith('/login') ||
      to.fullPath.startsWith('/examine/questionnaire') ||
      to.fullPath.startsWith('/examine/activity_aules') ||
      to.fullPath.startsWith('/examine/notice') ||
      to.fullPath.startsWith('/examine/notice_1') ||
      (to.name && to.name.indexOf('home') > -1)
    ) {
      next();
      return;
    }

    /**
     * 路由鉴权👇
     */

    /** 无用户信息 */
    const userInfo = localStorage.getItem('user');
    if (!userInfo) {
      document.title = '登录德友惠';
      next({
        name: 'auth.login',
        query: {
          redirect: to.fullPath,
        },
      });
      return;
    }

    const { token } = JSON.parse(userInfo);
    if (token) {
      const needCheck: AuthEnum[] = [];
      (to.matched || []).forEach(async (route) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const props = (route.props as unknown as any).default as KeyObject<
          AuthEnum[]
        >;
        if (props && props.auth) {
          (props.auth || []).forEach((auth) => {
            if (!needCheck.includes(auth)) {
              needCheck.push(auth);
            }
          });
        }
      });
      if (!needCheck.length) {
        next();
        return;
      }
      const permission = await asyncPermission(needCheck);
      if (permission) {
        if (permission.status === false) {
          next({
            path: '/login',
          });
        } else if (Object.values(permission).some((item) => item)) {
          next();
        } else {
          next({
            params: {
              type: '401',
            },
            name: 'exception',
          });
        }
      } else {
        message.error('权限请求失败！');
        next({
          params: {
            type: '401',
          },
          name: 'exception',
        });
      }
    } else {
      /** 无 token */
      document.title = '登录德友惠';
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      });
    }
  },
);

export default router;
