
import { Vue, Component, Ref } from 'vue-property-decorator';
import Representative from './components/Representative.vue';
import Foundation from './components/Foundation.vue';
import QuickEntrance from './components/QuickEntrance.vue';
import Individual from './components/Individual.vue';
import StatisticsMod from './components/StatisticsMod.vue';
import Loading from '@/components/loading/index.vue';

@Component({
  components: {
    Representative,
    Foundation,
    QuickEntrance,
    Individual,
    Loading,
    StatisticsMod,
  },
})
export default class Workbenches extends Vue {
  @Ref('quickEntrance')
  quickEntrance!: QuickEntrance;

  @Ref('representative')
  representative!: Representative;

  @Ref('statisticsMod')
  statisticsMod!: StatisticsMod;

  type: any = '';
  position: any = {
    assistantDirector: '总监助理',
    kaTeamLeader: '大B组长',
    kaTeamMinister: '大B部长',
    ordinaryTeamLeader: '小B组长',
    ordinaryTeamMinister: '小B部长',
    director: '总监',
  };

  changeType(type: any) {
    this.type = type;
    this.quickEntrance.closeLading();
    this.representative.init(type);
    this.statisticsMod.init(type);
  }
  mounted() {
  }
}
