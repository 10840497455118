import { render, staticRenderFns } from "./EditThirdpartnarModal.vue?vue&type=template&id=80130092&scoped=true"
import script from "./EditThirdpartnarModal.vue?vue&type=script&lang=ts"
export * from "./EditThirdpartnarModal.vue?vue&type=script&lang=ts"
import style0 from "./EditThirdpartnarModal.vue?vue&type=style&index=0&id=80130092&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80130092",
  null
  
)

export default component.exports