
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import {
  Shop,
  City,
  Kalist,
  MerchantSettledEnum,
  PlatformSpuBindStatusEnum,
} from '@/common/interface/shop';
import { AgentRes, AgentService } from '@/services/agent.service';
import { EShopBusinessStatus } from '@/common/enum/shop';
import EditThirdpartnarModal from './components/EditThirdpartnarModal.vue';
import EditThirdpartnarModalId from './components/EditThirdpartnarModalId.vue';
import { AuthEnum } from '@/services/permission.service';
import { EAgencyType } from '@/common/enum/account';
import { $delay } from '@/common/utils';
import { BASE_URL } from '@/common/constant';
import { Options } from './common/SPUForm';
import { v4 } from 'uuid';
import UserService from '@/services/user.service';
import SPUService from '@/services/spu.service';
import { Modal } from 'ant-design-vue';
import LookPhone from '@/components/LookPhone.vue';

@Component({
  components: {
    'edit-thirdpartnar-modal': EditThirdpartnarModal,
    'edit-thirdpartnar-id': EditThirdpartnarModalId,
    'look-phone': LookPhone,
  },
})
export default class ShopList extends Vue {
  options: Options = {
    allowDuplicateUploads: false,
    target: `${BASE_URL}/tool/upload`,
    testChunks: false,
    headers: {},
    chunkSize: 5 * 1024 * 1024,
  };

  BASE_URL = BASE_URL;

  token: string | undefined = '';

  uuid = v4;

  @lazyInject(ShopService)
  shopService!: ShopService;

  @lazyInject(UserService)
  userService!: UserService;

  @lazyInject(AgentService)
  protected agentService!: AgentService;

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @Ref('editThirdpartnarModal')
  editThirdpartnarModal?: EditThirdpartnarModal;

  @Ref('lookPhone')
  lookPhone!: LookPhone;


  @Ref('editThirdpartnarModalId')
  editThirdpartnarModalId?: EditThirdpartnarModalId;

  AuthEnum = AuthEnum;

  MerchantSettledEnum = MerchantSettledEnum;

  PlatformSpuBindStatusEnum = PlatformSpuBindStatusEnum;

  form: {
    name: string;
    regionId: string | number;
    ka_id: string | number;
    agentId: string | undefined;
    agentName: string | undefined;
    businessStatus: EShopBusinessStatus;
    poi: string;
    platformSpuBindStatus: PlatformSpuBindStatusEnum;
    campaignAgent: string;
    annualAgent: string;
    shopId: string | number;
    merchantShopId: string | number;
    onsiteService: any;
  } = {
      name: '',
      regionId: '',
      ka_id: '',
      agentId: undefined,
      agentName: undefined,
      businessStatus: EShopBusinessStatus.全部,
      poi: '',
      platformSpuBindStatus: PlatformSpuBindStatusEnum.全部,
      campaignAgent: '',
      annualAgent: '',
      shopId: '',
      merchantShopId: '',
      onsiteService: undefined,
    };

  EAgencyType = EAgencyType;

  businessStatusEnum = EShopBusinessStatus;

  businessStatusList: EShopBusinessStatus[] = [
    EShopBusinessStatus.全部,
    EShopBusinessStatus.正常营业,
    EShopBusinessStatus.暂停营业,
    EShopBusinessStatus.惩罚下线,
    EShopBusinessStatus.永久停业,
  ];

  platformSpuBindStatusList: PlatformSpuBindStatusEnum[] = [
    PlatformSpuBindStatusEnum.全部,
    PlatformSpuBindStatusEnum.未报名,
    PlatformSpuBindStatusEnum.报名过,
    PlatformSpuBindStatusEnum.已报名,
  ];

  listData: Shop[] = [];

  citys: City[] = [];

  kalist: Kalist[] = [];

  agentList: AgentRes[] = [];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };

  currentThirdpartnar: {
    uid: string;
    name: string;
    shopId: string | number;
    type: EAgencyType;
  } | null = null;

  currentThirdpartnarId: {
    venderId: string;
    oldVenderId: string;
    shopId: string | number;
  } | null = null;

  /** 获取服务商列表 */
  async getAgentList(value: string) {
    try {
      const res = await this.agentService.list({
        name: value,
        page: 1,
        pageSize: 50,
      });
      this.agentList = res;
    } catch (error) {
      this.$message.error(`${error}`);
    }
  }
  LookShopDetail(val: Shop) {
    this.lookPhone.init('店铺列表-电话', val.encrypt_tels, val.poi);
  }
  goToShopDetail(id: string) {
    const routeUrl = this.$router.resolve({
      name: 'main.shop.detail',
      params: {
        id: `${id}`,
      },
    });
    window.open(routeUrl.href, '_blank');
  }

  handleAgentChange(aid: number) {
    const agentMap = new Map(this.agentList.map((item) => [item.aid, item]));

    this.form.agentId = `${aid}`;
    this.form.agentName = agentMap.get(aid)?.name;
  }

  mounted() {
    this.options.headers.AUTHORIZATION = this.userService.currentUser()?.token;
    this.token = this.userService.currentUser()?.token;
    this.handleSearch();
    this.getCityList();
    this.getKaList();
  }

  copyToClipboard(item: any, key: any) {
    const value = (item[key as keyof Shop]) as string;
    this.$copyText(String(value)).then(() => {
      this.$message.success('复制成功');
    }, (err) => {
      this.$message.error('复制失败');
      console.error('复制失败:', err);
    });
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    const { name, regionId, ka_id, agentId, agentName, businessStatus, poi, shopId, merchantShopId, annualAgent, campaignAgent } =
      this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: current,
    };
    if (name) {
      query.name = name;
    }

    if (regionId) {
      query.regionId = regionId;
    }

    if (ka_id) {
      query.ka_id = ka_id;
    }

    if (agentId) {
      query.agentId = agentId;
    }

    if (agentName) {
      query.agentName = agentName;
    }
    if (businessStatus) {
      query.businessStatus = businessStatus;
    }

    if (poi) {
      query.poi = poi;
    }
    if (shopId) {
      query.shopId = shopId;
    }
    if (merchantShopId) {
      query.merchantShopId = merchantShopId;
    }
    if (annualAgent) {
      query.annualAgent = annualAgent;
    }
    if (campaignAgent) {
      query.campaignAgent = campaignAgent;
    }
    this.$router.push({
      query,
    });
  }

  /** 跳转至商品报名 */
  spuManage(id: string) {
    this.$router.push({
      name: 'main.shop.platformSpus',
      params: {
        shopId: id,
      },
    });
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    const {
      name,
      regionId,
      ka_id,
      agentId,
      agentName,
      businessStatus,
      poi,
      platformSpuBindStatus,
      annualAgent,
      campaignAgent,
      shopId,
      merchantShopId,
      onsiteService,
    } = this.form;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: `${this.pagination.current}`,
    };
    if (name) {
      query.name = name;
    }

    if (regionId) {
      query.regionId = regionId;
    }

    if (ka_id) {
      query.ka_id = ka_id;
    }

    if (agentId) {
      query.agentId = agentId;
    }

    if (agentName) {
      query.agentName = agentName;
    }

    if (poi) {
      query.poi = poi;
    }

    if (shopId) {
      query.shopId = shopId;
    }

    if (merchantShopId) {
      query.merchantShopId = merchantShopId;
    }

    if (platformSpuBindStatus !== PlatformSpuBindStatusEnum.全部) {
      query.platformSpuBindStatus = platformSpuBindStatus;
    }

    if (businessStatus && businessStatus !== EShopBusinessStatus.全部) {
      query.businessStatus = businessStatus;
    }

    if (annualAgent) {
      query.annualAgent = annualAgent;
    }
    if (campaignAgent) {
      query.campaignAgent = campaignAgent;
    }
    if (onsiteService) {
      query.onsiteService = onsiteService;
    }

    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { pageSize, current } = this.pagination;
    const {
      name,
      regionId,
      ka_id,
      agentId,
      agentName,
      businessStatus,
      poi,
      platformSpuBindStatus,
      campaignAgent,
      annualAgent,
      shopId,
      merchantShopId,
      onsiteService,
    } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {
      pageSize,
      page: current,
    };

    if (name) {
      this.form.name = name as string;
      param.name = name;
    }
    if (regionId) {
      this.form.regionId = +regionId;
      param.regionId = regionId;
    }

    if (onsiteService) {
      this.form.onsiteService = onsiteService as string;
      param.onsiteService = onsiteService;
    }

    if (ka_id) {
      this.form.ka_id = +ka_id;
      param.ka_id = ka_id;
    }
    if (agentId) {
      this.form.agentId = agentId as string;
      param.distributorId = agentId;
    }
    if (agentName) {
      this.form.agentName = agentName as string;
    }
    if (poi) {
      this.form.poi = poi as string;
      param.poi = poi;
    }
    if (shopId) {
      this.form.shopId = shopId as string;
      param.shopId = shopId;
    }
    if (merchantShopId) {
      this.form.merchantShopId = merchantShopId as string;
      param.merchantShopId = merchantShopId;
    }

    if (businessStatus) {
      this.form.businessStatus = businessStatus as EShopBusinessStatus;
      if (businessStatus !== this.businessStatusEnum.全部) {
        param.businessStatus = businessStatus;
      }
    }

    if (platformSpuBindStatus) {
      this.form.platformSpuBindStatus =
        +platformSpuBindStatus as PlatformSpuBindStatusEnum;
      param.bindStatus = +platformSpuBindStatus;
    }
    if (annualAgent) {
      param.annualAgent = annualAgent;
      this.$set(this.form, 'annualAgent', annualAgent);
    }
    if (campaignAgent) {
      param.campaignAgent = campaignAgent;
      this.$set(this.form, 'campaignAgent', campaignAgent);
    }

    await this.getShopList(param);
  }

  async getShopList(params: {
    pageSize: number;
    page: number;
    name?: string;
    regionId?: string;
    ka_id?: string;
    distributorId?: string;
    businessStatus?: string;
    bindStatus?: string;
    poi?: string;
    annualAgent?: string;
    campaignAgent?: string;
    onsiteService?: string;
  }) {
    const res = await this.shopService.getList(params, this.$el);
    if (res) {
      this.listData = res.list;
      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: +res.pageSize,
        onChange: this.paginationChange,
      };
    }
  }

  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.shopService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;
    } else {
      this.citys = [];
    }
  }

  async getKaList() {
    const id = this.userService.currentUser()?.id;
    const response = await this.shopService.postKaUserList(id);
    if (response) {
      this.kalist = response;
    }
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    this.form = {
      name: '',
      regionId: '',
      ka_id: '',
      agentId: '',
      agentName: '',
      poi: '',
      businessStatus: EShopBusinessStatus.全部,
      platformSpuBindStatus: PlatformSpuBindStatusEnum.全部,
      annualAgent: '',
      campaignAgent: '',
      shopId: '',
      merchantShopId: '',
      onsiteService: undefined,
    };
    this.$router.push({
      query: {},
    });
  }

  /** 省市过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  showEditThirdpartnarModal(
    shopId: string | number,
    thirdpartnarName: string,
    thirdpartnarId: string,
    type: EAgencyType,
  ) {
    this.currentThirdpartnar = {
      shopId,
      name: thirdpartnarName,
      uid: thirdpartnarId,
      type,
    };

    this.editThirdpartnarModal?.open();
  }

  uploading = false;


  showEditThirdpartnarId(
    shopId: string | number,
    venderId: any,
  ) {
    this.currentThirdpartnarId = {
      shopId,
      oldVenderId: venderId,
      venderId: '',
    };
    this.editThirdpartnarModalId?.open();
  }

  isLt5M(file: File) {
    return file.size / 1024 / 1024 < 5;
  }

  beforeUpload(file: { type: string; size: number }) {
    const isExcel =
      file.type === 'application/vnd.ms-excel' ||
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'text/csv';
    if (!isExcel) {
      this.$message.error('请上传 excel表格！');
    }
    const isLt5M = this.isLt5M(file as File);
    if (!isLt5M) {
      this.$message.error('文件必须小于5MB!');
    }
    return isExcel && isLt5M;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async handleUploadChange(info: any) {
    if (info.file.status === 'uploading') {
      this.uploading = true;
    }
    if (info.file.status === 'error') {
      this.uploading = false;
      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        await $delay(1000);
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }

    if (info.file.status === 'done') {
      const { data } = JSON.parse(info.file.xhr.response);
      if (data) {
        if (data.isSuccess === true) {
          await this.batchImportBrand(data.data);
          this.uploading = false;
          return;
        }

        this.$confirm({
          title: '请仔细确认后再执行后续操作',
          content: `${data.msg}`,
          okText: '继续导入',
          cancelText: '取消',
          onOk: async () => {
            await this.batchImportBrand(data.data);
          },
        });
        this.uploading = false;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async batchImportBrand(data: any) {
    const res = await this.shopService.batchImportBrand(data);
    if (res) {
      if (res.isSuccess) {
        this.$message.success(`${res.msg}`);
      }
    }
  }

  loading = false;

  async getShopAllBoundSpus(
    bindStatus: PlatformSpuBindStatusEnum,
    id: number | string,
    name: string,
  ) {
    if (bindStatus !== PlatformSpuBindStatusEnum['已报名']) {
      return;
    }
    if (this.loading) {
      return;
    }
    this.loading = true;
    try {
      const res = await this.spuService.shopPlatformSpusBound(id);
      if (res) {
        this.info(name, res);
      }
    } catch (error) {
      this.loading = false;
    }
  }

  info(shopName: string, arr: Array<{ name: string }>) {
    const h = this.$createElement;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const list: any[] = [
      h('h4', '请核对门店报名信息，避免出现线下客诉。'),
      h('h5', '报名商品'),
    ];

    for (const item of arr) {
      list.push(h('p', `${item.name}`));
    }

    this.$info({
      title: `门店-${shopName}的报名信息`,
      content: h('div', {}, [...list]),
      onOk: () => {
        Modal.destroyAll();
        this.loading = false;
      },
    });
  }
}
