
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class StoreConsumed extends Vue {
  dataSource = [];
  columns = [
    {
      title: '门店名称',
      dataIndex: 'storeName',
      align: 'center',
    },
    {
      title: 'POI',
      dataIndex: 'poi',
      align: 'center',
    },
    {
      title: '广告主ID',
      dataIndex: 'advertiserId',
      align: 'center',
    },
    {
      title: '充值金额',
      dataIndex: 'last7DaysSpend',
      align: 'center',
    },
    {
      title: '消耗金额',
      dataIndex: 'balance',
      align: 'center',
    },
    {
      title: '余额',
      dataIndex: 'action',
      align: 'center',
    },
    // {
    //   title: '充值次数',
    //   dataIndex: 'action',
    //   align: 'center',
    // },
    {
      title: '最近一次充值时间',
      dataIndex: 'action',
      align: 'center',
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
    },
  ];
  refresh() {
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }
}
