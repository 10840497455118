
import { Vue, Component, Ref } from 'vue-property-decorator';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import { FormModel } from 'ant-design-vue';
import GoodsModal from './components/GoodsModal.vue';
import { BASE_URL } from '@/common/constant';
import { $delay } from '@/common/utils';
import UserService from '@/services/user.service';


enum EInvalidIds {
  AUDITERR = 'auditErr',
  PLATFORMERR = 'platformErr',
  KAERROR = 'kaError',
  EXCEPTIONIDS = 'exceptionIds',
}

@Component({
  components: {
    GoodsModal,
  },
})

export default class ProductDeletion extends Vue {
  @lazyInject(SPUService)
  protected spuService!: SPUService;


  @lazyInject(UserService)
  userService!: UserService;

  @Ref()
  readonly ruleForm!: FormModel;

  @Ref()
  goods_modal!: GoodsModal;

  BASE_URL = BASE_URL;

  EInvalidIds = EInvalidIds;

  val = '';

  uploading = false;

  exceptionIds = '';

  platformErr = '';

  auditErr = '';

  kaError = '';


  get exceptionIdsStatus() {
    return this.exceptionIds !== '';
  }

  get getplatformErr() {
    return this.platformErr !== '';
  }

  get getauditErr() {
    return this.auditErr !== '';
  }

  get getkaError() {
    return this.kaError !== '';
  }

  dataForm = {
    spuId: '',
    kaId: '',
  };

  async remove(invalidType: EInvalidIds) {
    const all = this.dataForm.spuId.split(',').map((item) => {
      return Number(item);
    });
    let diff: number[] = [];
    switch (invalidType) {
      case EInvalidIds.PLATFORMERR: {
        const platformErr = this.platformErr
          .split(',')
          .map((item) => {
            return Number(item);
          });
        diff = all.filter((item) => platformErr.indexOf(item) === -1);
        this.platformErr = '';
        break;
      }
      case EInvalidIds.AUDITERR: {
        const auditErr = this.auditErr
          .split(',')
          .map((item) => {
            return Number(item);
          });
        diff = all.filter((item) => auditErr.indexOf(item) === -1);
        this.auditErr = '';
        break;
      }
      case EInvalidIds.KAERROR: {
        const kaError = this.kaError
          .split(',')
          .map((item) => {
            return Number(item);
          });
        diff = all.filter((item) => kaError.indexOf(item) === -1);
        this.kaError = '';
        break;
      }
      default: {
        const exceptionList = this.exceptionIds.split(',').map((item) => {
          return Number(item);
        });
        diff = all.filter((item) => exceptionList.indexOf(item) === -1);
        this.exceptionIds = '';
        break;
      }
    }

    this.dataForm.spuId = diff.join(',');
    this.$message.success('移除成功');
  }

  token: string | undefined = undefined;


  rules = {
    kaId: [
      { required: true, message: '请先选择品牌', trigger: 'blur' },
    ],
    spuId: [
      { required: true, message: '商品ID 不能为空', trigger: 'blur' },
    ],
  };

  layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };

  kaList: Array<{ key: string; val: number }> = [];

  getList() {
    console.log('刷新');
  }

  updateGoods(val: any) {
    this.dataForm.spuId = val.join(',');
    console.log(val);
  }
  isLt5M(file: File) {
    return file.size / 1024 / 1024 < 5;
  }

  onCopySuccess() {
    this.$message.success('复制成功');
  }

  beforeUpload(file: { type: string; size: number }) {
    console.log('000000000');
    if (!this.dataForm.kaId) {
      this.$message.error('请先选择品牌');
      return false;
    }
    const isExcel =
      file.type === 'application/vnd.ms-excel' ||
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'text/csv';
    if (!isExcel) {
      this.$message.error('请上传 excel表格！');
    }
    const isLt5M = this.isLt5M(file as File);
    if (!isLt5M) {
      this.$message.error('文件必须小于5MB!');
    }
    return isExcel && isLt5M;
  }

  async onFullImportChange(info: any) {
    if (info.file.status === 'uploading') {
      this.uploading = true;
    }
    if (info.file.status === 'error') {
      this.uploading = false;
      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        await $delay(1000);
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }
    if (info.file.status === 'done') {
      const { data } = JSON.parse(info.file.xhr.response);
      const { error, ka_error, platform_err, audit_err } = data;

      const all = data.data;
      const newIds = data.data;
      const ids = newIds.split(',');
      this.exceptionIds = error || '';
      this.kaError = ka_error || '';
      this.platformErr = platform_err || '';
      this.auditErr = audit_err || '';
      this.dataForm.spuId = ids.join(',');
      this.uploading = false;
      console.log(all);
      this.onChanges();
    }
  }
  onChanges() { }

  created() {
    // this.options.headers.AUTHORIZATION = this.userService.currentUser()?.token;
    this.token = this.userService.currentUser()?.token;
  }

  submitForm(val: any) {
    this.ruleForm.validate((valid) => {
      if (valid) {
        this.funCheckSpuBat();
        console.log(val);
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }
  async SpuBatDelete() {
    // const formDate = {
    //   ids: [11, 180359, 658379, 119021, 20, 21],
    //   type: '2',
    // };
    console.log(this.dataForm);
    const { kaId, spuId } = this.dataForm;
    const params = {
      ka_id: kaId,
      spu_ids: spuId,
    };
    const res = await this.spuService.postSpuBatDelete(params);
    if (res) {
      this.ruleForm.resetFields();
      this.$message.success('删除成功');
    }
  }
  async resetForm(val: any) {
    console.log(val, '重置');
    this.ruleForm.resetFields();
  }

  async funCheckSpuBat() {
    const res = await this.spuService.checkSpuBat(this.dataForm.kaId, { spu_ids: this.dataForm.spuId });
    const { error, ka_error, platform_err, audit_err } = res;
    this.exceptionIds = error || '';
    this.kaError = ka_error || '';
    this.platformErr = platform_err || '';
    this.auditErr = audit_err || '';
    console.log(this.exceptionIdsStatus, this.getplatformErr, this.getauditErr, this.getkaError);
    if (this.exceptionIdsStatus || this.getplatformErr || this.getkaError) {
      this.$message.error('存在异常商品，请先处理');
    } else {
      this.SpuBatDelete();
    }
  }

  onChange(val: number | string) {
    // const idx = this.kaList?.findIndex((item) => item.val === val);
    console.log(val);
    // if (this.kaList && typeof idx !== 'undefined' && idx > -1) {
    //   const targert = this.kaList[idx];
    //   this.val = targert.key;
    // }
  }

  async getKaList() {
    const res = await this.spuService.kaSpuAccessibleKas();
    if (res && res.length) {
      this.kaList = [...res];
    }
  }

  filterOption(input: any, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }


  changeGoods() {
    if (this.dataForm.kaId) {
      const spuIds = JSON.parse(JSON.stringify(this.dataForm.spuId));
      this.goods_modal.init(this.dataForm.kaId, spuIds);
    } else {
      this.$message.error('请先选择品牌');
    }
  }
  mounted() {
    this.getKaList();
  }
}
