
import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';
import lazyInject from '@/di';
import WorkbenchesService from '@/services/workbenches.service';
import { AccountService } from '@/services/account_service';
import { getLastMonth } from '@/common/utils';

@Component({})
export default class PromoteCollision extends Vue {
  moment = moment;

  @lazyInject(WorkbenchesService)
  workbenchesService!: WorkbenchesService;

  @lazyInject(AccountService)
  protected accountService!: AccountService;
  permissionListOfRole: any = [];
  fetching = false;
  permissionOptions: any = [];

  dataSource: any = [];
  pagination = {
    pageSize: 10,
    current: 1,
    total: 0,
    onChange: this.paginationChange,
  };

  filterForm: any = {
    month: '',
    dept_id: undefined,
    group_id: undefined,
  };
  columns = [
    {
      title: '部门',
      dataIndex: 'dept_name',
      align: 'center',
    },
    {
      title: '组',
      dataIndex: 'group_name',
      align: 'center',
    },
    {
      title: '代运营',
      dataIndex: 'agent_name',
      align: 'center',
    },
    {
      title: '撞线率',
      dataIndex: 'ad_rate',
      align: 'center',
    },
    {
      title: '得分',
      dataIndex: 'score',
      align: 'center',
    },
  ];

  dept_list: any = [];

  group_list: any = [];

  @Watch('$route')
  onRouteChange() {
    const { month, dept_id, group_id, agent_name, agent_id, page, pageSize } = this.$route.query;
    const params: any = {
      page: page || 1,
      pageSize,
    };
    if (month) {
      this.filterForm.month = month;
      params.month = month;
    }
    if (dept_id) {
      this.filterForm.dept_id = +dept_id;
      params.dept_id = +dept_id;
    }
    if (group_id) {
      this.filterForm.group_id = +group_id;
      params.group_id = +group_id;
    }
    if (agent_name || agent_id) {
      this.filterForm.agent_name = agent_name;
      params.agent_name = agent_name;
      params.agent_id = agent_id;
      this.permissionListOfRole = {
        key: agent_id,
        label: agent_name,
      };
    }
    this.getList(params);
  }
  back() {
    this.$router.push({
      name: 'workbenches.index',
    });
  }
  async getList(params: any) {
    const res = await this.workbenchesService.getAdCampaignRate(params);
    this.dataSource = res.list;
    this.pagination.current = res.page;
    this.pagination.total = res.total;
  }
  refresh() {
    this.resetQuery();
  }

  async searchPermission(value: string) {
    try {
      const res = await this.accountService.getLeaderList({
        page: 1,
        pageSize: 1000,
        userName: value,
      });
      this.permissionOptions = res.list;
    } catch (error) {
      // console.log(error);
    }
  }

  async handleChange(value: any) {
    this.permissionListOfRole = value;
  }

  search() {
    const { month, dept_id, group_id } = this.filterForm;
    const { key, label } = this.permissionListOfRole;
    const query: any = {
      page: 1,
      pageSize: 10,
    };
    if (month) {
      query.month = month;
    }
    if (dept_id) {
      query.dept_id = dept_id;
    }
    if (group_id) {
      query.group_id = group_id;
    }
    if (key) {
      query.agent_id = key;
      query.agent_name = label;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  resetQuery() {
    this.filterForm = {
      month: getLastMonth(),
      dept_id: undefined,
      group_id: undefined,
    };
    this.permissionListOfRole = [];
    const query: any = {};
    query.month = getLastMonth();
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  paginationChange(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    const { month, dept_id, group_id } = this.filterForm;
    const { key, label } = this.permissionListOfRole;
    const query: any = {
      page,
      pageSize: 10,
    };
    if (month) {
      query.month = month;
    }
    if (dept_id) {
      query.dept_id = dept_id;
    }
    if (group_id) {
      query.group_id = group_id;
    }
    if (key) {
      query.agent_id = key;
      query.agent_name = label;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  getDataSource() {
  }
  changeMonth(val: any) {
    const year = val._d.getFullYear(); // 获取当前年份，例如：2021
    let month: any = Number(val._d.getMonth()) + 1; // 获取当前月份，注意需要加1，例如：9
    if (month < 10) {
      month = `0${month}`;
    }
    const _d = `${year}-${month}`;
    this.filterForm.month = _d;
  }

  mounted() {
    this.onRouteChange();
    this.getDeptInfo();
  }

  async getDeptInfo() {
    const res = await this.workbenchesService.getDeptInfo();
    this.dept_list = res;
    const { dept_id } = this.$route.query;
    if (!dept_id) return;
    const deptId = Number(dept_id);
    this.group_list = this.dept_list.find((item: any) => item.dept.id === deptId).group;
  }
  selectOption(val: any) {
    this.group_list = this.dept_list.find((res: any) => res.dept.id === val).group;
    this.filterForm.group_id = undefined;
  }
}
