/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import {
  IResShopRunningReport,
  IShopStaffConfig,
  MerchantInfo,
} from './../common/interface/shop';
import { injectable } from 'inversify';
import {
  Shop,
  ShopDetail,
  EditShopFiledType,
  ShopProfileDetail,
} from '@/common/interface/shop';
import BaseService from './base.service';
import { message } from 'ant-design-vue';
import { ShopWeeklyRunningReport } from '@/models/shopWeeklyRunningReport.model';

interface ShopListRes {
  list: Shop[];
  total: number;
  pageSize: number;
  page: number;
}
interface AllShopsRes {
  list: Array<{
    dyhStaffName: string;
    poi: string;
    shopId: number;
    shopName: string;
  }>;
  total: number;
  pageSize: number;
  page: number;
}

export enum BlackcardRankTypeEnum {
  NAV_PV = 'navPv',
  TEL_NUM = 'telNum',
  ORDER_NUM = 'orderNum',
}

@injectable()
export default class ShopService extends BaseService {
  /**
   * 获取店铺列表
   *
   * @param {{
   *     regionId?: string;
   *     name?: string;
   *     distributorId?: string;
   *     auditStatus?: boolean;
   *     businessStatus?: string;
   *     pageSize: number;
   *     page: number;
   *   }} params
   * @return {(Promise<ShopListRes | null>)}
   * @memberof ShopService
   */
  async getList(
    params: {
      regionId?: string;
      name?: string;
      distributorId?: string;
      auditStatus?: boolean;
      businessStatus?: string;
      poi?: string;
      bindStatus?: string;
      pageSize: number;
      page: number;
      annualAgent?: string;
      campaignAgent?: string;
    },
    loadingEl?: Element | Vue,
  ): Promise<ShopListRes | null> {
    try {
      const result = (await this.http.get('/shop/search/list', {
        params,
        data: {
          loadingEl,
        },
      })) as ShopListRes;
      return result;
    } catch (error) {
      return null;
    }
  }

  /**
   * 获取门店详情
   *
   * @param {number} shopid
   * @return {Promise<ShopDetail | undefined>}
   * @memberof ShopService
   */
  async getShopDetail(
    shopid: number | string,
    loadingEl?: Element | Vue,
  ): Promise<{ detail: ShopDetail; merchantInfo: MerchantInfo } | undefined> {
    try {
      const data = (await this.http.get(`/shop/${shopid}/baseInfo`, {
        data: { loadingEl },
      })) as {
        detail: ShopDetail;
        merchantInfo: MerchantInfo;
        city_code: {
          regionid: number;
          regionpid: number;
          adcode: number;
        };
      };
      if (!data.detail.videos) {
        data.detail.videos = [] as Array<{
          id: number;
          order: number;
          url: string;
          cover: string;
        }>;
      }
      return data;
    } catch (error) {
      return undefined;
    }
  }

  /** 停止营业 */
  async stopBusiness<T>(
    shopId: string,
    startTime: string,
    reason: string,
  ): Promise<T | undefined> {
    try {
      const data = (await this.http.post(
        `/shop/backend/${shopId}/stop-business`,
        {
          startTime,
          reason,
        },
      )) as T;
      return data;
    } catch (error) {
      return undefined;
    }
  }

  /** 继续营业 */
  async startBusiness<T>(shopId: string): Promise<T | undefined> {
    try {
      const data = (await this.http.post(
        `/shop/backend/${shopId}/start-business`,
      )) as T;
      return data;
    } catch (error) {
      return undefined;
    }
  }

  /** 永久停业 */
  async foreverStopBusiness<T>(
    shopId: string,
    reason: string,
  ): Promise<T | undefined> {
    try {
      const data = (await this.http.post(
        `/shop/backend/${shopId}/forever-stop-business`,
        { reason },
      )) as T;
      return data;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * 惩罚下线
   * @param shopId 门店ID
   * @param reason 惩罚下线原因
   * @returns
   */
  async punitiveStopBusiness<T>(
    shopId: string,
    reason: string,
  ): Promise<T | undefined> {
    try {
      const data = (await this.http.post(
        `/shop/backend/${shopId}/offline-business`,
        { reason },
      )) as T;
      return data;
    } catch (error) {
      return undefined;
    }
  }

  /** 转让 */
  async transfer<T>(
    shopId: string,
    phone: string,
    reason: string,
  ): Promise<T | undefined> {
    try {
      const data = (await this.http.post(`/shop/${shopId}/transfer-boss`, {
        phone,
        reason,
      })) as T;
      return data;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * 修改商户收款账户状态
   *   冻结 | 解冻
   *
   * @param {(string | number)} shopId
   * @param {number} status
   * @param {(Element | Vue)} [loadingEl]
   * @return {*}  {Promise<boolean>}
   * @memberof ShopService
   */
  async changePayeeAccountStatus(
    shopId: string | number,
    status: number,
    loadingEl?: Element | Vue,
    reason?: string,
  ): Promise<boolean> {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const param: any = {
        status,
      };
      if (reason) {
        param.reason = reason;
      }
      const data = (await this.http.post(
        `/shop/${shopId}/payee-account-freeze-status`,
        param,
        {
          data: {
            loadingEl,
          },
        },
      )) as string;
      message.success(data);
      return true;
    } catch (error) {
      return false;
    }
  }

  async touchShop(shopId: string | number): Promise<boolean> {
    try {
      await this.http.post(`/shop/${shopId}/touch`);
      message.success('标记推送成功，1 分钟左右会推送至高德.');
      return true;
    } catch (error) {
      message.error(error as string);
      return false;
    }
  }

  async touchShopMerchant(shopId: string | number): Promise<boolean> {
    try {
      await this.http.post(`/shop/${shopId}/mechant-join`);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @note 门店入驻中，资料修改中增加人工干预
   * @param shopId 门店ID
   * @param action 1
   */
  async interventionShopMerchant(shopId: string | number, action?: number) {
    try {
      const url = `/shop/${shopId}/change-mechant-join`;
      const res =
        typeof action !== 'undefined' && action === 1
          ? ((await this.http.post(url, { do: 1 })) as string)
          : ((await this.http.post(url)) as string);
      return res;
    } catch (error) {
      return false;
    }
  }

  async migrateShop(shopId: string | number, targetPOI: string) {
    try {
      const data = (await this.http.post(`/shop/${shopId}/migration`, {
        poi: targetPOI,
      })) as string;
      message.success(
        `${data} 门店迁移成功，高德客户端更新约有 5 ~ 10 分钟的延迟，请耐心等待。`,
      );
      return true;
    } catch (error) {
      message.error(error as string);
      return false;
    }
  }

  /**
   * 编辑门店
   *
   * @template T
   * @param {string} shopId
   * @param {({[key: string]: number[] | string[]})} payload
   * @return {*}  {(Promise<T | undefined>)}
   * @memberof ShopService
   */
  async editShop<T>(
    shopId: string,
    payload: { [key: string]: number[] | string[] },
  ): Promise<T | undefined> {
    try {
      const data = (await this.http.post(`/shop/${shopId}/edit`, payload)) as T;
      return data;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * 修改签约门店信息
   */
  async modifyShop<T>(
    shopId: string,
    filed: EditShopFiledType,
  ): Promise<T | undefined> {
    try {
      const res = (await this.http.post(`shop/${shopId}/baseInfo`, {
        data: filed,
      })) as T;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  async editOnSiteServiceFlag(parm: any) {
    try {
      const data = (await this.http.post('onSiteService/shop/edit/onSiteServiceFlag', parm)) as any;
      return data;
    } catch (error) {
      return false;
    }
  }


  /**
   * 获取全部门店（不区分是否入驻的门店列表）
   *
   * @param params
   * @param loadingEl
   * @return {Promise<AllShopsRes | null>}
   */
  async all(
    params: {
      poi?: string;
      shopName?: string;
      pageSize: number;
      page: number;
    },
    loadingEl?: Element | Vue,
  ): Promise<AllShopsRes | null> {
    try {
      const result = (await this.http.get('/w/shops', {
        params,
        data: {
          loadingEl,
        },
      })) as AllShopsRes;
      return result;
    } catch (error) {
      return null;
    }
  }

  /**
   * 获取（不区分是否入驻的全部门店）门店信息
   * @param id
   * @param loadingEl
   * @returns
   */
  async profile(id: number, loadingEl?: Element | Vue) {
    try {
      const data = (await this.http.get(`/w/shop/${id}/detail`, {
        data: {
          loadingEl: loadingEl || document.body,
        },
      })) as ShopProfileDetail;

      return data;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * 释放地推员
   * @param shopId
   * @param loadingEl
   * @return {Promise<string | undefined>
   */
  async emptyDyhStaff(
    shopId: number,
    loadingEl?: Element | Vue,
  ): Promise<string | undefined> {
    try {
      const res = (await this.http.delete(`/w/shop/release/${shopId}/staff`, {
        data: { loadingEl },
      })) as string;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  async fetchShopRawData(param: {
    shopId: number;
    startDate?: string;
    endDate?: string;
  }) {
    try {
      const { shopId, startDate, endDate } = param;
      const params: {
        shop: number;
        startDate?: string;
        endDate?: string;
      } = {
        shop: shopId,
      };
      if (startDate) {
        params.startDate = startDate;
      }
      if (endDate) {
        params.endDate = endDate;
      }

      const res = (await this.http.post(
        '/w/shop/summary-data',
        params,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      )) as any[];
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * 同步门店信息
   *
   * @param poiId
   * @return {Promise<string | undefined>
   */
  async syncToAmap(poiId: string): Promise<string | undefined> {
    try {
      const res = (await this.http.post(`/w/shop/${poiId}/sync`)) as string;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * 删除门店草稿
   * @param shopId
   * @param loadingEl
   * @return {Promise<string | undefined>
   */
  async deleteShopDraft(shopId: number, loadingEl?: Element | Vue) {
    try {
      const data = (await this.http.delete(`/w/shop/${shopId}/draft`, {
        data: { loadingEl },
      })) as string;

      return data;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * 删除审核中的门店认领申请
   *
   * @param shopId
   * @param loadingEl
   * @return {Promise<string | undefined>
   */
  async deleteShopApplication(shopId: number, loadingEl?: Element | Vue) {
    try {
      const data = (await this.http.delete(`/w/shop/${shopId}/apply`, {
        data: { loadingEl },
      })) as string;

      return data;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * 地推员转移
   *
   * @param shopId
   * @param userId
   * @return {Promise<string | undefined>
   */
  async dyhStaffChange(shopId: number, userId: number) {
    try {
      const res = (await this.http.post('/w/shop/transfer/staff', {
        shopId,
        userId,
      })) as string;

      return res;
    } catch (error) {
      return undefined;
    }
  }

  async thirdpartnar(shopId: number | string, uid: number) {
    try {
      const res = (await this.http.post(`/shop/${shopId}/agent-bind`, {
        agent_id: uid,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      })) as any;

      return res;
    } catch (error) {
      return undefined;
    }
  }

  async thirdpartnarSearch(shopId: number | string) {
    try {
      const res = (await this.http.get(`/shop/${shopId}/agent-bind-search`)) as any;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /** 黑卡/年费代运营 */
  async bindBlackCardAgency(shopId: number | string, uid: number) {
    try {
      const res = (await this.http.post('/annual_agent/bind', {
        shop_id: shopId,
        agent_id: uid,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      })) as any;

      return res;
    } catch (error) {
      return undefined;
    }
  }

  /** 品牌门店批量导入 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async batchImportBrand(data: any) {
    try {
      const res = (await this.http.post('/shop/brand/shop-upload?isImport=1', {
        importData: data,
      })) as { isSuccess: boolean; data: string; msg: string };
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /** 添加巡店记录 */
  async addShopInspectionRecord(
    param: {
      shopId: number | string;
      title: string;
      subtitle: string;
      content: string;
      inspector: string;
    },
    loadingEl?: Element | Vue,
  ) {
    try {
      const { shopId, title, subtitle, content, inspector } = param;
      const res = (await this.http.post(`/shop/${shopId}/inspection-add`, {
        inspection_title: title,
        inspection_content: content,
        inspection_user: inspector,
        inspection_subtitle: subtitle,
        data: {
          loadingEl,
        },
      })) as string;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /** 巡店记录列表 */
  async shopInspectionRecordList(shopId: number | string, params: {
    page_size: number;
    page: number;
  }) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = (await this.http.get(`shop/${shopId}/inspection-list`, {
        params,
      })) as Array<{
        city: string;
        content: string;
        created_at: string;
        id: number;
        poi: string;
        record_user: string;
        shop_id: string;
        title: string;
        subtitle: string;
        bi_url: string;
      }>;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /**
   *
   * @param shopId 门店id
   * @param mobilePhoneNumber 广告主手机号码
   * @returns
   */
  async modifyAdvertiserMobilePhoneNumber(
    shopId: string,
    mobilePhoneNumber: string,
  ) {
    const url = `shop/${shopId}/ad-user-add`;
    try {
      const res = (await this.http.post(url, {
        ad_user_phone: mobilePhoneNumber,
      })) as string;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  async adUserBk(shopId: string) {
    try {
      const res = (await this.http.get(`shop/${shopId}/ad-user-bk`)) as {
        ad_user_phone: string;
        bind: boolean;
        encrypt_ad_user_phone: string;
      };
      return res;
    } catch (error) {
      return undefined;
    }
  }

  async adUserAddBk(shopId: string, data: {
    ad_user_phone: string;
  }) {
    try {
      const res = (await this.http.post(`shop/${shopId}/ad-user-add-bk`, data)) as string;
      return res;
    } catch (error) {
      return undefined;
    }
  }


  async allMainBusiness() {
    try {
      const res = (await this.http.get('shop/mianBusiness/list')) as Array<{
        id: number;
        name: string;
      }>;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  async shopInspectionTitles() {
    const url = 'inspection-option';
    try {
      const res = (await this.http.get(url)) as Array<{
        name: string;
        value: Array<{ name: string }>;
      }>;
      return res;
    } catch (error) {
      return [];
    }
  }

  /**
   * 获取城市黑卡门店数
   * @param regionId 6位城市编码
   * @param loadingEl
   */
  async getCityBlackcardNum(regionId: number, loadingEl?: Element | Vue) {
    const url = `shop/${regionId}/brand-list-count`;
    try {
      const result = (await this.http.get(url, {
        data: {
          loadingEl,
        },
      })) as number;
      return result;
    } catch (error) {
      return null;
    }
  }

  /**
   *
   * @param params
   * @param loadingEl
   */
  async getCityBlackcardListByRank(
    params: {
      regionId: number;
      type: BlackcardRankTypeEnum | string;
      min: number;
      max: number;
      page: number;
    },
    loadingEl?: Element | Vue,
  ) {
    const { regionId, type, min, max, page } = params;
    const newParams = {
      page,
      type,
      min,
      max,
    };
    const url = `shop/${regionId}/brand-list`;
    try {
      const result = (await this.http.get(url, {
        params: newParams,
        data: {
          loadingEl,
        },
      })) as {
        total: number;
        list: Array<{
          name: string;
          poi: string;
          address: string;
        }>;
        pageSize: number;
        page: number;
      };
      return result;
    } catch (error) {
      return null;
    }
  }

  /** 推送消息设置 */
  async pushSetting(
    params: {
      regionId: number;
      type: BlackcardRankTypeEnum | string;
      min: number;
      max: number;
      pushType: number[];
      sms: string;
      desc: string;
      pois: string;
    },
    loadingEl?: Element | Vue,
  ) {
    const { regionId, type, min, max, pushType, sms, desc, pois } = {
      ...params,
    };
    const newParams = {
      regionId,
      type,
      min,
      max,
      poi_white_list: pois.split(',').filter((item) => {
        return item;
      }),
      push_type: pushType,
      short_message_content: sms,
      app_content: desc,
    };
    const url = `shop/${params.regionId}/push-brand-msg`;
    try {
      const res = (await this.http.post(url, {
        ...newParams,
        data: { loadingEl },
      })) as string;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /** 修改店铺地址和扎点 */
  async editAddressInfo(param: {
    shopId: number;
    regionid: number;
    regionpid: number;
    adcode: number;
    address: string;
    latitude: number | string;
    longitude: number | string;
  }) {
    const {
      shopId,
      regionid,
      regionpid,
      adcode,
      address,
      latitude,
      longitude,
    } = param;
    const url = `shop/${shopId}/edit-address`;

    try {
      const res = await this.http.post(url, {
        regionid,
        regionpid,
        adcode,
        address,
        latitude,
        longitude,
      });
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /** 获取门店视频限量 */
  async shopVideosLimit() {
    const url = 'shop/join-video/count';
    try {
      const res = (await this.http.get(url)) as number;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  /** 获取门店经营周报 */
  async shopRunningReport(shopId: number, dates?: string) {
    let url = `shop/${shopId}/weekly-report`;
    if (typeof dates !== 'undefined') {
      url = `${url}?date=${dates}`;
    }
    try {
      const res = (await this.http.get(url)) as IResShopRunningReport;
      return {
        shopName: res.shop_name,
        dates: res.date_list,
        msg: res.msg || '',
        report:
          res.report_data != null && !res.msg
            ? ShopWeeklyRunningReport.from(res)
            : null,
      };
    } catch (error) {
      return null;
    }
  }

  /** 获取门店子账户（员工）列表 */
  async shopStaff(shopId: number | string, loadingEl?: Element | Vue) {
    const url = `shop/${shopId}/staff`;
    try {
      const result = (await this.http.get(url, {
        data: {
          loadingEl,
        },
      })) as IShopStaffConfig;
      return result;
    } catch (error) {
      return null;
    }
  }
  /** 新增门店子账户 */
  async shopStaffAdd(
    params: {
      shopId: number | string;
      roleId: number;
      phone: number | string;
      name: string;
    },
    loadingEl?: Element | Vue,
  ) {
    const { shopId, roleId, phone, name } = params;
    const url = `shop/${shopId}/bk_add_staff`;
    try {
      const res = (await this.http.post(
        url,
        {
          phone,
          name,
          roleId,
        },
        {
          data: {
            loadingEl,
          },
        },
      )) as string;
      return res;
    } catch (error) {
      return null;
    }
  }

  /** 删除门店子账号 */
  async shopStaffDelete(
    params: {
      shopId: number | string;
      userId: number | string;
      group: string;
    },
    loadingEl?: Element | Vue,
  ) {
    const { shopId, userId, group } = params;

    const url = `shop/${shopId}/bk_del_staff`;
    try {
      const res = (await this.http.post(
        url,
        {
          userId,
          group,
        },
        {
          data: {
            loadingEl,
          },
        },
      )) as string;
      return res;
    } catch (error) {
      return null;
    }
  }

  /**
   *
   * @param shopId 门店id
   * @param venderId 第三方ID
   * @returns
   */
  async postVenderIdChange(
    shopId: string | number,
    venderId: string | number,
  ) {
    const url = `shop/${shopId}/venderId?venderId=${venderId}`;
    try {
      const res = (await this.http.post(url)) as string;
      return res;
    } catch (error) {
      return undefined;
    }
  }


  /**
   *
   * @param shopId 门店id
   * @param venderId 第三方ID
   * @returns
   */
  async postKaUserList(
    user_id: any,
  ) {
    const url = `shop/ka/list?user_id=${user_id}`;
    try {
      const res = (await this.http.get(url)) as Array<{
        kaId: number;
        kaName: string;
      }>;
      return res;
    } catch (error) {
      return undefined;
    }
  }
  async removeKa(shopId: number | string, uid: any) {
    const url = `shop/${shopId}/remove-ka`;
    try {
      const res = (await this.http.post(url, uid)) as string;
      return res;
    } catch (error) {
      return undefined;
    }
  }


  async shopCommissionRatio(shopId: number | string, data: any) {
    try {
      const res = (await this.http.post(`shop/${shopId}/commission-ratio`, data)) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
}
