import { ApplyFor, IApply, AuditDetail, IAuditDetail, ApplyExamineList, IApplyExamineList } from '@/common/interface/apply';


export class CApply implements ApplyFor {
  id = 0;

  orderNo = '';

  goodsId = '';

  goodsName = '';

  kaName = '';

  quantity = 0;

  tnum = 0;

  cnum = 0;

  ctime = '';

  oriPrice = '';

  coupon = '';

  price = '';

  comRatio = '';

  commissionPrice = '';

  verifyPrice = '';

  verifyts = '';

  verifyStatus = '';

  settleStatus = '';

  shopName = '';

  static from(data: IApply) {
    const apply = new CApply();
    apply.id = data.id;
    apply.orderNo = data.order_no;
    apply.goodsId = data.goods_id;
    apply.goodsName = data.goods_name;
    apply.kaName = data.ka_name;
    apply.quantity = data.quantity;
    apply.tnum = data.tnum;
    apply.cnum = data.cnum;
    apply.ctime = data.ctime;
    apply.oriPrice = data.ori_price;
    apply.coupon = data.coupon;
    apply.price = data.price;
    apply.comRatio = data.com_ratio ? `${Number(data.com_ratio) * 100}%` : '--';
    apply.commissionPrice = data.commission_price;
    apply.verifyPrice = data.verify_price;
    apply.verifyts = data.verifyts;
    apply.verifyStatus = data.verify_status;
    apply.settleStatus = data.settle_status;
    apply.shopName = data.shopName;
    return apply;
  }
}

export class CAuditDetail implements AuditDetail {
  withdrawalNo = 0;

  id = 0;

  money = '';

  statusStr = '';

  accountNo = '';

  username = '';

  flowNo = '';

  transferTime = '';

  payNo = '';

  remark = '';

  ctime = '';

  verifyTime = '';

  encryptAccountNo = '';

  encryptUsername = '';

  static from(data: IAuditDetail) {
    const auditDetail = new CAuditDetail();
    auditDetail.id = data.id;
    auditDetail.withdrawalNo = data.withdrawal_no;
    auditDetail.money = data.money ? data.money : '--';
    auditDetail.statusStr = data.status_str || '--  ';
    auditDetail.accountNo = data.account_no || '--';
    auditDetail.username = data.username || '--';
    auditDetail.flowNo = data.flow_no || '--';
    auditDetail.transferTime = data.transfer_time || '--';
    auditDetail.remark = data.remark || '--';
    auditDetail.ctime = data.ctime || '--';
    auditDetail.payNo = data.pay_no || '--';
    auditDetail.verifyTime = data.verify_time || '--';
    auditDetail.encryptAccountNo = data.encrypt_account_no || '--';
    auditDetail.encryptUsername = data.encrypt_username || '--';
    return auditDetail;
  }
}


export class CApplyExamineList implements ApplyExamineList {
  id = 0;

  withdrawalNo = '';

  status = 0;

  accountNo = '';

  ctime = '';

  verifyTime = '';

  transferTime = '';

  payNo = '';

  flowNo = '';

  statusStr = '';

  encryptAccountNo = '';

  static from(data: IApplyExamineList) {
    const applyExamineList = new CApplyExamineList();
    applyExamineList.id = data.id;
    applyExamineList.withdrawalNo = data.withdrawal_no || '--';
    applyExamineList.status = data.status;
    applyExamineList.accountNo = data.account_no || '--';
    applyExamineList.ctime = data.ctime || '--';
    applyExamineList.verifyTime = data.verify_time || '--';
    applyExamineList.transferTime = data.transfer_time || '--';
    applyExamineList.payNo = data.pay_no || '--';
    applyExamineList.flowNo = data.flow_no || '--';
    applyExamineList.statusStr = data.status_str || '--';
    applyExamineList.encryptAccountNo = data.encrypt_account_no || '--';
    return applyExamineList;
  }
}
