
import { Vue, Component } from 'vue-property-decorator';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import moment from 'moment';

@Component
export default class StoreSupport extends Vue {
  title = '设置门店抽拥';
  visible = false;

  ruleForm: any = {
    commission_ratio: '',
    commission_time: '2025-03-30',
  };
  rules = {

  };
  layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
  };
  shopId: any = '';


  @lazyInject(ShopService)
  shopService!: ShopService;

  handleCancel() {
    this.visible = false;
    this.ruleForm = {
      commission_ratio: '',
      commission_time: '',
    };
  }

  open(value: any) {
    this.visible = true;
    this.ruleForm.commission_ratio = value.commission_ratio || 0;
    if (value.commission_time != '0000-00-00 00:00:00') {
      this.ruleForm.commission_time = this.ruleForm.commission_ratio == 0 ? '' : value.commission_time;
    } else {
      this.ruleForm.commission_time = '';
    }
    this.shopId = value.shopId;
  }

  async submitForm() {
    console.log(this.ruleForm);
    // if (this.ruleForm.commission_time) {
    //   const data = new Date();
    //   if (data.getTime() > new Date(this.ruleForm.commission_time).getTime()) {
    //     this.$message.error('生效时间不能小于当前时间');
    //     return;
    //   }
    // }

    try {
      const res = await this.shopService.shopCommissionRatio(this.shopId, this.ruleForm);

      if (res) {
        this.$message.success('设置成功');
        this.visible = false;
        this.resetForm();
        this.$emit('refresh');
      }
    } catch (error) {
      this.$message.error('设置失败');
    }
  }

  resetForm() {
    this.visible = false;
    this.ruleForm = {
      commission_ratio: '',
      commission_time: '',
    };
  }

  onChange(date: any) {
    this.ruleForm.commission_time = moment(date).format('YYYY-MM-DD');
  }

  onOk(value: any) {
    this.ruleForm.commission_time = moment(value).format('YYYY-MM-DD');
  }
}
