
import { BASE_URL } from '@/common/constant';
import { ESPUField, ENProductSource } from '@/common/enum/spu';
import { KeyObject } from '@/common/interface';
import {
  IKASPU,
  IResKASPU,
  ISPUCategory,
  ISPUField,
  ISPUProduct,
} from '@/common/interface/spu';
import SPUEditor from '@/components/spu/SPUEditor.vue';
import lazyInject from '@/di';
import { KASPU } from '@/models/spu.model';
import { SPUFieldFactory } from '@/models/spuFieldFactory';
import PermissionService, { AuthEnum } from '@/services/permission.service';
import SPUService from '@/services/spu.service';
import _ from 'lodash';
import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import BatchDeletePois from '../shop/components/BatchDeletePois.vue';

@Component({
  components: {
    SPUEditor,
    BatchDeletePois,
  },
})
export default class SpuKA extends Vue {
  AuthEnum = AuthEnum;

  ENProductSource = ENProductSource;

  BASE_URL = BASE_URL;

  form: {
    name: string;
    spuId: string;
    categoryId?: string | number;
  } = {
    name: '',
    spuId: '',
    categoryId: '',
  };

  pagination = {
    total: 0,
    pageSize: 20,
    current: 1,
    onChange: this.paginationChange,
  };

  listData: IResKASPU[] = [];

  kaMap = new Map<string, number>();

  spuVideoPermmison: KeyObject<boolean> = {};

  /** 分类数据 */
  secondCategoryList: ISPUCategory[] = [];

  allSpuCategory: ISPUCategory[] = [];

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'KA',
      dataIndex: 'platform_product_ka',
      key: 'platform_product_ka',
      width: 80,
    },
    {
      title: '商品名称',
      dataIndex: 'name',
      key: 'name',
      width: 240,
    },
    {
      title: '商品类目',
      dataIndex: 'scategory',
      key: 'scategory',
      scopedSlots: { customRender: 'scategory' },
      width: 80,
    },
    {
      title: '商品照片',
      dataIndex: 'headlineImage',
      key: 'headlineImage',
      scopedSlots: { customRender: 'headlineImage' },
      width: 80,
    },
    {
      title: '门市价',
      dataIndex: 'originalPriceCent',
      scopedSlots: { customRender: 'originalPriceCent' },
      width: 100,
    },
    {
      title: '黑卡价',
      dataIndex: 'vipPriceCent',
      key: 'vipPriceCent',
      scopedSlots: { customRender: 'vipPriceCent' },
      width: 100,
    },
    {
      title: '服务详情',
      dataIndex: 'introduction',
      key: 'introduction',
      scopedSlots: { customRender: 'introduction' },
      width: 160,
    },
    {
      title: '商品相册',
      dataIndex: 'images',
      key: 'images',
      scopedSlots: { customRender: 'images' },
      width: 120,
    },
    {
      title: '适用门店',
      dataIndex: 'tempSpuShopIds',
      key: 'tempSpuShopIds',
      scopedSlots: { customRender: 'tempSpuShopIds' },
      width: 120,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      scopedSlots: { customRender: 'createTime' },
      width: 180,
    },
    {
      title: '操作',
      dataIndex: 'actions',
      fixed: 'right',
      key: 'actions',
      scopedSlots: { customRender: 'actions' },
      width: 120,
    },
  ];

  editingSpu: KASPU = new KASPU();

  spuForm: { [key: string]: any } = {};

  spuConfig: ISPUField[] = [];

  isLoading = false;

  initSpuFields: ISPUField[] = SPUFieldFactory.init({
    spuType: 'kaSpu',
    vipPriceValidator: this.vipPriceValidator,
    originalPriceValidator: this.originalPriceValidator,
    newUserPriceValidator: this.newUserPriceValidator,
    tyreSpecValidator: this.tyreSpecValidator,
  });

  @Ref()
  spuEditor!: SPUEditor;

  @Ref()
  batchDeletePois!: BatchDeletePois;

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @lazyInject(PermissionService)
  protected permissionService!: PermissionService;

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { pageSize, current } = this.pagination;
    const { name, spuId, categoryId } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {
      pageSize,
      page: current,
    };

    if (name) {
      this.form.name = name as string;
      param.name = name;
    }
    if (spuId) {
      this.form.spuId = spuId as string;
      param.spuId = spuId;
    }
    if (categoryId) {
      this.form.categoryId = +categoryId;
      param.categoryId = categoryId;
    }
    await this.all(param);
  }

  /** 新增商品 */
  add() {
    this.editingSpu = new KASPU();
    this.initSpuForm(this.editingSpu);
  }

  /** 修改商品 */
  async edit(params: { id: number; ka: string }) {
    const { id, ka } = params;
    const data = await this.spuService.kaSpuDetail(`${id}`, this.$el);
    if (data) {
      this.editingSpu = KASPU.from(data);
      this.editingSpu.ka = ka;
      this.editingSpu.ka_id = this.kaMap.get(ka);
      this.initSpuForm(this.editingSpu);
    }
  }

  initSpuForm(editingSpu: KASPU) {
    const form: { [key: string]: any } = {};
    const times =
      editingSpu.effectiveTimes > 0 ? editingSpu.effectiveTimes * 100 : 100;

    for (const [key, value] of Object.entries(editingSpu)) {
      switch (key) {
        case ESPUField.EFFECTIVE_TIMES: {
          if (Number(value) > 1) {
            form.isCombo = true;
          } else {
            form.isCombo = false;
          }
          form[key] = value;
          break;
        }
        case ESPUField.ORIGINAL_PRICE_CENT: {
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }
        case ESPUField.VIP_PRICE_CENT: {
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }
        case ESPUField.NEW_USER_PRICE_CENT: {
          form.hasNewUserPrice = value > 0;
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }

        default: {
          form[key] = value;
          break;
        }
      }
    }

    form.comboTotalPrice = {
      originalPriceCent: editingSpu.originalPriceCent,
      vipPriceCent: editingSpu.vipPriceCent,
      newUserPriceCent: editingSpu.newUserPriceCent,
    };

    this.handleUpdate(form, true);
  }

  async initSpuConfig(refreshIndicative?: boolean) {
    const spu = { ...this.spuForm };
    const detail = _.cloneDeep(this.initSpuFields);

    const kaIdx = detail.findIndex((item) => item.label === ESPUField.KA);
    const isComboIndex = detail.findIndex(
      (item) => item.label === ESPUField.IS_COMBO,
    );
    const idIdx = detail.findIndex((item) => item.label === ESPUField.ID);
    const originalPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.ORIGINAL_PRICE_CENT,
    );
    const vipPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.VIP_PRICE_CENT,
    );
    const newUserPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.NEW_USER_PRICE_CENT,
    );
    const comboTotalPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.COMBO_TOTAL_PRICE,
    );
    const effectiveTimesIdx = detail.findIndex(
      (item) => item.label === ESPUField.EFFECTIVE_TIMES,
    );
    const tyreTagIdx = detail.findIndex(
      (item) => item.label === ESPUField.TYRE_TAG,
    );
    const tyreSpecIdx = detail.findIndex(
      (item) => item.label === ESPUField.TYRE_SPEC,
    );
    const videosIdx = detail.findIndex(
      (item) => item.label === ESPUField.VIDEOS,
    );

    if (spu.id === 0) {
      // 新增品牌标品时允许更换是否套餐、允许更换ka选项
      detail[isComboIndex].readonly = false;
      detail[kaIdx].readonly = false;
      detail[idIdx].isHidden = true;
    } else {
      // 新增品牌标品时不允许更改是否套餐的选项、不允许更换ka选项
      detail[isComboIndex].readonly = true;
      detail[kaIdx].readonly = true;
      detail[idIdx].isHidden = false;
    }

    if (spu.isCombo === true) {
      detail[effectiveTimesIdx].isHidden = false;
      detail[comboTotalPriceIdx].isHidden = false;
      detail[originalPriceIdx].labelName = '单次门市价(元)';
      detail[vipPriceIdx].labelName = '单次黑卡价(元)';
      detail[newUserPriceIdx].labelName = '单次特价(元)';
    } else {
      detail[effectiveTimesIdx].isHidden = true;
      detail[comboTotalPriceIdx].isHidden = true;
      detail[originalPriceIdx].labelName = '门市价(元)';
      detail[vipPriceIdx].labelName = '黑卡价(元)';
      detail[newUserPriceIdx].labelName = '特价(元)';
    }

    if (spu.hasNewUserPrice === true) {
      detail[newUserPriceIdx].isHidden = false;
    } else {
      detail[newUserPriceIdx].isHidden = true;
    }

    if (refreshIndicative === true) {
      const indicativePrice = await this.spuService.indicativePrice({
        categoryId: spu.category.id,
      });
      if (indicativePrice) {
        this.editingSpu.indicativePrice = indicativePrice;
        // eslint-disable-next-line standard/computed-property-even-spacing
        detail[
          vipPriceIdx
        ].extra = `参考价：该商品分类，同城均价¥${indicativePrice}`;
      } else {
        detail[vipPriceIdx].extra = '暂无参考价';
      }
    } else {
      detail[vipPriceIdx].extra =
        this.editingSpu.indicativePrice > 0
          ? `参考价：该商品分类，同城均价¥${this.editingSpu.indicativePrice}`
          : '暂无参考价';
    }

    /** 补充轮胎类目规则 */
    if (spu.category.id === 41500) {
      detail[tyreTagIdx].isHidden = false;
      detail[tyreSpecIdx].isHidden = false;
      detail[tyreTagIdx].rule = [
        { required: true, message: '请选择轮胎性能', trigger: 'blur' },
      ];
    } else {
      detail[tyreTagIdx].isHidden = true;
      detail[tyreSpecIdx].isHidden = true;

      if (typeof detail[tyreTagIdx].rule !== 'undefined') {
        delete detail[tyreTagIdx].rule;
      }
    }

    if (
      this.spuVideoPermmison &&
      this.spuVideoPermmison[AuthEnum.标品视频管理]
    ) {
      detail[videosIdx].isHidden = false;
    } else {
      detail[videosIdx].isHidden = true;
    }

    return detail;
  }

  vipPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { originalPriceCent, products } = this.spuForm;

    if (products.length) {
      return +value < +this.spuForm.comboTotalPrice.originalPriceCent;
    } else {
      if (+value > 0 && (!originalPriceCent || isNaN(+originalPriceCent))) {
        return true;
      }
      return +value < +originalPriceCent;
    }
  }

  newUserPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { vipPriceCent } = this.spuForm;
    if (!vipPriceCent || isNaN(+vipPriceCent)) {
      return true;
    }
    return +value < +vipPriceCent;
  }

  originalPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { products } = this.spuForm;
    if (products.length) {
      return +this.spuForm.comboTotalPrice.originalPriceCent > 0;
    } else {
      return +value > 0;
    }
  }

  tyreSpecValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: any,
  ): boolean {
    const { category } = this.spuForm;
    if (category.id !== 41500) {
      return true;
    }

    if (value === undefined) {
      return false;
    }
    const { tyreWidth, tyreRatio, tyreDiameter, tyreType } = value;
    if (!tyreWidth || !tyreRatio || !tyreType || !tyreDiameter) {
      return false;
    }
    if (isNaN(+tyreWidth) || isNaN(+tyreDiameter) || isNaN(+tyreRatio)) {
      return false;
    }
    return true;
  }

  async handleSubmit(val: { [key: string]: any }) {
    const {
      category,
      effectiveTimes,
      headlineImage,
      headlineImages,
      introduction,
      isHot,
      name,
      products,
      images,
      unavailableTime,
      id,
      shortComment,
      isDrawCommission,
      tempSpuShopIds,
      tyreTag,
      tyreSpec,
      videos,
      commissionRatio,
      isAutoBrand,
    } = val;
    const { originalPriceCent, vipPriceCent, newUserPriceCent } =
      val.comboTotalPrice;

    const spu: { [key: string]: any } = {
      category,
      headlineImage: headlineImages.length ? headlineImages[0] : headlineImage,
      headlineOtherImage:
        headlineImages.length > 1
          ? headlineImages.slice(1, headlineImages.length)
          : [],
      effectiveTimes,
      name,
      unavailableTime,
      products,
      originalPriceCent,
      vipPriceCent,
      newUserPriceCent,
      isHot,
      introduction,
      images,
      platformProduct: {
        isCopyPlatformProduct: 0,
        isPlatformProduct: 1,
        shortComment,
        tempSpuShopPois: '',
        commissionRatio,
        isDrawCommission: isDrawCommission === true ? 1 : 0,
      },
      tempSpuShopIds,
      isAutoBrand: Boolean(isAutoBrand),
    };
    if (videos && videos.length && videos[0].id) {
      spu.videos = videos;
    }
    if (category.id === 41500) {
      spu.ext_json = {
        tireTag: tyreTag,
        sectionWidth: tyreSpec.tyreWidth,
        aspectRatio: tyreSpec.tyreRatio,
        wheelDiameter: tyreSpec.tyreDiameter,
        tireType: tyreSpec.tyreType,
      };
    }
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    try {
      if (id !== 0) {
        // 编辑商品
        spu.id = id;
        spu.platform_product_ka = this.kaMap.get(val.ka);
        const res = await this.spuService.kaSpuEdit(id, spu, this.$el);
        if (res) {
          this.$message.success(`${res}`);
          this.spuEditor.close();
          setTimeout(() => {
            this.handleSearch();
          }, 500);
        }
      } else {
        // 新增商品
        spu.platform_product_ka = this.kaMap.get(val.ka);
        const res = await this.spuService.kaSpuCreate(spu, this.$el);
        if (res) {
          this.$message.success('新增成功');
          this.spuEditor.close();
          setTimeout(() => {
            this.handleSearch();
          }, 500);
        }
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  async handleUpdate(form: { [key: string]: any }, initial?: boolean) {
    if (form.ka) {
      form.ka_id = this.kaMap.get(form.ka);
    }
    let shouldGetIndicativePrice = false;
    const prevCategoryId =
      this.spuForm && this.spuForm.category ? this.spuForm.category.id : 0;

    const duplicate = Object.assign({}, form);

    if (form.category && form.category.id === 41500) {
      // 增加轮胎参数
      if (typeof form.tyreTag === 'undefined') {
        duplicate.tyreTag = '';
      }
      if (typeof form.tyreSpec === 'undefined') {
        const tyreSpec = {
          tyreType: '',
          tyreWidth: '',
          tyreRatio: '',
          tyreDiameter: '',
        };
        duplicate.tyreSpec = tyreSpec;
      }
    } else if (
      form.category.id !== 41500 &&
      typeof form.tyreTag !== 'undefined'
    ) {
      // 移除轮胎性能
      delete duplicate.tyreTag;
    } else if (
      form.category.id !== 41500 &&
      typeof form.tyreSpec !== 'undefined'
    ) {
      // 移除轮胎规格
      delete duplicate.tyreSpec;
    }

    this.spuForm = { ...duplicate };

    if (initial && initial === true) {
      shouldGetIndicativePrice = this.spuForm.category.id !== 0;
      // 初始化商品
      this.spuEditor.setup(
        await this.initSpuConfig(shouldGetIndicativePrice),
        this.spuForm,
      );
      return;
    }

    // 更新商品属性
    shouldGetIndicativePrice =
      prevCategoryId !== form.category.id && form.category.id !== 0;

    if (!this.spuForm.hasNewUserPrice) {
      this.$set(this.spuForm, ESPUField.NEW_USER_PRICE_CENT, '0.00');
    }
    if (!this.spuForm.isCombo) {
      this.$set(this.spuForm, ESPUField.EFFECTIVE_TIMES, 1);
    }
    if (this.spuForm.products && this.spuForm.products.length) {
      const priceCent = (this.spuForm.products as ISPUProduct[])
        .map(({ originalPriceCent }) => +originalPriceCent)
        .reduce((prev, cur) => prev + cur, 0);

      this.$set(
        this.spuForm,
        ESPUField.ORIGINAL_PRICE_CENT,
        (priceCent / 100).toFixed(2),
      );
    }
    const times = this.spuForm.effectiveTimes * 100;
    const comboTotalPrice = {
      originalPriceCent: Math.round(times * this.spuForm.originalPriceCent),
      vipPriceCent: Math.round(times * this.spuForm.vipPriceCent),
      newUserPriceCent:
        this.spuForm.hasNewUserPrice === true
          ? Math.round(times * this.spuForm.newUserPriceCent)
          : 0,
    };
    this.$set(this.spuForm, ESPUField.COMBO_TOTAL_PRICE, comboTotalPrice);

    this.spuEditor.setup(
      await this.initSpuConfig(shouldGetIndicativePrice),
      this.spuForm,
      true,
    );
  }

  getApplicableShops(ids: string, length: number) {
    const shopIdList = !ids ? [] : ids.split(',');
    if (!shopIdList.length) {
      return [];
    }
    if (shopIdList.length < length) {
      return shopIdList;
    }
    return shopIdList.splice(0, length);
  }

  async all(params: {
    pageSize: number;
    page: number;
    name?: string;
    spuId?: string;
    categoryId?: number;
  }) {
    const res = await this.spuService.kaSpuList(params, this.$el);

    if (res) {
      this.listData = res.list;
      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: Number(res.pageSize),
        onChange: this.paginationChange,
      };
    }
  }

  async getCategory() {
    const data = await this.spuService.spuCategories({
      aggregation: true,
      loadingEl: this.$el,
    });

    this.allSpuCategory = data;

    this.secondCategoryList = data.reduce((prev, cur) => {
      return prev.concat(cur.children || []);
    }, [] as ISPUCategory[]);
  }

  openBatchDeletePoisModal() {
    this.batchDeletePois.visible = true;
  }

  onChangeCate(val: number) {
    this.$set(this.form, 'cate', val);
  }

  onChangeFcate(val: number) {
    this.$set(this.form, 'cate', '');
    if (val) {
      this.$set(this.form, 'fcate', val);
    }
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    const { name, spuId, cate, fcate } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: current,
    };
    if (name) {
      query.name = name;
    }

    if (spuId) {
      query.spuId = spuId;
    }
    if (fcate) {
      query.fcate = fcate;
    }
    if (cate) {
      query.cate = cate;
    }

    this.$router.push({
      query,
    });
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    const { name, spuId, categoryId } = this.form;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: `${this.pagination.current}`,
    };
    if (name) {
      query.name = name;
    }

    if (spuId) {
      query.spuId = spuId;
    }

    if (categoryId) {
      query.categoryId = categoryId;
    }

    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    this.form = {
      name: '',
      spuId: '',
      categoryId: '',
    };
    this.$router.push({
      query: {},
    });
  }

  async changeOnlineStatus(spuId: string, isOnline: boolean) {
    const res = await this.spuService.kaSpuOnlineStatusChange(
      spuId,
      !isOnline,
      this.$el,
    );
    if (res) {
      this.$message.success(res);
      setTimeout(() => {
        this.handleSearch();
      }, 500);
    }
  }

  async deleteSpu(spu: IKASPU) {
    const res = await this.spuService.kaSpuDelete(`${spu.id}`, this.$el);
    if (res) {
      this.$message.success('删除成功');
      setTimeout(() => {
        this.handleSearch();
      }, 500);
    }
  }

  async getKaMap() {
    const res = await this.spuService.kaSpuAccessibleKas();
    if (res && res.length) {
      const map = new Map<string, number>();
      for (const item of res) {
        map.set(item.key, item.val);
      }
      if (map.size) {
        this.kaMap = new Map(map);
      }
    }
  }

  async created() {
    const $asyncP = await this.permissionService.asyncPermissions([
      AuthEnum.标品视频管理,
    ]);
    $asyncP.subscribe((permissions: KeyObject<boolean>) => {
      if (permissions) {
        this.spuVideoPermmison = permissions;
      } else {
        this.spuVideoPermmison = {};
      }
    });
  }

  async mounted() {
    const { page } = this.$route.query;
    if (typeof page !== 'undefined') {
      this.pagination.current = +page;
    }
    this.handleSearch();
    this.getCategory();
    await this.getKaMap();
  }
}
